import { CityService } from './city.service';
import { CountryService } from './country.service';
import { Country } from 'src/models/geography/country';
import { Injectable } from "@angular/core";
import { IGeographyService } from "../../interfaces/geographyService/IGeographyService";
import { RegionService } from './region.service';
import { IRegionService } from 'src/services/interfaces/geographyService/IRegionService';
import { ICityService } from 'src/services/interfaces/geographyService/ICityService';
import { IBaseService } from 'src/services/interfaces/IBaseService';

@Injectable({
    providedIn: 'root'
})

export class GeographyService implements IGeographyService {
    public country: IBaseService<Country>;
    public region: IRegionService;
    public city: ICityService;
    constructor(country: CountryService,
                region: RegionService,
                city: CityService)
    {
        this.country = country;
        this.region = region;
        this.city = city;
    }
}