import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/services/apiServices/allServices/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  isShow: boolean = true;
  constructor(private loaderService : LoaderService) { }

  ngOnInit() {
    this.loaderService.getIsShowEvent().subscribe(isShow => {
      this.isShow = isShow;
    });
  }
  ngOnDestroy(): void {
    // this.loaderService.getIsShowEvent().unsubscribe();
  }

}
