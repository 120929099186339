import { EditorComponent } from 'src/models/editor';
import { Rule } from './rule';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Util } from 'src/utils/util';

export abstract class EditorComponentRule extends EditorComponent<Rule>{
    private editor: any;
    abstract nameTranslate: string;
    protected mobileView: boolean;
    constructor(protected services: ServicesService,
                protected route: ActivatedRoute,
                protected formBuilder: FormBuilder,
                protected api: ApiService,
                protected translate: TranslateService)
    {
        super(services, route, formBuilder, api, translate);
        this.mobileView = false;
        this.form = formBuilder.group({
            "value" : new FormControl('', [Validators.required])
        });
    }
    ngOnInit(){
        this.setValues();
    }
    setValues(): void{
        this.apiCall.getOne().subscribe(
            item => {
                this.item = item;
                this.form.patchValue(item);
                this.InProgress = false;
            },
            error => this.catchError(error)
        );
    }
    setValuesToObject(): void { }
    apiAdd(): void {
        this.apiUpdate();
    }
    apiUpdate(): void {
        const html = Util.createHtml(this.form.value.value);
        
        this.apiCall.update(html).subscribe(
            () => this.sendSuccessAlert('info.message.rules'),
            error => this.catchError(error)
        );
    }
    changeMobile(){
        this.mobileView = !this.mobileView;
    }

    onInitEditor(value: any){
        this.editor = value.editor;
        this.editor.getModule('toolbar').addHandler(
            'image', 
            () => this.selectLocalImage()
        );
    }

    selectLocalImage() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/jpeg');
        input.click();
        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();
            reader.onloadend = _ => {
                this.saveToServer(reader.result);
            }
            reader.readAsDataURL(file);
        };
    }
    saveToServer(file: string|ArrayBuffer) {
        this.api.settings.saveImageToServer(file).subscribe(
            url => this.insertToEditor(url),
            error => this.catchError(error)
        )
    }
    insertToEditor(url: any) {
        const range = this.editor.getSelection();
        this.editor.insertEmbed(range.index, 'image', Util.getPathImage(url), 'user');
    }
}