import {Base} from '../base';
import {Region} from '../geography/region';

export class Supervisor {
  id: number;
  name: string;
  email: string;
  phone: string;
  regions: Region[] | -1;
  constructor();
  constructor(id: number, name: string);
  constructor(id: number, name: string, email?: string);
  constructor(id?: number, name?: string, email?: string, phone?: string)
  constructor(id?: number, name?: string, email?: string, phone?: string, regions?: Region[]) {
    if (id) { this.id = id; }
    if (name) { this.name = name; }
    if (email) { this.email = email; }
    if (phone) { this.phone = phone; }
    if (regions) { this.regions = regions; }
  }
}
