import { ApiService } from 'src/services/api.service';
import { Component } from '@angular/core';
import { Region } from 'src/models/geography/region';
import { DatatablesComponent } from 'src/models/datatables';
import { ServicesService } from 'src/services/services.service';
import { TitleItem } from 'src/models/titleItem';
import { getColumnTitles } from 'src/utils/dt/columns';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss']
})
export class RegionsComponent extends DatatablesComponent<Region> {
  columnNames: TitleItem[];
  apiCall: any;
  constructor(protected services: ServicesService,
              protected api: ApiService) {
    super(api, services);
    this.apiCall = api.geography.region;
    this.columnNames = getColumnTitles([
      'name.region', 'country'
    ]);
  }
}
