import { TableSearchCalendarComponent } from 'src/components/table/table-search-calendar/table-search-calendar.component';
import { LogService } from '../services/apiServices/allServices/log.service';
import { NavigationService } from '../services/apiServices/allServices/navigation.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID  } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule} from '@angular/router';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';


import { AppComponent } from './app.component';
import { HeaderComponent } from '../components/header/header.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { DataTablesModule } from 'angular-datatables';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { ApiHttpInterceptor } from 'src/services/apiHttpInterceptor';
import { ProgressBarModule } from 'primeng/progressbar';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import '../icons';

import { UsersComponent } from '../pages/users/users/users.component';
import { CategoriesComponent } from '../pages/users/categories/categories.component';
import { appRoutes } from './app.routes';
import { ContentHeaderComponent } from 'src/components/content-header/content-header.component';
import { UserEditorComponent } from 'src/pages/users/user-editor/user-editor.component';
import { ButtonModule } from 'primeng/button';
import { CategoryEditorComponent } from 'src/pages/users/category-editor/category-editor.component';
import { MessageComponent } from 'src/components/message/message.component';
import { ApiService } from 'src/services/api.service';
import { BasePageComponent } from 'src/pages/base/base-page/base-page.component';
import { BaseEditorPageComponent } from 'src/pages/base/base-editor-page/base-editor-page.component';
import { InvalidTypeDirective } from 'src/directives/invalid-type.directive';
import { InvalidMessageDirective } from 'src/directives/invalid-message.directive';

import { CardFooterComponent } from 'src/components/card-footer/card-footer.component';
import { PasswordComponent } from 'src/components/password/password.component';
import { ForgotPasswordComponent } from 'src/pages/auth/forgot-password/forgot-password.component';
import { SelectGeographyComponent } from 'src/components/select-geography/select-geography.component';
import { AdminsComponent } from 'src/pages/users/admins/admins.component';
import { AdminEditorComponent } from 'src/pages/users/admin-editor/admin-editor.component';
import { CountriesComponent } from 'src/pages/geography/countries/countries.component';
import { CountryEditorComponent } from 'src/pages/geography/country-editor/country-editor.component';
import { LogoutComponent } from 'src/pages/auth/logout/logout.component';
import { LoaderComponent } from 'src/components/loader/loader.component';
import { LoaderService } from 'src/services/apiServices/allServices/loader.service';
import { ServicesService } from 'src/services/services.service';
import { RegionsComponent } from 'src/pages/geography/regions/regions.component';
import { RegionEditorComponent } from 'src/pages/geography/region-editor/region-editor.component';
import { CitiesComponent } from 'src/pages/geography/cities/cities.component';
import { CityEditorComponent } from 'src/pages/geography/city-editor/city-editor.component';
import { ModelTypesComponent } from 'src/pages/models/model-types/model-types.component';
import { ModelTypeEditorComponent } from 'src/pages/models/model-type-editor/model-type-editor.component';
import { TradingPlaceTypesComponent } from 'src/pages/trading-places/trading-place-types/trading-place-types.component';
import { TradingPlaceTypeEditorComponent } from 'src/pages/trading-places/trading-place-type-editor/trading-place-type-editor.component';
import { ItemNotFoundComponent } from 'src/components/item-not-found/item-not-found.component';
import { TradingPlacesComponent } from '../pages/trading-places/trading-places/trading-places.component';
import { TradingPlaceEditorComponent } from '../pages/trading-places/trading-place-editor/trading-place-editor.component';
import { SelectUserComponent } from 'src/components/select-user/select-user.component';
import { CutModelTypePipe } from 'src/pipes/cutModelType/cutModelType';
import { LoginComponent } from 'src/pages/auth/login/login.component';
import { VerificationCodeComponent } from 'src/pages/auth/verification-code/verification-code.component';
import { BaseAuthComponent } from 'src/pages/base/base-auth/base-auth.component';
import { ConfirmPasswordComponent } from 'src/pages/auth/confirm-password/confirm-password.component';
import { SwitchTranslatePipe } from 'src/pipes/switchTranslate/switchTranslate';
import {GalleriaModule} from 'primeng/galleria';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeDeAt from '@angular/common/locales/ru';
import { PresentsComponent } from 'src/pages/presents/presents/presents.component';
import { PresentEditorComponent } from 'src/pages/presents/present-editor/present-editor.component';
import {FileUploadModule} from 'primeng/fileupload';
import { PointsAccountEditorComponent } from 'src/pages/users/points-account-editor/points-account-editor.component';
import { PointsAccountsComponent } from 'src/pages/users/points-accounts/points-accounts.component';
import {DialogModule} from 'primeng/dialog';
import { PointsAccountHistoryComponent } from 'src/pages/users/points-account-history/points-account-history.component';
import { SettingsComponent } from 'src/pages/settings/settings/settings.component';
import {CalendarModule} from 'primeng/calendar';
import { AdminsInfoComponent } from 'src/pages/settings/admins-info/admins-info.component';
import { AdminInfoEditorComponent } from 'src/pages/settings/admin-info-editor/admin-info-editor.component';
import { SelectAdminComponent } from 'src/components/select-admin/select-admin.component';
import { ModelBrandsComponent } from 'src/pages/models/model-brands/model-brands.component';
import { ModelBrandEditorComponent } from 'src/pages/models/model-brand-editor/model-brand-editor.component';
import { ModelsComponent } from 'src/pages/models/models/models.component';
import { ModelEditorComponent } from 'src/pages/models/model-editor/model-editor.component';
import { UploadFileComponent } from 'src/components/upload-file/upload-file.component';
import { OrderPresentsComponent } from 'src/pages/presents/order-presents/order-presents.component';
import { OrderPresentEditorComponent } from 'src/pages/presents/order-present-editor/order-present-editor.component';
import { ActivityComponent } from 'src/pages/activity/activity/activity.component';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { ActivityEditorComponent } from 'src/pages/activity/activity-editor/activity-editor.component';
import { ActivityModelsComponent } from 'src/pages/activity/activity-models/activity-models.component';
import { SalesComponent } from 'src/pages/sales/sales/sales.component';
import { SaleEditorComponent } from 'src/pages/sales/sale-editor/sale-editor.component';
import {LightboxModule} from 'primeng/lightbox';
import { FormInputComponent } from '../components/form-input/form-input.component';
import { RulesOfParticipationComponent } from 'src/pages/settings/rules-of-participation/rules-of-participation.component';
import {EditorModule} from 'primeng/editor';
import { InstructionComponent } from 'src/pages/settings/instruction/instruction.component';
import { GdprComponent } from '../pages/settings/gdpr/gdpr.component';
import { CppdComponent } from '../pages/settings/cppd/cppd.component';
import { SupervisorsComponent } from '../pages/users/supervisors/supervisors.component';
import { SupervisorEditComponent } from '../pages/users/supervisor-edit/supervisor-edit.component';
import { CommissionCppdComponent } from '../pages/settings/commission-cppd/commission-cppd.component';
import { InstallerClientSmsEditorComponent } from 'src/pages/settings/installer-client-sms-editor/installer-client-sms-editor.component';
import { InstallerClientsSmsComponent } from 'src/pages/settings/installer-clients-sms/installer-clients-sms.component';
import {PointValueComponent} from '../pages/settings/point-value/pointValue.component';

registerLocaleData(localeDeAt);
// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    SidebarComponent,
    UsersComponent,
    CategoriesComponent,
    ContentHeaderComponent,
    UserEditorComponent,
    CategoryEditorComponent,
    MessageComponent,
    BasePageComponent,
    BaseEditorPageComponent,
    InvalidMessageDirective,
    InvalidTypeDirective,
    CardFooterComponent,
    PasswordComponent,
    ForgotPasswordComponent,
    SelectGeographyComponent,
    AdminsComponent,
    AdminEditorComponent,
    CountriesComponent,
    CountryEditorComponent,
    LogoutComponent,
    LoaderComponent,
    RegionsComponent,
    RegionEditorComponent,
    CityEditorComponent,
    CitiesComponent,
    ModelTypesComponent,
    ModelTypeEditorComponent,
    TradingPlaceTypesComponent,
    TradingPlaceTypeEditorComponent,
    ItemNotFoundComponent,
    TradingPlacesComponent,
    TradingPlaceEditorComponent,
    SelectUserComponent,
    CutModelTypePipe,
    SwitchTranslatePipe,
    VerificationCodeComponent,
    BaseAuthComponent,
    ConfirmPasswordComponent,
    PresentsComponent,
    PresentEditorComponent,
    PointsAccountEditorComponent,
    PointsAccountsComponent,
    PointsAccountHistoryComponent,
    SettingsComponent,
    AdminsInfoComponent,
    AdminInfoEditorComponent,
    SelectAdminComponent,
    ModelBrandsComponent,
    ModelBrandEditorComponent,
    ModelsComponent,
    ModelEditorComponent,
    UploadFileComponent,
    OrderPresentsComponent,
    OrderPresentEditorComponent,
    ActivityComponent,
    ActivityEditorComponent,
    ActivityModelsComponent,
    SalesComponent,
    SaleEditorComponent,
    FormInputComponent,
    RulesOfParticipationComponent,
    InstructionComponent,
    GdprComponent,
    CppdComponent,
    TableSearchCalendarComponent,
    SupervisorsComponent,
    SupervisorEditComponent,
    CommissionCppdComponent,
    InstallerClientSmsEditorComponent,
    InstallerClientsSmsComponent,
    PointValueComponent,
  ],
  imports: [
    TrimValueAccessorModule,
    BrowserModule,
    FontAwesomeModule,
    RouterModule.forRoot(appRoutes),
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    ProgressBarModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    }),
    NgSelectModule,
    GalleriaModule,
    ConfirmDialogModule,
    BrowserAnimationsModule,
    FileUploadModule,
    DialogModule,
    CalendarModule,
    ScrollPanelModule,
    LightboxModule,
    EditorModule
  ],
  providers: [
    NavigationService,
    LoaderService,
    ServicesService,
    LogService,
    ConfirmationService,
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiHttpInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'ru' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
