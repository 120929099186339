import { IDataTableResponse } from 'src/services/interfaces/IDataTableResponse';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { DataTablesResponse } from "src/models/dataTablesResponse";
import { Sale } from 'src/models/sales/sale';
import { ISaleService } from 'src/services/interfaces/saleService/ISaleService';
import { Base } from 'src/models/base';
import { BaseService } from '../base.service';
import { LogService } from '../allServices/log.service';

@Injectable({
    providedIn: 'root'
})

export class SaleService implements ISaleService {
    
    api: string = environment.api;
    constructor(private http: HttpClient,
                private baseService: BaseService<Sale>,
                private log: LogService)
    { }
    getPage(dataTablesParameters: any): Observable<DataTablesResponse> {
        return this.http.post<DataTablesResponse>(`${this.api}/adminsales`, JSON.stringify(dataTablesParameters));
    }
    getAll(): Observable<Sale[]> {
        throw new Error("Method not implemented.");
    }
    getOne(id: number): Observable<Sale> {
        return this.baseService.getOne(id, "adminsale");
    }
    getAllStatus(): Observable<Base[]> {
        return this.http.get<Base[]>(`${this.api}/adminsalestatus`);
    }
    add(item: Sale): Observable<Sale> {
        throw new Error("Method not implemented.");
    }
    update(item: Sale): Observable<Sale> {
        return this.baseService.update(item, "adminsale");
    }
   
    logXls(dataTablesParameters: any): Observable<any> {
        return this.log.postXls(dataTablesParameters, "salesXls");
    }
    logXml(dataTablesParameters: any): Observable<any> {
        return this.log.postXml(dataTablesParameters, "salesXml");
    }
    logHistoryXls(dataTablesParameters?: any): Observable<any> {
        return this.log.getXls("salesHistoryXls");
    }
    logHistoryXml(dataTablesParameters?: any): Observable<any> {
        return this.log.getXml("salesHistoryXml");
    }
}