import { Injectable } from "@angular/core";
import { ModelType } from "src/models/model/modelType";
import { Observable, of } from "rxjs";
import { IBaseService } from "src/services/interfaces/IBaseService";
import { BaseService } from "../base.service";

@Injectable({
    providedIn: 'root'
})

export class ModelTypeService implements IBaseService<ModelType> {
    constructor(private baseService: BaseService<ModelType>) {}
    getAll(): Observable<ModelType[]> {
        return this.baseService.getAll("modeltypes");
    }
    getOne(id: number): Observable<ModelType> {
        return this.baseService.getOne(id, "modeltype");
    }
    add(item: ModelType): Observable<ModelType> {
        return this.baseService.add(item, "modeltype");
    }
    update(item: ModelType) {
        return this.baseService.update(item, "modeltype");
    }
}