import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/services/apiServices/allServices/navigation.service';

@Component({
  selector: 'app-base-editor-page',
  templateUrl: './base-editor-page.component.html',
  styleUrls: ['./base-editor-page.component.scss']
})
export class BaseEditorPageComponent implements OnInit {
  itemNotFound: boolean = false;
  constructor(private nav: NavigationService) { }

  ngOnInit() {
    this.nav.getItemNotFound().subscribe(value => this.itemNotFound = value);
  }
}
