import { IBase } from "src/interfaces/IBase";

export class Base implements IBase {
    id: number;    
    name?: string;
    
    constructor();
    constructor(id: number);
    constructor(id: number, name: string);
    constructor(id?: number, name?: string){
        if(id) this.id = id;
        if(name) this.name = name;
    }
}