export class NavItem{
    public subMenu: Array<NavItem>;
    public id: string;
    public name: string;
    public iconName: string;
    public url: string;
    public isSuperAdmin: boolean;
    constructor(name: string, id: string, url: string, isSuperAdmin: boolean, iconName?: string)
    {
        this.subMenu = new Array<NavItem>();
        this.name = name;
        this.id = id;
        this.url = url;
        this.isSuperAdmin = isSuperAdmin;
        this.iconName = iconName;
    }
}