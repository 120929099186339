import { MessageService } from 'src/services/apiServices/allServices/message.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { extend } from 'webdriver-js-extender';
import { EditorComponent } from 'src/models/editor';
import { Country } from 'src/models/geography/country';
import { NavigationService } from 'src/services/apiServices/allServices/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { BaseEditorPageComponent } from 'src/pages/base/base-editor-page/base-editor-page.component';
import { ServicesService } from 'src/services/services.service';
import { environment } from 'src/environments/environment';
import { MessageEventType } from 'src/enum/message/eventType';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-country-editor',
  templateUrl: './country-editor.component.html',
  styleUrls: ['./country-editor.component.scss']
})
export class CountryEditorComponent extends EditorComponent<Country> {
  apiCall: any;
  constructor(services: ServicesService,
              formBuilder: FormBuilder,
              api: ApiService,
              route: ActivatedRoute,
              translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.item = new Country();
    this.form = this.formBuilder.group({
      "name" : new FormControl('', [Validators.required])
    });
    this.apiCall = api.geography.country;
  }
  setValuesToObject(): void {
    Object.assign(this.item, this.form.value);
  }
  returnError(error: string){
    if(error.indexOf(environment.serverError.alreadyExist) > -1){
      this.form.controls['name'].setErrors({exist: true});
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error); 
  }
}
