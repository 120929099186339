import { Message } from '../../../models/message';
import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IMessageService } from 'src/services/interfaces/allServices/IMessageService';

@Injectable({
  providedIn: 'root'
})
export class MessageService implements IMessageService{
  answerDialogEvent: EventEmitter<boolean> = new EventEmitter();
  messageEvent: EventEmitter<Message> = new EventEmitter();
  constructor(protected translate: TranslateService) { }

  getMessageEvent(){
    return this.messageEvent;
  }
  setMessageEvent(message: Message){
    this.messageEvent.emit(message);
  }
  getAnswerDialogEvent(){
    return this.answerDialogEvent;
  }
  setAnswerDialogEvent(answer: boolean){
    this.answerDialogEvent.emit(answer);
  }

  sendAlertMessageEvent(message: Message){
    const that = this;
    this.translate.get(that.getEventType(message)).subscribe(res => {
        message.text = res;
        that.messageEvent.emit(message);
    }); 
  };

  sendAlertMessageText(message: Message, useTranslate: boolean = true){
    const that = this;
    if(useTranslate){
      this.translate.get(message.text).subscribe(res => {
        message.text = res;
        that.messageEvent.emit(message);
      });
    }
    else{
      this.messageEvent.emit(message);
    }
  }

  getEventType(message: Message): string {
      let event = '';
      switch(message.event){
        case message.messageEvent.add: 
          event = "info.message.successAdd";
          break;
        case message.messageEvent.edit: 
          event = "info.message.successUpdate";
          break;
      }
      return event;
  }

}
