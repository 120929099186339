import { Component, OnInit } from '@angular/core';
import {EditorComponentRule} from '../../../models/settings/editorComponentRule';
import {ServicesService} from '../../../services/services.service';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {ApiService} from '../../../services/api.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-commission-cppd',
  templateUrl: '../templateRule/rule.component.html',
  styleUrls: ['./commission-cppd.component.scss']
})
export class CommissionCppdComponent extends EditorComponentRule {
  nameTranslate = 'model.commissionCppd';
  apiCall: any;
  constructor(services: ServicesService,
              route: ActivatedRoute,
              formBuilder: FormBuilder,
              api: ApiService,
              translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.apiCall = api.settings.commissionCppd;
  }
  returnError(error: any) {
    throw new Error('Method not implemented.');
  }
}
