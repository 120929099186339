import { ModelBrand } from './../../../models/model/modelBrand';
import { Component } from '@angular/core';
import { EditorComponent } from 'src/models/editor';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-model-brand-editor',
  templateUrl: './model-brand-editor.component.html',
  styleUrls: ['./model-brand-editor.component.scss']
})
export class ModelBrandEditorComponent extends EditorComponent<ModelBrand> {
  apiCall: any;
  constructor(services: ServicesService, 
              route: ActivatedRoute, 
              formBuilder: FormBuilder, 
              api: ApiService, 
              translate: TranslateService) { 
    super(services, route, formBuilder, api, translate);
    this.item = new ModelBrand();
    this.form = this.formBuilder.group({
      "name" : new FormControl('', [Validators.required])
    });
    this.apiCall = api.model.modelBrand;
  }
  setValuesToObject(): void {
    Object.assign(this.item, this.form.value);
  }
  returnError(error: any) {
    if(error.indexOf(environment.serverError.alreadyExist) > -1){
      this.form.controls['name'].setErrors({exist: true});
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error); 
  }

}
