import { User } from 'src/models/users/user';
import { ApiService } from 'src/services/api.service';
import { OnInit, OnDestroy, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { Select } from './select';
import { SelectUser } from '../users/selectUser';

export abstract class SelectUserBase implements OnInit, OnDestroy {
    @Input() name: string;
    @Input() isDisabled: boolean = false;
    @Input() parentForm: FormGroup;
    @Input() onSubmit: EventEmitter<any>;
    @ViewChild('ngForm') _form: NgForm;
    @ViewChild('selectItem') selectItem: ElementRef;
    @Input() selectUser: SelectUser;
    select: Select;

    constructor(protected api: ApiService) {
        this.select = new Select();
        this.select.onIsDisabled.subscribe(isDisabled => this.onIsDisabled(isDisabled));
    }
    ngOnInit(): void {
        this.onSubmit.subscribe(_ => this._form.ngSubmit.emit());
    }

    ngOnDestroy(): void {
        this.onSubmit.unsubscribe();
    }
    setUsers(users: SelectUser[]) {
        this.select.setItems(users);
        if (this.selectUser.id) {
            this.select.setActive(this.selectUser.id);
        }
        if (this.select.HasSelected) {
            this.parentForm.controls[this.name].setValue(this.select.Active.id);
        }
        this.select.IsDisabled = this.isDisabled;
    }
    selected(value: any) {
        if (!value) { return; }
        this.select.selected(value.id);
    }
    onIsDisabled(isDisabled: boolean) {
        const control = this.parentForm.controls[this.name];
        isDisabled ? control.disable() : control.enable();
    }
    clear() {
        this.select.removed();
    }
}
