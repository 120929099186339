import { SelectList } from "./selectList";
import { EventEmitter } from "@angular/core";

export class SelectOptions {
    selectItems: string[] = [];
    selectItemsEvent: EventEmitter<SelectList> = new EventEmitter();
    hasLog: boolean = false;
    constructor();
    constructor(selectItems: string[]);
    constructor(selectItems: string[], hasLog: boolean);
    constructor(selectItems?: string[], hasLog?: boolean){
        if(selectItems) this.selectItems = selectItems;
        if(hasLog) this.hasLog = hasLog;
    }
}