import { Component, OnInit } from '@angular/core';
import { EditorComponent } from 'src/models/editor';
import { OrderPresent } from 'src/models/presents/orderPresent';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Base } from 'src/models/base';
import { SelectUser } from 'src/models/users/selectUser';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import {OrderPresentHistory} from '../../../models/presents/orderPresentHistory';

@Component({
  selector: 'app-order-present-editor',
  templateUrl: './order-present-editor.component.html',
  styleUrls: ['./order-present-editor.component.scss']
})
export class OrderPresentEditorComponent extends EditorComponent<OrderPresent> {
  apiCall: any;
  orderStatus: Base[];
  selectStatus: number;
  shipped = 4;
  cancel = 6;
  orderPresentHistory: OrderPresentHistory[];
  constructor(services: ServicesService,
              route: ActivatedRoute,
              formBuilder: FormBuilder,
              api: ApiService,
              translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.apiCall = api.present.order;
    this.form = this.formBuilder.group({
      'address' : new FormControl('', [Validators.required]),
      'orderStatus' : new FormControl('', [Validators.required]),
      'waybillNumber' : new FormControl(''),
      'comment' : new FormControl(''),
      'lastCheckDate': new FormControl(''),
      'createdAt': new FormControl(''),
      'notes': new FormControl('')
    });
  }
  setValues(): void {
    const observable = forkJoin(
      this.api.present.order.getAllStatus(),
      this.api.present.order.getOne(this.id),
      this.api.present.order.getLogHistory(this.id)
    );
    observable.subscribe(
      res => {
        this.orderStatus = res[0];
        const item = res[1] as OrderPresent;
        this.item = item;
        this.orderPresentHistory = res[2];
        this.changeValidators(this.item.orderStatus.id);
        this.form.patchValue({
          ...this.item,
          'orderStatus': this.selectStatus,
          'lastCheckDate': this.item.user.lastCheckDate,
          'createdAt': this.item.createdAt
        });

        if (this.selectStatus === 6) {
          this.item.admin = item.admin;
        } else {
          this.item.admin = new SelectUser(this.currentUser.id, this.currentUser.email);
        }
        this.InProgress = false;
      },
      e => this.catchError(e)
    );
  }

  setValuesToObject(): void {
    const { waybillNumber, comment, orderStatus, lastCheckDate, ...valuesToSubmit } = this.form.value;
    Object.assign(this.item, valuesToSubmit);
    this.item.orderStatus = new Base(orderStatus);
    this.item.user.lastCheckDate = lastCheckDate ? moment(lastCheckDate).format('YYYY-MM-DD') : null;
    if (this.selectStatus === this.shipped) {
      this.item.orderStatus.id = this.shipped;
      this.item.waybillNumber = waybillNumber;
    } else if (this.selectStatus === this.cancel) {
      this.item.orderStatus.id = this.cancel;
      this.item.comment = comment;
    }

  }
  returnError(error: any) {
    throw new Error('Method not implemented.');
  }
  onChange(value: any) {
    this.changeValidators(parseInt(value));
  }
  changeValidators(id: number) {
    this.selectStatus = id;
    const waybillNumber = this.form.controls['waybillNumber'];
    const comment = this.form.controls['comment'];

    if (id === this.shipped) {
      waybillNumber.setValidators(Validators.required);
      comment.setValidators([]);
      comment.setErrors(null);
    } else if (id === this.cancel) {
      waybillNumber.setValidators([]);
      waybillNumber.setErrors(null);
      comment.setValidators(Validators.required);
    } else {
      waybillNumber.setValidators([]);
      waybillNumber.setErrors(null);
      comment.setValidators([]);
      comment.setErrors(null);
    }
  }
}
