import { CurrentUser } from '../../models/users/currentUser';
import { ApiService } from 'src/services/api.service';
import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { NavItem } from 'src/models/navItem';
import { Location } from '@angular/common';
import * as $ from 'jquery';
import { NavigationService } from 'src/services/apiServices/allServices/navigation.service';

@Component({
  selector: 'main-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {
  private activeId: string;
  set ActiveId(value: string){
    if(this.activeId === value)
      this.activeId = '';
    else
      this.activeId = value;
  }
  get ActiveId(): string{
    return this.activeId;
  }
  private activeSubId: string;
  set ActiveSubId(value: string){
    this.activeSubId = value;
  }
  get ActiveSubId(): string{
    return this.activeSubId;
  }
  @Input() currentUser: CurrentUser;
  public menu: Array<NavItem>;
  constructor(private location: Location,
              private nav: NavigationService,
              private api: ApiService) { 
    
  }

  ngOnInit() {
    this.setMenu();
    this.setDefMenu();
  }
  ngAfterViewInit(): void {
    let activeSumMenu = $(".sidebar-submenu li.active")[0];
    $(activeSumMenu).parents(".collapse").addClass("show");
  }
  private setMenu(){
    this.menu = this.nav.getAllMenu(this.currentUser.isSuperAdmin);
  }
  private setDefMenu(){
    this.ActiveId = this.nav.getMenu().url;
    this.ActiveSubId = this.nav.getSubmenu().url;
  }
  
}
