import {Supervisor} from '../../../models/users/supervisor';
import { Component } from '@angular/core';
import { DatatablesServerComponent } from 'src/models/datatablesServer';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { IDataTableResponse } from 'src/services/interfaces/IDataTableResponse';
import { TitleItem } from 'src/models/titleItem';
import { getColumnTitles } from 'src/utils/dt/columns';

@Component({
  selector: 'app-supervisors',
  templateUrl: './supervisors.component.html',
  styleUrls: ['./supervisors.component.scss']
})
export class SupervisorsComponent  extends DatatablesServerComponent<Supervisor> {
  columnsData: any[];
  columnNames: TitleItem[];
  filename = 'Супервайзры';
  apiCall: IDataTableResponse<Supervisor>;

  constructor(api: ApiService,
              services: ServicesService) {
    super(api, services);
    this.setInitValues();
  }

  setInitValues(): void {
    const apiCall = this.api.supervisor;
    this.apiCall = apiCall;
    this.apiLog = apiCall;
    this.pageLength = 5;
    this.columnNames = getColumnTitles([
      'id', 'name.fullName', 'email',
      'phone', 'regions'
    ]);
    const dtType = this.dtType;
    this.columnsData = [
      ['id', this.urlReturn], 'name', 'email', 'phone', ['regions', dtType.array]
    ];
  }
  ngOnInit(): void {
    this.setInit();
    this.dtOptions = {
      ...this.dtOptions,
      order: [[1, 'desc']],
      buttons: [
        ...this.dtOptions.buttons
      ]
    };
  }

}
