import { MessagePosition } from 'src/enum/message/position';
import { Message } from './message';
import { ServicesService } from 'src/services/services.service';
import { MessageColor } from 'src/enum/message/color';
import { MessageEventType } from 'src/enum/message/eventType';

export abstract class MessageManager{
    protected message: Message;

    abstract returnError(error: any);
    protected abstract urlReturn: string;

    constructor(protected services: ServicesService){
        this.message = new Message();
    }

    sendAlert(color: MessageColor, event: MessageEventType);
    sendAlert(color: MessageColor, event: MessageEventType, position: MessagePosition);
    sendAlert(color: MessageColor, text: string);
    sendAlert(color: MessageColor, text: string, position: MessagePosition, useTranslate: boolean);
    sendAlert(color: MessageColor, eventOrText: MessageEventType|string, position: MessagePosition = MessagePosition.float, useTranslate: boolean = false){
        this.message.position = position;
        this.message.color = color;
        this.message.type = this.message.messageType.alert;
        if(typeof eventOrText === 'string'){
            this.message.text = eventOrText;
            this.services.message.sendAlertMessageText(this.message, useTranslate);
        }
        else{
            this.message.event = eventOrText;
            this.services.message.sendAlertMessageEvent(this.message);
        }
    }

    sendSuccessAlert(text: string, useTranslate: boolean = true){
        this.message.text = text;
        this.message.position = this.message.messagePosition.fix;
        this.message.color = this.message.messageColor.success;
        this.message.type = this.message.messageType.alert;
        this.services.message.sendAlertMessageText(this.message, useTranslate);
    }

    returnResult(res: any, event: MessageEventType){
        if(res.error){
            this.returnError(res.error);
        }
        else if(res.errors){
            this.returnError(res.errors);
        }
        else{
            this.sendAlert(this.message.messageColor.success, event);
            this.services.nav.redirectTo(this.urlReturn);
        }
    }

    handleDelete(){
        this.message.text = "Вы точно хотите удалить?";
        this.message.type = this.message.messageType.popup;
        this.services.message.setMessageEvent(this.message);
    }

    sendPhoto(text: string){
        this.message.text = text;
        this.message.type = this.message.messageType.image;
        this.services.message.sendAlertMessageText(this.message, false);
    }
}