import { AdminInfo } from 'src/models/settings/adminInfo';
import { Component } from '@angular/core';
import { EditorComponent } from 'src/models/editor';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { SelectUser } from 'src/models/users/selectUser';

@Component({
  selector: 'app-admin-info-editor',
  templateUrl: './admin-info-editor.component.html',
  styleUrls: ['./admin-info-editor.component.scss']
})
export class AdminInfoEditorComponent extends EditorComponent<AdminInfo> {
  apiCall: any;
  constructor(services: ServicesService,
              route: ActivatedRoute,
              formBuilder: FormBuilder,
              api: ApiService,
              translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.form = this.formBuilder.group({
      'notificationType' : new FormControl('', [Validators.required]),
      'admin' : new FormControl('', [Validators.required]),
      'phone' : new FormControl('', [Validators.required]),
      'email' : new FormControl('', [Validators.required, Validators.email]),
      'skype' : new FormControl('', [Validators.required]),
      'viber' : new FormControl('', [Validators.required]),
      'whatsapp' : new FormControl('', [Validators.required])
    });
    this.apiCall = api.settings.adminInfo;
  }
  setPatchValue() {
    this.form.patchValue({
      ...this.item,
      'admin': '',
      'notificationType': this.item.notificationType.name
    });
  }
  setValuesToObject(): void {
    const {notificationType, admin, ...valuesToSubmit} = this.form.value;
    if (admin) {
      this.item.admin = new SelectUser(admin);
    }
    Object.assign(this.item, valuesToSubmit);
  }
  apiAdd(): void {
    throw new Error('Method not implemented.');
  }
  returnError(error: any) {
    throw new Error('Method not implemented.');
  }

}
