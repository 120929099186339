import { SelectOptions } from './../../models/form/selectOptions';
import { Component, OnInit, EventEmitter, ViewChild, Input, OnDestroy } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { SelectList } from 'src/models/form/selectList';

@Component({
  selector: 'app-select-geography',
  templateUrl: './select-geography.component.html',
  styleUrls: ['./select-geography.component.scss']
})
export class SelectGeographyComponent implements OnInit, OnDestroy {
  @Input() selectOptions: SelectOptions;
  @Input() parentForm: FormGroup;
  @Input() onSubmit: EventEmitter<any>;
  @ViewChild('ngForm') _form: NgForm;
  selectList: SelectList;
  constructor() { }

  ngOnInit() {
    this.selectList = new SelectList(this.selectOptions.selectItems, this.parentForm, this.selectOptions.selectItemsEvent);
    this.onSubmit.subscribe(_ => {
      this._form.ngSubmit.emit();
    });
    this.selectOptions.selectItemsEvent.emit(this.selectList);
  }
  ngOnDestroy(): void {
    this.onSubmit.unsubscribe();
  }

  selected(value: any, name: string) {
    if (!value) { return; }
    this.selectList.selected(value, name);
  }

  removed(name: string) {
    this.selectList.removed(name);
  }
}
