import { Component } from '@angular/core';
import { DatatablesServerComponent } from 'src/models/datatablesServer';
import { OrderPresent } from 'src/models/presents/orderPresent';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { TitleItem } from 'src/models/titleItem';
import { getColumnTitles, getColumnData } from 'src/utils/dt/columns';

@Component({
  selector: 'app-order-presents',
  templateUrl: './order-presents.component.html',
  styleUrls: ['./order-presents.component.scss']
})
export class OrderPresentsComponent extends DatatablesServerComponent<OrderPresent> {
  columnsData: any[];
  columnNames: TitleItem[];
  apiCall: any;
  filename: string = "Заказы подарков";
  constructor(api: ApiService,
              services: ServicesService) {
    super(api, services);
    this.setInitValues();
  }

  setInitValues(): void {
    this.apiCall = this.api.present.order;
    this.apiLog = this.api.present.order;
    this.apiLogHistory = this.api.present.order;
    this.pageLength = 5;
    this.columnNames = getColumnTitles([
      'id', 'orderStatus', 'lastName', 'firstName', 'middleName', 'phone',
      'postAddress', 'deliveryRequired', 'name.present', 'waybillNumber',
      ['adminLogin', true], ['comment', true], 'cost',
      'userLogin', 'category', 'lastCheckDate', 'createdAt', 'tradingPlaceAtSalTime', 'cityAtSalTime', 'regionAtSalTime', 'supervisorFullName', 'email'
    ]);
    const dtType = this.dtType;
    this.columnsData = [
      ['id', this.urlReturn], 'orderStatus.name', 'user.lastName', 'user.firstName',
      'user.middleName', 'user.phone', 'address', ['present.isDeliveryRequired', dtType.yesOrNo],
      'present.name', 'waybillNumber', ['admin.email', dtType.string, false, false],
      ['comment', dtType.longText, false], 'points',
      'user.email', 'user.category', 'user.lastCheckDate', 'createdAt', 'tradingPlaceName', 'tradingPlaceCity', 'tradingPlaceRegion', 'supervisorName', 'supervisorEmail'
    ];
  }

  ngOnInit(): void {
    this.setInit();
    this.dtOptions = {
      ...this.dtOptions,
      order: [[ 1, 'desc' ]],
      buttons: [
        ...this.dtOptions.buttons,
        this.exportLogButtons
      ]
    }
  }
}
