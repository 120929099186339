import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cutModelType'
})
export class CutModelTypePipe implements PipeTransform {
  transform(value: string): string {
    if (typeof value !== 'string') {
      throw new Error('CutModelTypePipe: should be a string');
    }
    return value.length > 7 ? `${value.slice(0, 7)}...` : value;
  }
}
