import { AdminInfo } from './../../../models/settings/adminInfo';
import { DatatablesComponent } from 'src/models/datatables';
import { Component } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { TitleItem } from 'src/models/titleItem';
import { getColumnTitles } from 'src/utils/dt/columns';

@Component({
  selector: 'app-admins-info',
  templateUrl: './admins-info.component.html',
  styleUrls: ['./admins-info.component.scss']
})
export class AdminsInfoComponent  extends DatatablesComponent<AdminInfo>{
  columnNames: TitleItem[];
  apiCall: any;
  constructor(api: ApiService, 
              services: ServicesService) { 
    super(api, services);
    this.apiCall = api.settings.adminInfo;
    const names = [ 
      'notificationType', 'admin', 'phone', 'email', 'skype',
      'viber', 'whatsapp'
    ];
    this.columnNames = getColumnTitles(names);
  }
}
