import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class BaseService<T> {
    private api: string = environment.api;
    constructor(protected http: HttpClient) {
    }
    getAll(uri: string): Observable<T[]> {
        return this.http.get<T[]>(`${this.api}/${uri}`);
    }
    getOne(id: number, uri: string): Observable<T> {
        return this.http.get<T>(`${this.api}/${uri}/${id}`);
    }
    add(item: T, uri: string): Observable<T> {
        return this.http.post<T>(`${this.api}/${uri}`, JSON.stringify(item));
    }
    update(item: T, uri: string): Observable<T> {
        const temp: any = item;
        return this.http.put<T>(`${this.api}/${uri}/${temp.id}`, JSON.stringify(item));
    }
    // delete(id: number) {
    //     throw new Error("Method not implemented.");
    // }
}