import { Message } from './../../models/message';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'src/services/apiServices/allServices/message.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {
  message: Message = new Message();
  timer: any;
  display = false;
  useTimer: boolean;
  constructor(private messageService: MessageService,
              private confirmationService: ConfirmationService) { }

  ngOnInit() {
    const that = this;
    this.messageService.getMessageEvent().subscribe(data => {
      this.message = data;
      this.message.display = this.message.messageDisplay.show;
      this.useTimer = true;
      if (this.message.type === this.message.messageType.popup) {
        this.message.display = this.message.messageDisplay.hide;
        this.confirm();
      } else if (this.message.type === this.message.messageType.image) {
        this.useTimer = false;
        this.display = true;
      }
      if (this.useTimer) {
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(function () {
          that.hide();
          that.timer = null;
        }.bind(that), 3000);
      }
    });
  }
  ngOnDestroy(): void {
    this.messageService.getMessageEvent().unsubscribe();
  }
  hide() {
    this.message.display = this.message.messageDisplay.hide;
  }
  confirm() {
    this.confirmationService.confirm({
      message: this.message.text,
      accept: () => {
        this.messageService.setAnswerDialogEvent(true);
      }
    });
  }
}
