import { EditorComponentRule } from 'src/models/settings/editorComponentRule';
import { Component } from '@angular/core';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-gdpr',
  templateUrl: '../templateRule/rule.component.html',
  styleUrls: ['./gdpr.component.scss']
})
export class GdprComponent extends EditorComponentRule {
  nameTranslate: string = 'model.gdpr';
  apiCall: any;
  constructor(services: ServicesService, 
              route: ActivatedRoute, 
              formBuilder: FormBuilder, 
              api: ApiService, 
              translate: TranslateService) 
  { 
    super(services, route, formBuilder, api, translate);
    this.apiCall = api.settings.gdpr;
  }
  returnError(error: any) {
    throw new Error("Method not implemented.");
  }
}
