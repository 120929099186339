import { SaleService } from './apiServices/saleService/sale.service';
import { ActivityService } from './apiServices/activityService/activity.service';
import { SettingsService } from './apiServices/settingsService/settings.service';
import { AuthService } from './apiServices/auth.service';
import { TradingPlaceService } from './apiServices/tradingPlaceService/tradingPlace.service';
import { ModelService } from './apiServices/modelService/model.service';
import { Injectable } from '@angular/core';
import { IUserService } from './interfaces/userSevice/IUserService';
import { UserService } from './apiServices/userService/user.service';
import { IGeographyService } from './interfaces/geographyService/IGeographyService';
import { GeographyService } from './apiServices/geographyService/geography.service';
import { IModelService } from './interfaces/modelService/IModelService';
import { IAuthService } from './interfaces/IAuthService';
import { IPresentService } from './interfaces/presentService/IPresentService';
import { PresentService } from './apiServices/presentService/present.service';
import { ISettingsService } from './interfaces/settingsService/ISettingsService';
import { IActivityService } from './interfaces/activityService/IActivityService';
import { ITradingPlaceService } from './interfaces/ITradingPlaceService/ITradingPlaceService';
import { ISaleService } from './interfaces/saleService/ISaleService';
import {ISupervisorService} from './interfaces/supervisorService/ISupervisorService';
import {SupervisorService} from './apiServices/supervisorService/supervisor.service';
import {IInstallerClientSMSService} from './interfaces/settingsService/IInstallerClientSMSService';
import {InstallerClientSMSService} from './apiServices/settingsService/InstallerClientSMSService';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public user: IUserService;
  public geography: IGeographyService;
  public model: IModelService;
  public tradingPlace: ITradingPlaceService;
  public auth: IAuthService;
  public present: IPresentService;
  public settings: ISettingsService;
  public activity: IActivityService;
  public sale: ISaleService;
  public supervisor: ISupervisorService;
  public installerClientSMS: IInstallerClientSMSService;

  constructor(user: UserService,
              geography: GeographyService,
              model: ModelService,
              tradingPlace: TradingPlaceService,
              auth: AuthService,
              present: PresentService,
              settings: SettingsService,
              activity: ActivityService,
              sale: SaleService,
              supervisor: SupervisorService,
              installerClientSMS: InstallerClientSMSService
  ) {
    this.user = user;
    this.geography = geography;
    this.model = model;
    this.tradingPlace = tradingPlace;
    this.auth = auth;
    this.present = present;
    this.settings = settings;
    this.activity = activity;
    this.sale = sale;
    this.supervisor = supervisor;
    this.installerClientSMS = installerClientSMS;
  }

}
