import { MessageService } from 'src/services/apiServices/allServices/message.service';
import { CurrentUser } from 'src/models/users/currentUser';
import { Component, OnInit, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { NavigationService } from 'src/services/apiServices/allServices/navigation.service';
import { Message } from 'src/models/message';
import { environment } from 'src/environments/environment';
import { Login } from 'src/models/login';
import { ApiService } from 'src/services/api.service';
import { AuthComponent } from 'src/models/auth';
import { ServicesService } from 'src/services/services.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends AuthComponent {
  login: Login;
  loaded: boolean = false;
  name: string;
  constructor(formBuilder: FormBuilder, 
              api: ApiService, 
              services: ServicesService,
              private nav: NavigationService) { 
    super(formBuilder, api, services);
    this.form = this.formBuilder.group({
      "email": new FormControl('', [Validators.required, Validators.email]),
      "password": new FormControl('', [Validators.required]),
      "remember": new FormControl(false)
    });
    this.login = new Login();
  }

  ngOnInit() {
    this.name = this.nav.PathMenu;
    let currentUser = this.api.auth.getCurrentUser();
    if(currentUser.isAuthorized){
      const prevUrl = this.services.nav.getPreviousUrl();
      this.services.nav.redirectTo(prevUrl);
      let message = new Message();
      message.text = "info.message.alreadyLogin";
      message.color = message.messageColor.error;
      this.services.message.sendAlertMessageText(message);
      return;
    }
    let history = this.services.nav.getHistory();
    if(history.length > 2 || history.length === 1){
      this.loaded = true;
    }
    this.api.auth.getCurrentUserEvent().subscribe(currentUser => {
      if(currentUser.isAuthorized){
        const prevUrl = this.services.nav.getPreviousUrl();
        this.services.nav.redirectTo(prevUrl);
      }
      else{
        this.loaded = true;
      }
    });
  }
  submitValue() {
    this.api.auth.postLogin(this.login).subscribe(
      currentUser => this.returnResult(currentUser), 
      error => this.catchError(error)
    )
  }
  setValuesToObject(){
    Object.assign(this.login, this.form.value);
  }
  returnResult(res: any){
    let user = res as CurrentUser;
    user.isAuthorized = true;
    user.email = this.login.email;
    this.api.auth.setCurrentUserEvent(user);
    this.services.nav.redirectTo("users/users");
  }
  catchError(error: string) {
    if(error === environment.serverError.wrongPassword){
      this.form.controls['password'].setErrors({notCorrect: true});
      this.onSubmit.emit();
    }
    if(error === environment.serverError.emailNotFound ||
       error === environment.serverError.notAuthorizedRole){
      this.form.controls['email'].setErrors({'notCorrect': true});
      this._form.ngSubmit.emit();
    }
    if(error === environment.serverError.delete){
      this.form.controls['email'].setErrors({'delete': true});
      this._form.ngSubmit.emit();
    }
  }
}
