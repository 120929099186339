import { Log } from "./log";
import { TranslateService } from "@ngx-translate/core";
import { IBase } from 'src/interfaces/IBase';

export class LogHistory{
    private history: Log[] = [];
    private translateHistory: Log[] = [];
    get History(){
        return this.history;
    }
    
    get TranslateHistory(){
        return this.translateHistory;
    }

    constructor(public useId: number, public superAdminId: number, public translateService: TranslateService){
    }

    private addHistory(valueType: string, valueBefore: string, valueAfter: string){
        const log = new Log(this.useId, this.superAdminId, valueType, valueBefore, valueAfter);
        this.history.push(log);
    }
    updateHistory(valueType: string, valueAfter: string){
        this.history.forEach(log => {
            if(log.valueType === valueType){
                log.valueAfter = valueAfter;
            }
        })
    }
    checkType(valueType: string): boolean{
        return this.history.some(log => log.valueType === valueType);
    }
    clear(){
        this.history = this.history.filter(log=> {
            if(log.valueBefore !== log.valueAfter && log.valueAfter !== '')
                return log;
        });
        this.translateHistory = [];
    }
    translate(){
        this.clear();
        this.history.forEach(log => {
            this.translateService.get(`form.field.${log.valueType}`).subscribe(name => {
                this.translateHistory.push(new Log(log.userId, log.superAdminId, name, log.valueBefore, log.valueAfter));
            });
        });
    }
    compareField(valueType: string, valueBefore: string, valueAfter: string){
        if(this.checkType(valueType)){
            return this.updateHistory(valueType, valueAfter);
        }
        if(valueBefore !== valueAfter){
            return this.addHistory(valueType, valueBefore, valueAfter);
        }
    }
    compareBase(valueType: string, valueBefore: any, valueAfter: any, list: IBase[]){
        if(valueBefore.id)
            valueBefore = valueBefore.id;
        const item = list.find(val => val.id === parseInt(valueBefore));
        const value = list.find(val => val.id === parseInt(valueAfter));
        this.compareField(valueType, item.name, value.name);
    }
    compareGeography(valueType: string, valueAfter: any){
        if(this.checkType){
            this.updateHistory(valueType, valueAfter);
        }
    }
    compareModelType(valueType: string, valueBefore: any, valueAfter: any, list: IBase[]){
        let items = [];
        let values = [];
        for (let i = 0; i < list.length; i++) {
            if(valueBefore[i]){
                if(valueBefore[i].id)
                    items.push(valueBefore[i].name);
                else
                    items.push(list[i].name);
            }
            if(valueAfter[i]) 
                values.push(list[i].name);
        }
        this.compareField(valueType, items.join(', '), values.join(', '));
    }
    compareBoolean(valueType: string, valueBefore: boolean, valueAfter: boolean){
        return this.translateService.get([`info.text.yes`,`info.text.no`]).subscribe(name => {
            const before = valueBefore ? name['info.text.yes'] : name['info.text.no'];
            const after = valueAfter ? name['info.text.yes'] : name['info.text.no'];
            this.compareField(valueType, before, after);
        });
    }
    compareEnabled(valueType: string, valueBefore: boolean, valueAfter: boolean){
        return this.translateService.get([`info.text.active`,`info.text.noActive`]).subscribe(name => {
            const before = valueBefore ? name['info.text.active'] : name['info.text.noActive'];
            const after = valueAfter ? name['info.text.active'] : name['info.text.noActive'];
            this.compareField(valueType, before, after);
        });
    }
}