import { LogService } from './../allServices/log.service';
import { IPointsAccountService } from "src/services/interfaces/userSevice/IPointsAccountService";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { DataTablesResponse } from "src/models/dataTablesResponse";
import { PointsAccount } from "src/models/users/pointsAccount";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "../base.service";

@Injectable({
    providedIn: 'root'
})

export class PointsAccountService implements IPointsAccountService {
    
    
    api: string = environment.api;
    constructor(private http: HttpClient,
                private baseService: BaseService<PointsAccount>,
                private log: LogService)
    {
    }
    getPage(dataTablesParameters: any): Observable<DataTablesResponse> {
        return this.http.post<DataTablesResponse>(`${this.api}/adminaccounts`, JSON.stringify(dataTablesParameters));
    }
    getHistoryPage(dataTablesParameters: any, idPointsAccount: number): Observable<DataTablesResponse> {
        return this.http.post<DataTablesResponse>(`${this.api}/adminaccounthistory/${idPointsAccount}`, JSON.stringify(dataTablesParameters));
    }
    getAll(): Observable<PointsAccount[]> {
        throw new Error("Method not implemented.");
    }
    getOne(id: number): Observable<PointsAccount> {
        return this.baseService.getOne(id, "adminaccount");
    }
    add(item: PointsAccount): Observable<PointsAccount> {
        throw new Error("Method not implemented.");
    }
    update(item: PointsAccount): Observable<PointsAccount> {
        throw new Error("Method not implemented.");
    }

    logXls(dataTablesParameters: any): Observable<any> {
        return this.log.postXls(dataTablesParameters, "accountsXls");
    }
    logXml(dataTablesParameters: any): Observable<any> {
        return this.log.postXml(dataTablesParameters, "accountsXml");
    }
}