import { ApiService } from './../../../services/api.service';
import { DatatablesComponent } from 'src/models/datatables';
import { Component } from '@angular/core';
import { Country } from 'src/models/geography/country';
import { ServicesService } from 'src/services/services.service';
import { TitleItem } from 'src/models/titleItem';
import { getColumnTitles } from 'src/utils/dt/columns';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})
export class CountriesComponent extends DatatablesComponent<Country>  {
  columnNames: TitleItem[];
  apiCall: any;
  constructor(protected api: ApiService,
              protected services: ServicesService)
  {
    super(api, services);
    this.apiCall = api.geography.country;
    this.columnNames = getColumnTitles(['name.country']);
  }
}
