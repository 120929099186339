export enum DtColumnType {
    string,
    date,
    photo,
    longText,
    array,
    arrayString,
    yesOrNo,
    enabled,
    delete,
    manual,
    dateShort
}
