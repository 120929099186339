import { ModelBrand } from './../../../models/model/modelBrand';
import { DatatablesComponent } from 'src/models/datatables';
import { Component } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { TitleItem } from 'src/models/titleItem';
import { getColumnTitles } from 'src/utils/dt/columns';

@Component({
  selector: 'app-model-brands',
  templateUrl: './model-brands.component.html',
  styleUrls: ['./model-brands.component.scss']
})
export class ModelBrandsComponent extends DatatablesComponent<ModelBrand> {
  columnNames: TitleItem[];
  apiCall: any;
  constructor(api: ApiService, 
              services: ServicesService) { 
    super(api, services);
    this.apiCall = api.model.modelBrand;
    this.columnNames = getColumnTitles(['name.brand']);
  }
}
