import { EventEmitter } from '@angular/core';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NavigationService } from 'src/services/apiServices/allServices/navigation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() id: number;
  items: string[];
  menu: string; 
  submenu: string; 
  editor: string;
  private breadCrumbsEvent: EventEmitter<string>;
  constructor(private nav: NavigationService) { }

  ngOnInit() {
    this.breadCrumbsEvent = this.nav.getBreadCrumbs().subscribe((uri: string) => {
      if(uri !== '/logout' && uri !== '/login'){
        this.getBreadCrumbs();
      }
    });
    this.getBreadCrumbs();
  }
  private getBreadCrumbs(){
    this.menu = this.nav.getMenu().name;
    this.submenu = this.nav.getSubmenu().name;
    this.editor = this.nav.IsEditor ? ( this.nav.IsEditorHasId ? 'menu.edit' : 'menu.create') : '';
  }
  ngOnDestroy(): void {
    this.breadCrumbsEvent.unsubscribe();
  }
}
