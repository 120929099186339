import { Message } from './message';
import { Subject } from 'rxjs/internal/Subject';
import { OnInit, OnDestroy, ViewChild } from "@angular/core";
import { DataTableDirective } from 'angular-datatables';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { MessageManager } from './messageManager';
import { IBaseService } from 'src/services/interfaces/IBaseService';
import { SearchOption } from './searchOption';
import { TitleItem } from './titleItem';
import { DtColumnType } from './datatable/dtColumnType';

export abstract class DatatablesComponent<T> extends MessageManager implements OnInit, OnDestroy {
    dtOptions: any = {};
    dtTrigger: Subject<any> = new Subject();
    @ViewChild(DataTableDirective)
    datatableElement: DataTableDirective;
    protected answerSubscription;
    protected lang: any;
    protected message: Message;
    protected deleteButton: any;
    protected filterButton: any;
    protected dtType = DtColumnType;
    // abstract tableName: string; 

    protected urlReturn: string;

    items: Array<T>;
    abstract apiCall: IBaseService<T>;
    abstract columnNames: TitleItem[];

    constructor(protected api: ApiService,
        protected services: ServicesService) {
        super(services);
        this.answerSubscription = this.services.message.getAnswerDialogEvent().subscribe(answer => {
            if (answer) {
                this.delete();
                this.sendSuccessAlert("Элемент успешно удален.");
            }
        });
    }
    ngOnInit(): void {
        this.setInit();
        this.apiCall.getAll().subscribe(items => {
            this.items = items;
            this.dtTrigger.next();
            this.rerender();
        });
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
        this.answerSubscription.unsubscribe();
    };
    setInit() {
        this.urlReturn = this.services.nav.getSubmenu().url;
        const that = this;
        this.lang = environment.language;
        this.filterButton = {
            key: 3,
            text: "Фильтры",
            attr: {
                "data-toggle": "collapse",
                "data-target": "#filter",
                "aria-expanded": "false",
                "aria-controls": "filter"
            },
            className: "btn-filter",
            action: function (e, dt, node, conf) {

            }
        };
        this.deleteButton = {
            key: 2,
            text: "Удалить",
            action: function (e, dt, node, conf) {
                that.handleDelete();
            },
            className: "delete",
            enable: false
        };


        this.dtOptions = {
            language: this.lang,
            orderCellsTop: true,
            lengthMenu: [[5, 10, 25, 50, 100], [5, 10, 25, 50, 100]],
            pageLength: 10,
            bStateSave: true,
            stateSaveCallback: function (settings, data) {
                let url = that.services.nav.getPreviousUrl();
                if(that.hasDatatablesData(url)) return;
                localStorage.setItem('DatatablesData', JSON.stringify(data));
            },
            stateLoadCallback: function () {
                try {
                    const data = JSON.parse(localStorage.getItem('DatatablesData'));
                    if(!data) return  {};
                    const nav = that.services.nav;
                    let url = nav.getPreviousUrl();
                    if (url === '/login') {
                        url = nav.getPreviousUrl(3);
                    }
                    if (url.indexOf(that.urlReturn) === -1 ||
                        that.hasDatatablesData(url)) {
                            return {};
                    }
                    
                    let searchOptions: Array<SearchOption> = [];
                    data.columns.map(function (val, i) {
                        const search = val.search.search;
                        if (search) {
                            searchOptions.push(new SearchOption(i, search));
                        }
                    });
                    that.updateDisplaySearchInputs(searchOptions);
                    return data;
                } catch (e) { }
            },
            select: true,
            columnDefs: [{
                orderable: false,
                className: 'th-checkbox text-center select-checkbox',
                targets: 0
            }],
            order: [[2, 'asc']],
            dom: 'Brtpli',
            pagingType: "full_numbers",
            buttons: [
                that.filterButton,
                // that.deleteButton,
            ]
        };
    }

    delete(): void {
        throw new Error("Method not implemented.");
    };
    returnError(error: any) {
        throw new Error("Method not implemented.");
    }
    rerender() {
        this.datatableElement.dtInstance.then((dtInstance: any) => {
            let table: DataTables.Api = dtInstance;
            $('.table thead tr:eq(1) th').each(function (i) {
                const that = this;
                $('.reset').unbind();
                $('.reset').bind('click', function () {
                    const input = $('input.input-search');
                    input.each(function () {
                        $(this).val('');
                    });
                    table.columns().search('').draw();
                });
                $('input', this).on('keyup change search', function () {
                    if (table.column(i).search() !== this['value']) {
                        table
                            .column(i)
                            .search(this['value'])
                            .draw();
                    }
                });
                $('.select-all').change(function () {
                    if (this['checked']) {
                        dtInstance.rows({ page: 'all' }).select();
                    }
                    else {
                        dtInstance.rows({ page: 'all' }).deselect();
                    }
                });
            });

        });
        this.selectAllButton();
    }
    selectAllButton() {
        this.datatableElement.dtInstance.then((dtInstance: any) => {
            let button = dtInstance.button(".delete");
            button.disable();
            dtInstance.on('select deselect', function () {
                let selectedRows = dtInstance.rows({ selected: true }).count();
                button.enable(selectedRows > 0);
                let selectAll = $('.select-all');
                if (selectedRows == 0) {
                    selectAll.prop("checked", false)
                }
            });
        });
    }
    updateDisplaySearchInputs(searchOptions: Array<SearchOption>) {
        if (!searchOptions.length) return;
        this.datatableElement.dtInstance.then((dtInstance: any) => {
            let searchInputs = $('.table thead tr:eq(1) th');
            searchOptions.map(val => {
                const search = searchInputs[val.index];
                let input = $('input', search);
                input.val(val.value);
            });
            let btnFilter: any = $('.btn-filter');
            btnFilter.attr("aria-expanded", "true");
            let th = $('.table thead tr:eq(1)');
            th.addClass('show');
        })
    }
    private hasDatatablesData(url: string): boolean{
        const nav = this.services.nav;
        if(url.indexOf('users/pointsAccounts') > -1 || url.indexOf('activity/activity') > -1){
            url = nav.getPreviousUrl(1);
            if(nav.isEditorByUrl(url))
                return true;
        }
        return false;
    }
}