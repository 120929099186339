import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/services/api.service';
import { MessageService } from 'src/services/apiServices/allServices/message.service';
import { NavigationService } from 'src/services/apiServices/allServices/navigation.service';
import { Category } from 'src/models/users/category';
import { EditorComponent } from 'src/models/editor';
import { TranslateService } from '@ngx-translate/core';
import { ServicesService } from 'src/services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-category-editor',
  templateUrl: './category-editor.component.html',
  styleUrls: ['./category-editor.component.scss']
})
export class CategoryEditorComponent extends EditorComponent<Category> {
  apiCall: any;
  constructor(formBuilder: FormBuilder,
              api: ApiService,
              services: ServicesService,
              private router: Router,
              route: ActivatedRoute,
              translate: TranslateService) 
  { 
    super(services, route, formBuilder, api, translate);
    this.item = new Category();
    this.form = this.formBuilder.group({
      "name" : new FormControl('', [Validators.required])
    });
    this.apiCall = api.user.category;
  }
  setValuesToObject(): void {
    this.item.name = this.form.value.name;
  }
  returnError(error: string) {
    if(error.indexOf(environment.serverError.alreadyExist) > -1){
      this.form.controls['name'].setErrors({exist: true});
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error);
  }
}
