import { Settings } from './../../../models/settings/settings';
import { Component } from '@angular/core';
import { EditorComponent } from 'src/models/editor';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent extends EditorComponent<Settings> {
  apiCall: any;
  ru: any;
  constructor(services: ServicesService,
              route: ActivatedRoute,
              formBuilder: FormBuilder,
              api: ApiService,
              translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.form = formBuilder.group({
      "ourProgram" : new FormControl('', [Validators.required]),
      "startProgram" : new FormControl('', [Validators.required]),
      "monthlyReport" : new FormControl('', [Validators.required, Validators.pattern(/^[1-9]$|^[12]\d$|^3[01]$/)]),
      "pointsForPhoto" : new FormControl('', [Validators.required]),
      "pointsForLocation" : new FormControl('', [Validators.required]),
      "minYear" : new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]),
      "orderProcessingTime" : new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(2)])

    });
  }

  ngOnInit() {
    this.ru = environment.ru;
    this.setValues();
  }

  setValues(): void {
    this.api.settings.getOne().subscribe(
      item => {
        this.item = item;
        this.form.patchValue({
          ...item,
          "startProgram": new Date(item.startProgram)
        });
        this.InProgress = false;
      },
      error => this.catchError(error)
    )
  }
  setValuesToObject(): void {
    Object.assign(this.item, this.form.value);
    const now = moment(this.form.value.startProgram);
    this.item.startProgram = now.format('YYYY-MM-DD HH:mm');
  }
  apiAdd(): void {
    this.apiUpdate();
  }
  apiUpdate(): void {
    this.api.settings.update(this.item).subscribe(
      res => {
        this.sendSuccessAlert('info.message.settings');
      },
      error => this.catchError(error)
    )
  }
  returnError(error: any) {
    throw new Error("Method not implemented.");
  }
}
