import { isNumber } from "util";

export class SelectUser {
    id: number;    
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    constructor();
    constructor(id: number);
    constructor(id: number, email: string);
    constructor(id?: number, email?: string){
        if(isNumber(id)) this.id = id;
        if(email) this.email = email;
    }

}