import { getColumnTitles } from 'src/utils/dt/columns';
import { ModelType } from 'src/models/model/modelType';
import { DatatablesComponent } from 'src/models/datatables';
import { Component, } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { TitleItem } from 'src/models/titleItem';

@Component({
  selector: 'app-model-types',
  templateUrl: './model-types.component.html',
  styleUrls: ['./model-types.component.scss']
})
export class ModelTypesComponent extends DatatablesComponent<ModelType> {
  columnNames: TitleItem[];
  apiCall: any;
  constructor(api: ApiService, 
              services: ServicesService) {
    super(api, services);
    this.apiCall = api.model.modelType;
    this.columnNames = getColumnTitles(['name.modelType']);
  }
}
