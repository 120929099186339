import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {IInstallerClientSMSService} from '../../interfaces/settingsService/IInstallerClientSMSService';
import {InstallerClientSMS} from '../../../models/settings/installerClientSMS';
import {BaseService} from '../base.service';
import {Base} from '../../../models/base';
import {DataTablesResponse} from '../../../models/dataTablesResponse';

@Injectable({
  providedIn: 'root'
})

export class InstallerClientSMSService implements IInstallerClientSMSService {

  api: string = environment.api;

  constructor(private http: HttpClient,
              private baseService: BaseService<InstallerClientSMS>) {
  }

  getAll(): Observable<InstallerClientSMS[]> {
    return this.baseService.getAll(`all-installer-client-sms`);
  }

  getOne(id: number): Observable<InstallerClientSMS> {
    return this.baseService.getOne(id, `installer-client-sms`);
  }

  update(item: InstallerClientSMS): Observable<InstallerClientSMS> {
    return this.baseService.update(item, `installer-client-sms`);
  }

  add(item: InstallerClientSMS): Observable<InstallerClientSMS> {
    return this.baseService.add(item, `installer-client-sms`);
  }

  delete(id: number) {
    return this.http.delete(`${this.api}/installer-client-sms`);
  }

  getAccessibleModels(id: number): Observable<Base[]> {
    return this.http.get<Base[]>(`${this.api}/installer-client-sms-models/${id}`);
  }
  getPage(dataTablesParameters: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(`${this.api}/admin-installer-client-sms`, JSON.stringify(dataTablesParameters));
  }
}
