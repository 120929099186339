export class SearchOption{
    value: string;
    index: number;
    constructor();
    constructor(index: number, value: string);
    constructor(index?: number, value?: string)
    {
        if(Number.isInteger(index)) this.index = index;
        if(value) this.value = value;
    }
}