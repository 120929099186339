import { SelectUser } from 'src/models/users/selectUser';
import { TradingPlaceType } from './tradingPlaceType';
import { City } from 'src/models/geography/city';
import { Base } from '../base';
import { Image } from './images';

export class TradingPlace extends Base{
    address?: string;
    tradingPlaceType?: TradingPlaceType;
    city?: City = new City();
    contactUser?: SelectUser;
    createdUser?: SelectUser;
    positionUser?: SelectUser;
    createdAt?: string;
    longitude?: string; //долгота
    latitude?: string;
    gpsTime?: string;
    photos?: number;
    images?: Image[];
    deleted: boolean;
}