import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ModelBrand } from "src/models/model/modelBrand";
import { IBaseService } from "src/services/interfaces/IBaseService";
import { BaseService } from "../base.service";

@Injectable({
    providedIn: 'root'
})

export class ModelBrandService implements IBaseService<ModelBrand> {
    constructor(private baseService: BaseService<ModelBrand>) {}
    getAll(): Observable<ModelBrand[]> {
        return this.baseService.getAll("modelbrands");
    }
    getOne(id: number): Observable<ModelBrand> {
        return this.baseService.getOne(id, "modelbrand");
    }
    add(item: ModelBrand): Observable<ModelBrand> {
        return this.baseService.add(item, "modelbrand");
    }
    update(item: ModelBrand) {
        return this.baseService.update(item, "modelbrand");
    }
}