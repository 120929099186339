import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Admin } from "src/models/users/admin";
import { IAdminService } from "src/services/interfaces/userSevice/IAdminService";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "../base.service";

@Injectable({
    providedIn: 'root'
})

export class AdminService implements IAdminService {
    api: string = environment.api;
    constructor(private http: HttpClient,
                private baseService: BaseService<Admin>)
    {}
    getAll(): Observable<Admin[]> {
        return this.baseService.getAll("admins");
    }
    getOne(id: number): Observable<Admin> {
        return this.baseService.getOne(id, "admin");
    }
    add(item: Admin): Observable<Admin> {
        return this.baseService.add(item, "admin");
    }
    update(item: Admin): Observable<Admin> {
        return this.baseService.update(item, "admin");
    }
    search(): Observable<Admin[]> {
        return this.http.get<Admin[]>(`${this.api}/search/admin`);
    }
}