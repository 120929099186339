// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'https://api.atlantic.yourserveradmin.com',
  language: {
    sProcessing: 'Подождите...',
    sLengthMenu: 'Показать _MENU_ записей',
    sZeroRecords: 'Записи отсутствуют.',
    sInfo: 'Записи с _START_ по _END_ из _TOTAL_',
    sInfoEmpty: 'Записи с 0 по 0 из 0',
    sInfoFiltered: '(отфильтровано из _MAX_ записей)',
    sInfoPostFix: '',
    sSearch: `<button class='btn btn-outline-secondary btn-sm' type='button' data-toggle='collapse' data-target='#filter' 
                    aria-expanded='false' aria-controls='filter'>
                        Фильтры
                    </button>`,
    sUrl: '',
    oPaginate: {
      sFirst: 'Первая',
      sPrevious: 'Предыдущая',
      sNext: 'Следующая',
      sLast: 'Последняя'
    },
    select: {
      rows: ''
    }
  },
  serverError: {
    wrongPassword: 'Wrong password',
    emailNotFound: 'Email could not be found',
    notAuthorizedRole: 'not authorized role',
    itemNotFound: 'wrong ',
    alreadyExist: 'уже существует',
    delete: 'User was deleted',
    exist: {
      email: '',
    }
  },
  defaultValue: {
    tradingPlaceType: 'Бытовая техника',
    category: 'Продавец',
    modelType: 'ЭВН'
  },
  translate: {
    yes: 'Да',
    no: 'Нет',
    active: 'Активный',
    noActive: 'Заблокирован'
  },
  ru: {
    firstDayOfWeek: 1,
    dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    today: 'Сегодня',
    clear: 'Очистить'
  }
};
