import { LogService } from './../allServices/log.service';
import { BaseService } from './../base.service';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { IPresentService } from "src/services/interfaces/presentService/IPresentService";
import { Present } from "src/models/presents/present";
import { DataTablesResponse } from "src/models/dataTablesResponse";
import { IOrderPresentService } from "src/services/interfaces/presentService/IOrderPresentService";
import { OrderPresentService } from "./orderPresent.service";

@Injectable({
    providedIn: 'root'
})

export class PresentService implements IPresentService {
    api: string = environment.api;
    public order: IOrderPresentService;
    constructor(private http: HttpClient,
                private baseService: BaseService<Present>,
                private log: LogService,
                order: OrderPresentService)
    {
        this.order = order;
    }
    getAll(): Observable<Present[]> {
        return this.baseService.getAll("presents");
    }
    getPage(dataTablesParameters: any): Observable<DataTablesResponse> {
        return this.http.post<DataTablesResponse>(`${this.api}/adminpresents`, JSON.stringify(dataTablesParameters));
    }
    getOne(id: number): Observable<Present> {
        return this.baseService.getOne(id, "adminpresent");
    }
    add(item: Present): Observable<Present> {
        return this.baseService.add(item, "adminpresent");
    }
    update(item: Present) {
        return this.baseService.update(item, "adminpresent");
    }
    logXls(dataTablesParameters: any): Observable<any> {
        return this.log.postXls(dataTablesParameters, "presentsXls");
    }
    logXml(dataTablesParameters: any): Observable<any> {
        return this.log.postXml(dataTablesParameters, "presentsXml");
    }
}