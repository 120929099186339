import { OnDestroy } from '@angular/core';
import { Component, OnInit, Input, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit, OnDestroy {
  
  @Input() name: string;
  @Input() parentForm: FormGroup;
  @Input() onSubmit: EventEmitter<any>;
  @ViewChild('ngForm') _form: NgForm;
  @Input() isRequired: boolean = true;
  @Input() hasLabel: boolean = true;
  @Input() autocomplete: boolean = false;
  isShow: boolean = false;
  constructor() { 
  }

  ngOnInit() {
    this.onSubmit.subscribe(_ => {
      this._form.ngSubmit.emit();
    });
  }
  ngOnDestroy(): void {
    this.onSubmit.unsubscribe();
  }

  showOrHideField(){
    this.isShow = !this.isShow;
  }


}
