import { SelectItem } from './../../../models/form/selectItem';
import { PromoModel } from './../../../models/activity/promoModel';
import { forkJoin } from 'rxjs';
import { SelectManyList } from './../../../models/form/selectManyList';
import { Activity } from 'src/models/activity/activity';
import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { EditorComponent } from 'src/models/editor';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { PromoModelList } from 'src/models/activity/promoModelList';
import { Base } from 'src/models/base';

@Component({
  selector: 'app-activity-editor',
  templateUrl: './activity-editor.component.html',
  styleUrls: ['./activity-editor.component.scss']
})
export class ActivityEditorComponent extends EditorComponent<Activity> implements OnDestroy {
  apiCall: any;
  activity: number[] = [];
  ru: any;
  minDateFrom: Date = new Date();
  minDateTo: Date = new Date();
  year: number;
  promoModelList: PromoModelList;
  private priority: number;
  set Priority(value: number) {
    const preValue = this.priority;
    this.priority = value;
    this.onChangePriority.emit(preValue);
  }
  get Priority() {
    return this.priority;
  }
  selectManyList: SelectManyList;
  onChangePriority: EventEmitter<number> = new EventEmitter();
  eventChangePriority: EventEmitter<number> = new EventEmitter();
  constructor(services: ServicesService,
    route: ActivatedRoute,
    formBuilder: FormBuilder,
    api: ApiService,
    translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.item = new Activity();
    this.promoModelList = new PromoModelList(this.id);
    this.item.promoModels = [];
    this.form = this.formBuilder.group({
      'name': new FormControl('', [Validators.required]),
      'priority': new FormControl('', [Validators.required]),
      'dateFrom': new FormControl('', [Validators.required]),
      'dateTo': new FormControl('', [Validators.required]),
      'promoCategory': new FormControl('', [Validators.required]),
      'promoRegions': new FormControl('', [Validators.required]),
      'promoModels': new FormControl('', [Validators.required])
    });
    this.apiCall = api.activity;
    this.priority = -1;
    this.selectManyList = new SelectManyList(['promoCategory', 'promoRegions'], this.form);
    this.onChangePriority.subscribe(value => this.changePriority(value));
  }
  ngOnInit() {
    this.setValues();
  }
  ngOnDestroy(): void {
    this.onChangePriority.unsubscribe();
  }
  setValues() {
    this.ru = environment.ru;
    for (let i = 0; i < 10; i++) {
      this.activity.push(i);
    }
    let observable = null;
    if (this.id) {
      observable = forkJoin(
        this.api.activity.getYears(),
        this.api.user.category.getAll(),
        this.api.geography.region.getAll(),
        this.api.activity.getOne(this.id)
      );
    } else {
      observable = forkJoin(
        this.api.activity.getYears(),
        this.api.user.category.getAll(),
        this.api.geography.region.getAll()
      );
    }
    observable.subscribe(
      res => {
        this.year = ++res[0].year;
        this.selectManyList.setItems('promoCategory', res[1]);
        this.selectManyList.setItems('promoRegions', res[2]);
        this.InProgress = false;
        if (this.id) {
          const item = res[3];
          const promoCategory = item.promoCategory as Base[];
          const promoRegions = item.promoRegions as Base[];
          this.item = item;
          this.form.patchValue({
            ...item,
            'promoCategory': promoCategory.map(category => category.id),
            'promoRegions': promoRegions.map(promoRegion => promoRegion.id)
          });
          this.Priority = item.priority;
          this.promoModelList.SubmitModels = item.promoModels;
          if (item.priority !== 0) {
            promoCategory.map(category => this.selectManyList.selected(category, 'promoCategory'));
            promoRegions.map(region => this.selectManyList.selected(region, 'promoRegions'));
            this.activity.shift();
          } else {
            this.form.controls['priority'].disable();
          }
        } else {
          this.Priority = 0;
        }
      },
      e => this.catchError(e)
    );
  };
  setValuesToObject(): void {
    this.promoModelList.isValidation();
    if (!this.promoModelList.IsValid) {
      this.form.controls['promoModels'].setErrors({ required: true });
      return;
    } else {
      this.form.controls['promoModels'].setErrors(null);
    }

    const { promoCategory, promoRegions, promoModels, ...valuesToSubmit } = this.form.value;
    this.item.promoCategory = this.selectManyList.getSelect('promoCategory').getActivesToSubmit();
    this.item.promoRegions = this.selectManyList.getSelect('promoRegions').getActivesToSubmit();
    this.item.promoModels = this.promoModelList.getActivesToSubmit();
    Object.assign(this.item, valuesToSubmit);
    this.item.dateFrom = moment(this.item.dateFrom).format('YYYY-MM-DD');
    this.item.dateTo = moment(this.item.dateTo).format('YYYY-MM-DD');
    this.item.priority = this.priority;

  }
  returnError(error: any) {
    if (error.name) {
      this.form.controls['name'].setErrors({ exist: true });
      this._form.ngSubmit.emit();
      return;
    }
    if (error.exist) {
      this.form.controls['priority'].setErrors({ exist: true });
      this.form.controls['dateFrom'].setErrors({ exist: true });
      this.form.controls['dateTo'].setErrors({ exist: true });
      this.sendAlert(this.message.messageColor.error, 'info.message.activity', this.message.messagePosition.float, true);
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error);
  }
  onChange(value: any) {
    const priority = parseInt(value, 10);
    this.Priority = priority;
  }
  onSelectDateFrom(date: Date) {
    const values = this.form.value;
    const dateTo = moment(values.dateTo).toDate();
    if (values.dateTo) {
      if (date > dateTo) {
        this.form.controls['dateTo'].setValue(date);
      }
    }
    this.minDateTo = date;
  }

  changePriority(value: number) {
    const dateFrom = this.form.controls['dateFrom'];
    const dateTo = this.form.controls['dateTo'];
    const selectPromoCategory = this.selectManyList.getSelect('promoCategory');
    const selectPromoRegions = this.selectManyList.getSelect('promoRegions');

    this.form.controls['priority'].setValue(this.priority);

    if (this.priority === 0) {
      if (!this.id) {
        this.item.dateFrom = `${this.year}-01-01 00:00`;
        this.item.dateTo = `${this.year}-12-31 00:00`;
      }
      dateFrom.setValue(new Date(this.item.dateFrom));
      dateFrom.disable();
      dateTo.setValue(new Date(this.item.dateTo));
      dateTo.disable();
      selectPromoCategory.selected(-1);
      const promoCategoryIds = selectPromoCategory.Actives.map(category => category.id);
      this.form.controls['promoCategory'].setValue(promoCategoryIds);
      selectPromoCategory.IsDisabled = true;
      selectPromoRegions.selected(-1);
      const promoRegionIds = selectPromoRegions.Actives.map(promoRegion => promoRegion.id);
      this.form.controls['promoRegions'].setValue(promoRegionIds);
      selectPromoRegions.IsDisabled = true;
      this.promoModelList.allEnable();
    } else if (value === 0) {
      dateFrom.setValue('');
      dateFrom.enable();
      dateTo.setValue('');
      dateTo.enable();
      selectPromoCategory.selected(-2);
      this.form.controls['promoCategory'].setValue([]);
      selectPromoCategory.IsDisabled = false;
      selectPromoRegions.selected(-2);
      this.form.controls['promoRegions'].setValue([]);
      selectPromoRegions.IsDisabled = false;
      this.promoModelList.allDisable();
    }
    this.eventChangePriority.emit(this.priority);
  }
  removed(value: any, name: string) {
    this.selectManyList.removed(value.value, name);
  }
  selected(value: any, name: string) {
    this.selectManyList.selected(value, name);
  }
}
