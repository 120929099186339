import { Present } from './../../../models/presents/present';
import { Component } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { DatatablesServerComponent } from 'src/models/datatablesServer';
import { TitleItem } from 'src/models/titleItem';
import { getColumnTitles, getColumnData } from 'src/utils/dt/columns';

@Component({
  selector: 'app-presents',
  templateUrl: './presents.component.html',
  styleUrls: ['./presents.component.scss']
})
export class PresentsComponent extends DatatablesServerComponent<Present> {
  
  columnsData: any[];
  columnNames: TitleItem[];
  apiCall: any;
  filename: string = "Подарки";
  
  constructor(api: ApiService, 
              services: ServicesService) {
    super(api, services);
    this.setInitValues();
  }

  setInitValues(): void {
    this.apiCall = this.api.present;
    this.apiLog = this.api.present;
    this.columnNames = getColumnTitles([
      'name.present', 'article', 'deliveryRequired', 'cost', 'enabled'
    ]);
    this.columnsData = [
      ['name', this.urlReturn], 'article', ['isDeliveryRequired', this.dtType.yesOrNo], 
      'points', ['enabled', this.dtType.enabled]
    ];
  }
}
