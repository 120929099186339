import { MessageDisplay } from './../enum/message/display';
import { MessagePosition } from "src/enum/message/position";
import { MessageColor } from "src/enum/message/color";
import { MessageType } from "src/enum/message/type";
import { MessageEventType } from "src/enum/message/eventType"

export class Message{
    type: MessageType;
    color: MessageColor;
    position: MessagePosition;
    display: MessageDisplay;
    event: MessageEventType;
    messageType = MessageType;
    messageColor = MessageColor;
    messagePosition = MessagePosition;
    messageDisplay = MessageDisplay;
    messageEvent = MessageEventType;
    text: string;
    constructor(){
        this.type = this.messageType.alert;
        this.color = this.messageColor.success;
        this.position = this.messagePosition.fix;
        this.display = this.messageDisplay.hide;
        this.event = this.messageEvent.none;
    }

    alertSuccessFloat(text: string, event?: MessageEventType){
        this.text = text;
        this.type = this.messageType.alert;
        this.color = this.messageColor.success;
        this.position = this.messagePosition.float;
        this.display = this.messageDisplay.hide;
        this.event = event ? event : this.messageEvent.none;
    }
}