import { AuthComponent } from 'src/models/auth';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Message } from 'src/models/message';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends AuthComponent {
  
  
  form: FormGroup;
  message: Message;
  constructor(formBuilder: FormBuilder, 
              api: ApiService, 
              services: ServicesService) { 
    super(formBuilder, api, services);
    this.form = this.formBuilder.group({
      "emailAdmin": new FormControl('', [Validators.required, Validators.email])
    });
    this.message = new Message();
  }

  ngOnInit() {
  }

  catchError(res: any) {
    const errors = res.error.errors;
    if(errors && errors.credentials){
      this.form.controls['emailAdmin'].setErrors({ noExist: true});
      this._form.ngSubmit.emit();
    }
  }
  submitValue() {
    const values = this.form.value;
    this.api.auth.forgotPassword(values.emailAdmin).subscribe(
      res => this.returnResult(res),
      error => this.catchError(error)
    )
    
  }
  returnResult(res: string) {
    // if(error.indexOf(environment.serverError.alreadyExist) > -1)
    //   return this.form.controls['emailAdmin'].setErrors({ exists: true});
    this.message.alertSuccessFloat('info.message.forgotPassword');
    this.services.message.sendAlertMessageText(this.message);
    this.services.nav.redirectTo('verificationСode');

  }
  
  back(){
    this.services.nav.redirectToLogin();
  }

}
