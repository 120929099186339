import { TradingPlaceType } from 'src/models/tradingPlace/tradingPlaceType';
import { EditorComponent } from 'src/models/editor';
import { Component } from '@angular/core';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-trading-place-type-editor',
  templateUrl: './trading-place-type-editor.component.html',
  styleUrls: ['./trading-place-type-editor.component.scss']
})
export class TradingPlaceTypeEditorComponent extends EditorComponent<TradingPlaceType> {
  apiCall: any;
  constructor(services: ServicesService, 
              route: ActivatedRoute, 
              formBuilder: FormBuilder, 
              api: ApiService, 
              translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.item = new TradingPlaceType();
    this.form = this.formBuilder.group({
      "name" : new FormControl('', [Validators.required])
    });
    this.apiCall = api.tradingPlace.type;
  }
  setValuesToObject(): void {
    Object.assign(this.item, this.form.value);
  }
  returnError(error: any) {
    if(error.name){
      this.form.controls['name'].setErrors({exist: true});
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error); 
  }
}
