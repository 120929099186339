import { TradingPlace } from './../../../models/tradingPlace/tradingPlace';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { DatatablesServerComponent } from 'src/models/datatablesServer';
import { IExportService } from 'src/services/interfaces/IExportService';
import { TitleItem } from 'src/models/titleItem';
import { getColumnTitles, getColumnData } from 'src/utils/dt/columns';

@Component({
  selector: 'app-trading-places',
  templateUrl: './trading-places.component.html',
  styleUrls: ['./trading-places.component.scss']
})
export class TradingPlacesComponent extends DatatablesServerComponent<TradingPlace> {
  columnsData: any[];
  columnNames: TitleItem[];
  apiCall: any;
  apiLog: IExportService;
  filename: string = "Торговая точка";
  constructor(api: ApiService, 
              services: ServicesService) {
    super(api, services);
    this.setInitValues();
  }

  setInitValues(): void {
    this.apiCall = this.api.tradingPlace;
    this.apiLog = this.api.tradingPlace;
    this.pageLength = 5;
    this.columnNames = getColumnTitles([
      'name.name', 'address', 'city', 'region', 'country', 'tradingPlaceType',
      'contactUser', ['createdAt', 'input-calendar'], 'createdUser', 'longitude', 'latitude',
      ['gpsTime', 'input-calendar'], 'positionUser', 'photos', 'enabled'
    ]);
    const dtType = this.dtType;
    this.columnsData = [ 
      ['name', this.urlReturn], 'address', 'city.name', 'city.region.name', 'city.region.country.name', 
      'tradingPlaceType.name', 'contactUser.email', ['createdAt', dtType.date], 'createdUser.email', 
      'longitude', 'latitude',['gpsTime', dtType.date], 'positionUser.email', 'photos', 
      ['deleted', dtType.delete]
    ];
  }
}
