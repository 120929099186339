import { CppdComponent } from './../pages/settings/cppd/cppd.component';
import { ActivityEditorComponent } from 'src/pages/activity/activity-editor/activity-editor.component';
import { AdminsInfoComponent } from 'src/pages/settings/admins-info/admins-info.component';
import { PresentEditorComponent } from 'src/pages/presents/present-editor/present-editor.component';
import { TradingPlaceTypesComponent } from 'src/pages/trading-places/trading-place-types/trading-place-types.component';
import { TradingPlaceTypeEditorComponent } from 'src/pages/trading-places/trading-place-type-editor/trading-place-type-editor.component';
import { ModelTypesComponent } from 'src/pages/models/model-types/model-types.component';
import { CityEditorComponent } from 'src/pages/geography/city-editor/city-editor.component';
import { CitiesComponent } from './../pages/geography/cities/cities.component';
import { AuthGuard } from './../models/auth/authGuard';
import { CountryEditorComponent } from 'src/pages/geography/country-editor/country-editor.component';
import { AdminEditorComponent } from 'src/pages/users/admin-editor/admin-editor.component';
import {Routes} from '@angular/router';
import { UsersComponent } from 'src/pages/users/users/users.component';
import { CategoriesComponent } from 'src/pages/users/categories/categories.component';
import { UserEditorComponent } from 'src/pages/users/user-editor/user-editor.component';
import { CategoryEditorComponent } from "src/pages/users/category-editor/category-editor.component";
import { BasePageComponent } from 'src/pages/base/base-page/base-page.component';
import { BaseEditorPageComponent } from 'src/pages/base/base-editor-page/base-editor-page.component';
import { ForgotPasswordComponent } from 'src/pages/auth/forgot-password/forgot-password.component';
import { AdminsComponent } from 'src/pages/users/admins/admins.component';
import { CountriesComponent } from 'src/pages/geography/countries/countries.component';
import { LogoutComponent } from 'src/pages/auth/logout/logout.component';
import { RegionsComponent } from 'src/pages/geography/regions/regions.component';
import { RegionEditorComponent } from 'src/pages/geography/region-editor/region-editor.component';
import { ModelTypeEditorComponent } from 'src/pages/models/model-type-editor/model-type-editor.component';
import { TradingPlacesComponent } from 'src/pages/trading-places/trading-places/trading-places.component';
import { TradingPlaceEditorComponent } from 'src/pages/trading-places/trading-place-editor/trading-place-editor.component';
import { LoginComponent } from 'src/pages/auth/login/login.component';
import { BaseAuthComponent } from 'src/pages/base/base-auth/base-auth.component';
import { VerificationCodeComponent } from 'src/pages/auth/verification-code/verification-code.component';
import { ConfirmPasswordComponent } from 'src/pages/auth/confirm-password/confirm-password.component';
import { PresentsComponent } from 'src/pages/presents/presents/presents.component';
import { PointsAccountsComponent } from 'src/pages/users/points-accounts/points-accounts.component';
import { PointsAccountEditorComponent } from 'src/pages/users/points-account-editor/points-account-editor.component';
import { SettingsComponent } from 'src/pages/settings/settings/settings.component';
import { AdminInfoEditorComponent } from 'src/pages/settings/admin-info-editor/admin-info-editor.component';
import { ModelBrandsComponent } from 'src/pages/models/model-brands/model-brands.component';
import { ModelBrandEditorComponent } from 'src/pages/models/model-brand-editor/model-brand-editor.component';
import { ModelsComponent } from 'src/pages/models/models/models.component';
import { ModelEditorComponent } from 'src/pages/models/model-editor/model-editor.component';
import { OrderPresentsComponent } from 'src/pages/presents/order-presents/order-presents.component';
import { OrderPresentEditorComponent } from 'src/pages/presents/order-present-editor/order-present-editor.component';
import { ActivityComponent } from 'src/pages/activity/activity/activity.component';
import { SaleEditorComponent } from 'src/pages/sales/sale-editor/sale-editor.component';
import { SalesComponent } from 'src/pages/sales/sales/sales.component';
import { RulesOfParticipationComponent } from 'src/pages/settings/rules-of-participation/rules-of-participation.component';
import { InstructionComponent } from 'src/pages/settings/instruction/instruction.component';
import { GdprComponent } from 'src/pages/settings/gdpr/gdpr.component';
import {SupervisorsComponent} from '../pages/users/supervisors/supervisors.component';
import {SupervisorEditComponent} from '../pages/users/supervisor-edit/supervisor-edit.component';
import {CommissionCppdComponent} from '../pages/settings/commission-cppd/commission-cppd.component';
import {InstallerClientSmsEditorComponent} from '../pages/settings/installer-client-sms-editor/installer-client-sms-editor.component';
import {InstallerClientsSmsComponent} from '../pages/settings/installer-clients-sms/installer-clients-sms.component';
import {PointValueComponent} from '../pages/settings/point-value/pointValue.component';

export function createBaseEditorPage(path: string, component: any){
    return {
        path, component: BaseEditorPageComponent, children: [
            { path: "", component, outlet: "editor"}
        ]
    }
}
export function createBasePage(path: string, component: any){
    return {
        path, component: BasePageComponent, children: [
            { path: "", component, outlet: "page"}
        ]
    }
}

const usersRoutes: Routes = [
    //users
    { path: "users", component: BasePageComponent, children: [
        { path: "", component: UsersComponent, outlet: "page" }
    ] },
    { path: "users/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: UserEditorComponent, outlet: "editor"}
    ]},
    { path: "users/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: UserEditorComponent, outlet: "editor"}
    ]},
    //Categories
    { path: "categories", component: BasePageComponent, children: [
        { path: "", component: CategoriesComponent, outlet: "page" }
    ] },
    { path: "categories/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: CategoryEditorComponent, outlet: "editor"}
    ]},
    { path: "categories/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: CategoryEditorComponent, outlet: "editor"}
    ]},
    // Admins
    { path: "admins", component: BasePageComponent, children: [
        { path: "", component: AdminsComponent, outlet: "page" }
    ] },
    { path: "admins/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: AdminEditorComponent, outlet: "editor"}
    ]},
    { path: "admins/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: AdminEditorComponent, outlet: "editor"}
    ]},
    // pointsAccounts
    { path: "pointsAccounts", component: BasePageComponent, children: [
        { path: "", component: PointsAccountsComponent, outlet: "page" }
    ] },
    { path: "pointsAccounts/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: PointsAccountEditorComponent, outlet: "editor"}
    ]},
    // supervisors
    { path: "supervisors", component: BasePageComponent, children: [
        { path: "", component: SupervisorsComponent, outlet: "page"}
    ]},
    { path: "supervisors/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: SupervisorEditComponent, outlet: "editor"}
    ]},
    { path: "supervisors/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: SupervisorEditComponent, outlet: "editor"}
    ]}
];

const geographyRouter: Routes = [
    //countries
    { path: "countries", component: BasePageComponent, children: [
        { path: "", component: CountriesComponent, outlet: "page" }
    ]},
    { path: "countries/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: CountryEditorComponent, outlet: "editor"}
    ]},
    { path: "countries/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: CountryEditorComponent, outlet: "editor"}
    ]},
    //regions
    { path: "regions", component: BasePageComponent, children: [
        { path: "", component: RegionsComponent, outlet: "page" }
    ]},
    { path: "regions/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: RegionEditorComponent, outlet: "editor"}
    ]},
    { path: "regions/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: RegionEditorComponent, outlet: "editor"}
    ]},
    //cities
    { path: "cities", component: BasePageComponent, children: [
        { path: "", component: CitiesComponent, outlet: "page" }
    ]},
    { path: "cities/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: CityEditorComponent, outlet: "editor"}
    ]},
    { path: "cities/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: CityEditorComponent, outlet: "editor"}
    ]},
]

const modelRouter: Routes = [
    //modelTypes
    { path: "modelTypes", component: BasePageComponent, children: [
        { path: "", component: ModelTypesComponent, outlet: "page" }
    ]},
    { path: "modelTypes/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: ModelTypeEditorComponent, outlet: "editor"}
    ]},
    { path: "modelTypes/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: ModelTypeEditorComponent, outlet: "editor"}
    ]},
    //modelbrands
    { path: "modelBrands", component: BasePageComponent, children: [
        { path: "", component: ModelBrandsComponent, outlet: "page" }
    ]},
    { path: "modelBrands/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: ModelBrandEditorComponent, outlet: "editor"}
    ]},
    { path: "modelBrands/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: ModelBrandEditorComponent, outlet: "editor"}
    ]},
    //models
    { path: "models", component: BasePageComponent, children: [
        { path: "", component: ModelsComponent, outlet: "page" }
    ]},
    { path: "models/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: ModelEditorComponent, outlet: "editor"}
    ]},
    { path: "models/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: ModelEditorComponent, outlet: "editor"}
    ]},
]
const tradingPlaceRouter: Routes = [
    //tradingPlaceTypes
    { path: "tradingPlaceTypes", component: BasePageComponent, children: [
        { path: "", component: TradingPlaceTypesComponent, outlet: "page" }
    ]},
    { path: "tradingPlaceTypes/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: TradingPlaceTypeEditorComponent, outlet: "editor"}
    ]},
    { path: "tradingPlaceTypes/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: TradingPlaceTypeEditorComponent, outlet: "editor"}
    ]},
    //tradingPlace
    { path: "tradingPlaces", component: BasePageComponent, children: [
        { path: "", component: TradingPlacesComponent, outlet: "page" }
    ]},
    { path: "tradingPlaces/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: TradingPlaceEditorComponent, outlet: "editor"}
    ]},
    { path: "tradingPlaces/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: TradingPlaceEditorComponent, outlet: "editor"}
    ]},
]

const presentRouter: Routes = [
    //presents
    { path: "presents", component: BasePageComponent, children: [
        { path: "", component: PresentsComponent, outlet: "page" }
    ]},
    { path: "presents/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: PresentEditorComponent, outlet: "editor"}
    ]},
    { path: "presents/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: PresentEditorComponent, outlet: "editor"}
    ]},
    //orderPresents
    { path: "orderPresents", component: BasePageComponent, children: [
        { path: "", component: OrderPresentsComponent, outlet: "page" }
    ]},
    { path: "orderPresents/editor/:id", component: BaseEditorPageComponent, children: [
        { path: "", component: OrderPresentEditorComponent, outlet: "editor"}
    ]},
    { path: "orderPresents/editor", component: BaseEditorPageComponent, children: [
        { path: "", component: OrderPresentEditorComponent, outlet: "editor"}
    ]},
]

const settingsRouter: Routes = [
    createBaseEditorPage("settings", SettingsComponent),
    createBasePage("adminsInfo", AdminsInfoComponent),
    createBaseEditorPage("adminsInfo/editor/:id", AdminInfoEditorComponent),
    createBaseEditorPage("rulesOfParticipation", RulesOfParticipationComponent),
    createBaseEditorPage("instruction", InstructionComponent),
    createBaseEditorPage("gdpr", GdprComponent),
    createBaseEditorPage("cppd", CppdComponent),
    createBaseEditorPage("commission-cppd", CommissionCppdComponent),
    createBaseEditorPage("installer-clients-sms/editor/:id", InstallerClientSmsEditorComponent),
    createBaseEditorPage("installer-clients-sms/editor", InstallerClientSmsEditorComponent),
    createBasePage("installer-clients-sms", InstallerClientsSmsComponent),
    createBasePage('point-value', PointValueComponent)
]

const activityRouter: Routes = [
    createBasePage("activity", ActivityComponent),
    createBaseEditorPage("activity/editor/:id", ActivityEditorComponent),
    createBaseEditorPage("activity/editor", ActivityEditorComponent)
]

const salesRouter: Routes = [
    createBasePage("sales", SalesComponent),
    createBaseEditorPage("sales/editor/:id", SaleEditorComponent),
    createBaseEditorPage("sales/editor", ActivityEditorComponent)
]

export const appRoutes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'login'},
    // { path: "login", component: BaseAuthComponent, children: [
    //     { path: "", component: LoginComponent, outlet: "auth" }
    // ]},
    { path: "login", component: LoginComponent },
    { path: "logout", component: LogoutComponent },
    { path: "forgotPassword", component: BaseAuthComponent, children: [
        { path: "", component: ForgotPasswordComponent, outlet: "auth" }
    ]},
    { path: "verificationСode", component: BaseAuthComponent, children: [
        { path: "", component: VerificationCodeComponent, outlet: "auth" }
    ]},
    { path: "confirmPassword", component: BaseAuthComponent, children: [
        { path: "", component: ConfirmPasswordComponent, outlet: "auth" }
    ]},
    { path: "geography", children: geographyRouter, canActivate: [AuthGuard]},
    { path: "models", children: modelRouter, canActivate: [AuthGuard]},
    { path: "tradingPlaces", children: tradingPlaceRouter, canActivate: [AuthGuard]},
    { path: "users", children: usersRoutes, canActivate: [AuthGuard]},
    { path: "presents", children: presentRouter, canActivate: [AuthGuard]},
    { path: "settings", children: settingsRouter, canActivate: [AuthGuard]},
    { path: "activity", children: activityRouter, canActivate: [AuthGuard]},
    { path: "sales", children: salesRouter, canActivate: [AuthGuard]}

]
