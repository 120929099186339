import { CurrentUser } from 'src/models/users/currentUser';
import { Injectable, EventEmitter } from '@angular/core';
import { NavItem } from 'src/models/navItem';
import { Location } from '@angular/common';
import { Router, GuardsCheckStart, NavigationEnd } from '@angular/router';
import { INavigationService } from 'src/services/interfaces/allServices/INavigationService';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService implements INavigationService {
  private history = [];
  private menu: Array<NavItem>;
  getAllMenu(isSuperAdmin: boolean){
    let tempMenu: NavItem[] = JSON.parse(JSON.stringify(this.menu));
    if(!isSuperAdmin)
      tempMenu = tempMenu.filter(item => {
        if(item.isSuperAdmin == false){
          item.subMenu = item.subMenu.filter(subItem => subItem.isSuperAdmin === false);
          return item;
        }
      });
    return tempMenu;
  }
  getMenu(){
    let path = this.location.path().split('/');
    let menu = path[1];
    return this.find(menu);
  }
  getSubmenu(){
    let path = this.location.path().split('/');
    let menu = path[1];
    let subMenu = path[2];
    return this.find(menu, menu+ "/" +subMenu);
  }
  get PathMenu(){
    let path = this.location.path().split('/');
    return path[1];
  }
  private navItem: NavItem;
  private title: string;
  set Title(value: string){
    this.title = value;
  }
  get Title(){
    return this.title;
  }
  get IsEditor(){
    let path = this.location.path().split('/');
    return path[3] ? true : false;
  }
  get IsEditorHasId(){
    let path = this.location.path().split('/');
    return path[4] ? true : false;
  }
  itemNotFound: EventEmitter<boolean> = new EventEmitter();
  breadCrumbs: EventEmitter<string> = new EventEmitter();
  constructor(private location: Location,
              private router: Router)
  {
    this.setData();
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.breadCrumbs.emit(urlAfterRedirects);
      });
  }

  private setData(){
    this.menu = new Array<NavItem>();

    this.navItem = new NavItem("menu.geography", "geography", "geography", false, "globe-europe");
    this.navItem.subMenu.push(
      new NavItem("menu.countries", "countries", "geography/countries", false),
      new NavItem("menu.regions", "regions", "geography/regions", false),
      new NavItem("menu.cities", "cities", "geography/cities", false)
    );
    this.menu.push(this.navItem);

    this.navItem = new NavItem("menu.tradingPlaces", "tradingPlaces", "tradingPlaces", false, "shopping-cart");
    this.navItem.subMenu.push(
    new NavItem("menu.tradingPlaces", "tradingPlaces", "tradingPlaces/tradingPlaces", false),
    new NavItem("menu.tradingPlaceTypes", "tradingPlaceTypes", "tradingPlaces/tradingPlaceTypes", false)
    );
    this.menu.push(this.navItem);

    this.navItem = new NavItem("menu.models", "models", "models", false, "building");
    this.navItem.subMenu.push(
     new NavItem("menu.models", "models", "models/models", false),
     new NavItem("menu.modelTypes", "modelTypes", "models/modelTypes", false),
     new NavItem("menu.modelBrands", "modelBrands", "models/modelBrands", false),
    );
    this.menu.push(this.navItem);

    this.navItem = new NavItem("menu.activity", "activity", "activity", false, "calendar-alt");
    this.navItem.subMenu.push(
      new NavItem("menu.activity", "activity", "activity/activity", false),
    );
    this.menu.push(this.navItem);

    this.navItem = new NavItem("menu.sales", "sales", "sales", false, "balance-scale");
    this.navItem.subMenu.push(
      new NavItem("menu.sales", "sales", "sales/sales", false)
    );
    this.menu.push(this.navItem);

    this.navItem = new NavItem("menu.presents", "presents", "presents", false, "gift");
    this.navItem.subMenu.push(
      new NavItem("menu.presents", "presents", "presents/presents", false),
      new NavItem("menu.orderPresents", "orderPresents", "presents/orderPresents", false),
    );
    this.menu.push(this.navItem);

    this.navItem = new NavItem("menu.users", "users", "users", false, "users");
    this.navItem.subMenu.push(
      new NavItem("menu.users", "users", "users/users", false),
      new NavItem("menu.categories","categories", "users/categories", false),
      new NavItem("menu.admins","admins", "users/admins", true),
      new NavItem("menu.pointsAccounts","pointsAccounts", "users/pointsAccounts", false),
      new NavItem("menu.supervisors","supervisors", "users/supervisors", false)
    );
    this.menu.push(this.navItem);

    this.navItem = new NavItem("menu.settings", "settings", "settings", false, "cog");
    this.navItem.subMenu.push(
      new NavItem("menu.settings", "settings", "settings/settings", false),
      new NavItem("menu.adminsInfo", "adminsInfo", "settings/adminsInfo", false),
      new NavItem("menu.rulesOfParticipation", "rulesOfParticipation", "settings/rulesOfParticipation", true),
      new NavItem("menu.instruction", "instruction", "settings/instruction", true),
      new NavItem("menu.cppd", "cppd", "settings/cppd", true),
      new NavItem("menu.gdpr", "gdpr", "settings/gdpr", true),
      new NavItem("menu.commissionCppd", "commissionCppd", "settings/commission-cppd", true),
      new NavItem("menu.installerClientSMS", "installerClientSMS", "settings/installer-clients-sms", true),
      new NavItem('menu.pointValue', 'pointValue', 'settings/point-value', true)
    );
    this.menu.push(this.navItem);
  }
  private find(urlMenu: string, urlSubMenu?: string): NavItem{
    let item: NavItem = null;
    item = this.menu.find(item => item.url === urlMenu);
    if(urlSubMenu)
      item = item.subMenu.find(item => item.url === urlSubMenu);
    this.title = item.name;
    return item;
  }
  isAccessAllowedByUrl(url: string, currentUser: CurrentUser){
    this.breadCrumbs.emit();
    let item :NavItem;
    let path = url.split('/');
    let menu = path[1];
    item = this.find(menu);
    if(currentUser.isSuperAdmin) return true;
    if(item.isSuperAdmin === currentUser.isSuperAdmin){
      let subMenu = path[2];
      item = this.find(menu, menu+ "/" +subMenu);
      if(item.isSuperAdmin === currentUser.isSuperAdmin){
        return true;
      }
    }
    return false;

  }
  redirectTo(url: string){
    this.router.navigateByUrl(url);

  }
  redirectToLogin(){
    this.redirectTo('/login');
  }
  goBack(){
    this.location.back();
  }

  loadRouting(){
    this.router.events
      .pipe(filter(event => event instanceof GuardsCheckStart))
      .subscribe(({urlAfterRedirects}: GuardsCheckStart) => {
        this.history = [...this.history, urlAfterRedirects];
      });
  }
  getHistory(): string[]{
    return this.history;
  }
  getPreviousUrl(stepBack: number = 2): string {
    return this.history[this.history.length - stepBack] || '/users/users'
  }

  setItemNotFound(isShow: boolean){
    this.itemNotFound.emit(isShow);
  }
  getItemNotFound(){
    return this.itemNotFound;
  }
  getBreadCrumbs(){
    return this.breadCrumbs;
  }
  isEditorByUrl(url: string): boolean {
    let path = url.split('/');
    return path[3] ? true : false;
  }
  getId(url: string): number {
    let path = url.split('/');
    return parseInt(path[4]);
  }
}
