import { getColumnTitles } from 'src/utils/dt/columns';
import { Component } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { User } from 'src/models/users/user';
import { ServicesService } from 'src/services/services.service';
import { DatatablesServerComponent } from 'src/models/datatablesServer';
import { TitleItem } from 'src/models/titleItem';
import { TitleList } from 'src/models/titleList';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends DatatablesServerComponent<User>  {

  columnsData: any[];
  columnNames: TitleItem[];
  apiCall: any;
  filename: string = 'Пользователи';

  constructor(protected api: ApiService,
              protected services: ServicesService) {
    super(api, services);
    this.setInitValues();
  }

  setInitValues(): void {
    const apiCall = this.api.user;
    this.apiCall = apiCall;
    this.apiLog = apiCall;
    this.apiLogHistory = apiCall;
    this.pageLength = 5;
    this.columnNames = getColumnTitles([
      'id', 'lastName', 'firstName', 'middleName', 'email', 'phone', 'category',
      'userModelType', 'country', 'region', 'city', 'tradingPlace', 'postAddress',
      'pointsAccountId', 'points', 'emailMessagingEnabled', 'phoneMessagingEnabled',
      'enabled', ['createdAt', 'input-calendar'], ['lastCheckDate', 'input-calendar']
    ]);
    const dtType = this.dtType;
    this.columnsData = [
      ['id', this.urlReturn], 'lastName', 'firstName', 'middleName', 'email', 'phone', 'category.name',
      ['modelTypes', dtType.array, true, false], 'tradingPlace.city.region.country.name', 'tradingPlace.city.region.name',
      'tradingPlace.city.name', 'tradingPlace.name', ['postAddress', dtType.longText],
      'pointsAccount.id', 'pointsAccount.points', ['emailMessagingEnabled', dtType.yesOrNo],
      ['phoneMessagingEnabled', dtType.yesOrNo], ['enabled', dtType.enabled], ['createdAt', dtType.date], ['lastCheckDate', dtType.dateShort]
    ];
  }

  ngOnInit() {
    this.setInit();
    this.dtOptions = {
      ...this.dtOptions,
      order: [[ 1, 'asc' ]],
      buttons: [
        this.filterButton,
        this.exportButtons,
        this.exportLogButtons
      ]
    }
  }
}
