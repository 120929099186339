import { Component } from '@angular/core';
import { DatatablesServerComponent } from 'src/models/datatablesServer';
import { Sale } from 'src/models/sales/sale';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { IDataTableResponse } from 'src/services/interfaces/IDataTableResponse';
import { TitleItem } from 'src/models/titleItem';
import { getColumnTitles } from 'src/utils/dt/columns';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent extends DatatablesServerComponent<Sale> {
  columnsData: any[];
  columnNames: TitleItem[];
  filename = 'Продажи';
  apiCall: IDataTableResponse<Sale>;

  constructor(api: ApiService,
              services: ServicesService) {
    super(api, services);
    this.setInitValues();
  }

  setInitValues(): void {
    const apiCall = this.api.sale;
    this.apiCall = apiCall;
    this.apiLog = apiCall;
    this.apiLogHistory = apiCall;
    this.pageLength = 5;
    this.columnNames = getColumnTitles([
      'id', ['saleCreatedAt', 'input-calendar'], 'lastName', 'firstName', 'middleName', 'email',
      'phone', 'category', 'countryAtSalTime', 'regionAtSalTime', 'cityAtSalTime', 'tradingPlaceAtSalTime', 'addressAtSalTime', 'barcode',
      'saleStatus', 'emailAdmin', 'comment', 'name.model', 'modelType',
      'modelBrand', ['photoBarcode', true], 'manualInput', 'points', 'name.activity' , 'supervisorFullName' , 'email',
      'installationClientPhone', 'installationClientAddress', 'installationComment', 'latitude', 'longitude'
    ]);
    const dtType = this.dtType;
    this.columnsData = [
      ['id', this.urlReturn], ['createdAt', dtType.date], 'user.lastName', 'user.firstName', 'user.middleName',
      'user.email', 'user.phone', 'user.category', 'country', 'region', 'city', 'tradingPlace', 'tradingPlaceAddress', 'barcode',
      'saleStatus.name', ['admin.email', dtType.string, false, false], ['comment', dtType.longText, false], 'model.name',
      'model.modelType.name', 'model.modelBrand.name', ['photoBarcode', dtType.photo, false],
      ['manualInput', dtType.manual], 'model.points', 'activity.name', 'supervisorsFullName', 'supervisorsEmail',
      'installationClientPhone', 'installationClientAddress', 'installationComment', 'installationLatitude', 'installationLongitude'
    ];
  }

  ngOnInit(): void {
    this.setInit();
    this.dtOptions = {
      ...this.dtOptions,
      order: [[1, 'desc']],
      buttons: [
        ...this.dtOptions.buttons,
        this.exportLogButtons
      ]
    };
  }
  setImages(uri: string) {
    this.sendPhoto(uri);
  }
}
