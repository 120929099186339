import { IRuleService } from './../../interfaces/settingsService/IRuleService';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Rule } from 'src/models/settings/rule';

@Injectable({
    providedIn: 'root'
})

export class InstructionService implements IRuleService {
    api: string = environment.api;
    constructor(private http: HttpClient) {}

    getOne(): Observable<Rule> {
        return this.http.get<Rule>(`${this.api}/instruction`);
    }
    update(value: string): Observable<Rule> {
        return this.http.put<Rule>(`${this.api}/updateinstruction`, JSON.stringify({value}));
    }
    
}