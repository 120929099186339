import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/services/apiServices/allServices/navigation.service';
import { ApiService } from 'src/services/api.service';

@Component({
  selector: 'app-base-auth',
  templateUrl: './base-auth.component.html',
  styleUrls: ['./base-auth.component.scss']
})
export class BaseAuthComponent implements OnInit {
  name: string;
  constructor(private nav: NavigationService,
              private api: ApiService) { 
  }

  ngOnInit() {
    this.name = this.nav.PathMenu;
  }

}
