import { CppdService } from './cppd.service';
import { GdprService } from './gdpr.service';
import { AdminInfoService } from './adminInfo.service';
import { ISettingsService } from './../../interfaces/settingsService/ISettingsService';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Settings } from 'src/models/settings/settings';
import { IBaseService } from 'src/services/interfaces/IBaseService';
import { AdminInfo } from 'src/models/settings/adminInfo';
import { IRuleService } from 'src/services/interfaces/settingsService/IRuleService';
import { RulesOfParticipationService } from './rulesOfParticipation.service';
import { InstructionService } from './instruction.service';
import {CommissionCppdService} from './commission-cppd.service';
import {PointValue} from '../../../models/settings/pointValue';

@Injectable({
    providedIn: 'root'
})

export class SettingsService implements ISettingsService {
    api: string = environment.api;
    adminInfo: IBaseService<AdminInfo>;
    rulesOfParticipation: IRuleService;
    instruction: IRuleService;
    gdpr: IRuleService;
    cppd: IRuleService;
    commissionCppd: IRuleService;
    constructor(private http: HttpClient,
                adminInfo: AdminInfoService,
                rulesOfParticipation: RulesOfParticipationService,
                instruction: InstructionService,
                gdpr: GdprService,
                cppd: CppdService,
                commissionCppd: CommissionCppdService,
                )
    {
        this.adminInfo = adminInfo;
        this.rulesOfParticipation = rulesOfParticipation;
        this.instruction = instruction;
        this.gdpr = gdpr;
        this.cppd = cppd;
        this.commissionCppd = commissionCppd;
    }
    getOne(): Observable<Settings> {
        return this.http.get<Settings>(`${this.api}/settings`);
    }
    update(item: Settings): Observable<Settings> {
        return this.http.put<Settings>(`${this.api}/settings`, JSON.stringify(item));
    }
    saveImageToServer(file: string|ArrayBuffer): Observable<string>{
        return this.http.post<string>(`${this.api}/settings/uploadfile`, JSON.stringify({value: file}));
    }

  getPointValue(): Observable<PointValue> {
    return this.http.get<PointValue>(`${this.api}/point-value`);
  }

  updatePointValue(pointValue: PointValue): Observable<PointValue> {
    return this.http.put<PointValue>(`${this.api}/point-value`, JSON.stringify(pointValue));
  }
}
