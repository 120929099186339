import { BaseService } from './../base.service';
import { IBaseService } from 'src/services/interfaces/IBaseService';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AdminInfo } from "src/models/settings/adminInfo";

@Injectable({
    providedIn: 'root'
})

export class AdminInfoService implements IBaseService<AdminInfo> {
    constructor(private baseService: BaseService<AdminInfo>){}
    getAll(): Observable<AdminInfo[]> {
        return this.baseService.getAll("adminsinfo");
    }
    getOne(id: number): Observable<AdminInfo> {
        return this.baseService.getOne(id, "admininfo");
    }
    add(item: AdminInfo): Observable<AdminInfo> {
        throw new Error("Method not implemented.");
    }
    update(item: AdminInfo): Observable<AdminInfo> {
        return this.baseService.update(item, "admininfo");
    }
}