import { TitleItem } from "./titleItem";

export class TitleList {
    getItemList(names: any[]){
        let columnNames: TitleItem[] = [];
        names.map(value => {
            if(typeof value === 'string'){
                columnNames.push(new TitleItem(value));
            }
            else if(value.length){
                const [name, className, hideSearch, isLink] = value; 
                columnNames.push(new TitleItem(name, className, hideSearch, isLink));
            }
        });
        return columnNames;
    }
}