import { Country } from './../../../models/geography/country';
import { TradingPlaceType } from 'src/models/tradingPlace/tradingPlaceType';
import { TradingPlace } from 'src/models/tradingPlace/tradingPlace';
import { Component, OnDestroy } from '@angular/core';
import { EditorComponent } from 'src/models/editor';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ISelect } from 'src/interfaces/ISelect';
import { SelectList } from 'src/models/form/selectList';
import { SelectUser } from 'src/models/users/selectUser';
import { City } from 'src/models/geography/city';
import { SelectOptions } from 'src/models/form/selectOptions';
import { Image } from 'src/models/tradingPlace/images';
import { Region } from 'src/models/geography/region';

@Component({
  selector: 'app-trading-place-editor',
  templateUrl: './trading-place-editor.component.html',
  styleUrls: ['./trading-place-editor.component.scss']
})
export class TradingPlaceEditorComponent extends EditorComponent<TradingPlace> implements ISelect, OnDestroy {
  apiCall: any;
  images: any[];
  selectOptions: SelectOptions;
  selectImage: Image;
  tradingPlaceTypes: TradingPlaceType[] = [];
  private location: any = {
    longitude: '',
    latitude: ''
  }
  private answerSubscription;
  constructor(services: ServicesService,
    route: ActivatedRoute,
    formBuilder: FormBuilder,
    api: ApiService,
    translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.item = new TradingPlace();
    this.item.contactUser = new SelectUser();
    this.item.contactUser.email = '';
    this.form = this.formBuilder.group({
      'name': new FormControl('', [Validators.required]),
      'country': new FormControl({ value: null, disabled: true }, [Validators.required]),
      'region': new FormControl({ value: null, disabled: true }, [Validators.required]),
      'city': new FormControl({ value: null, disabled: true }, [Validators.required]),
      'address': new FormControl('', [Validators.required]),
      'createdUser': new FormControl({ value: null, disabled: false }),
      'contactUser': new FormControl({ value: null, disabled: false }, [Validators.required]),
      'createdAt': new FormControl(''),
      'tradingPlaceType': new FormControl(environment.defaultValue.tradingPlaceType, [Validators.required]),
      'longitude': new FormControl('', [Validators.pattern(/^\d{1,2}\.\d{7}$/)]),
      'latitude': new FormControl('', [Validators.pattern(/^\d{1,2}\.\d{7}$/)]),
      'gpsTime': new FormControl(''),
      'positionUser': new FormControl(''),
      'deleted': new FormControl(false)
    });
    this.selectOptions = new SelectOptions(['country', 'region', 'city']);
    this.selectOptions.selectItemsEvent.subscribe(selectList => this.selectItems(selectList));
    this.answerSubscription = this.services.message.getAnswerDialogEvent().subscribe(answer => {
      if (answer) {
        this.delete();
      }
    });
    this.apiCall = api.tradingPlace;
  }
  ngOnDestroy(): void {
    this.selectOptions.selectItemsEvent.unsubscribe();
    this.answerSubscription.unsubscribe();
  }
  setPatchValue() {
    this.form.patchValue({
      ...this.item,
      'contactUser': null,
      'city': null,
      'region': null,
      'country': null,
      'tradingPlaceType': this.item.tradingPlaceType.id
    });
    this.location.longitude = this.item.longitude;
    this.location.latitude = this.item.latitude;
    this.setImages(this.item.images);
  }
  setImages(images: Image[]) {
    this.images = [];
    if (images.length === 0) return;
    const that = this;
    images.map(image => {
      that.images.push({
        source: `${environment.api}/uploads/${image.name}`,
        title: image.name
      });

    });
    this.selectImage = images[0]
  }
  changeImage(event: any) {
    this.selectImage = this.item.images[event.index];
  }
  setDefaultValues() {
    this.images = [];

    this.api.tradingPlace.type.getAll().subscribe(items => {
      this.tradingPlaceTypes = items;
      const selectType = items.find(val => val.name === environment.defaultValue.tradingPlaceType);
      if (selectType) {
        this.item.tradingPlaceType = new TradingPlaceType(selectType.id, selectType.name);
        this.form.controls.tradingPlaceType.setValue(selectType.id);
      }
    });
  }
  selectItems(selectList: SelectList) {
    const selectCountry = selectList.getSelect('country');
    const selectRegion = selectList.getSelect('region');
    const selectCity = selectList.getSelect('city');

    if (!selectCountry.HasValues) {
      this.api.geography.country.getAll().subscribe(data => {
        if (this.id && selectList.isInit) {
          selectList.setItems('country', data, this.item.city.region.country.id);
        }
        else if (!this.id && selectList.isInit)
          selectList.setItems('country', data, 'Россия');
        else
          selectList.setItems('country', data);
      })
    }

    if (selectCountry.HasSelected && !selectRegion.HasValues) {
      this.api.geography.region.getRegions(selectCountry.Active.id).subscribe(data => {
        if (this.id && selectList.isInit) {
          selectList.setItems('region', data, this.item.city.region.id);
        }
        else
          selectList.setItems('region', data);
      })
    }

    if (selectRegion.HasSelected && !selectCity.HasValues) {
      this.api.geography.city.getCities(selectRegion.Active.id).subscribe(data => {
        if (this.id && selectList.isInit) {
          selectList.setItems('city', data, this.item.city.id);
        }
        else
          selectList.setItems('city', data);
      })
    }
  }

  setValuesToObject(): void {
    this.onSubmit.emit();
    const values = this.form.value;
    const { country, region, city, tradingPlaceType, contactUser, longitude, latitude, ...valuesToSubmit } = values;

    if ((!longitude && latitude) || (longitude && !latitude)) {
      if (!longitude) this.form.get('longitude').setErrors({ required: true });
      if (!latitude) this.form.get('latitude').setErrors({ required: true });
      return;
    }

    Object.assign(this.item, valuesToSubmit);


    if (city) {
      this.item.city = new City(city);
      if (region) {
        this.item.city.region = new Region(region);
        if (country) {
          this.item.city.region.country = new Country(country);
        }
      }
    }

    if (contactUser) {
      this.item.contactUser = new SelectUser(contactUser);
    }

    if (!this.id) {
      this.item.createdUser = new SelectUser(this.currentUser.id);
    }

    if (longitude && latitude) {
      if (!this.id || longitude !== this.location.longitude || latitude !== this.location.latitude) {
        this.item.latitude = latitude;
        this.item.longitude = longitude;
        this.item.positionUser = new SelectUser(this.currentUser.id);
      }
      if (longitude === this.location.longitude && latitude === this.location.latitude)
        this.deleteLocation();
    }
    else {
      this.deleteLocation();
      if (this.id && longitude !== this.location.longitude || latitude !== this.location.latitude)
        this.item.positionUser = new SelectUser(this.currentUser.id);
    }
    delete this.item.createdAt;
    delete this.item.gpsTime;

    this.item.tradingPlaceType = new TradingPlaceType(parseInt(tradingPlaceType));
  }
  deleteLocation() {
    delete this.item.longitude;
    delete this.item.latitude;
    delete this.item.positionUser;
  }
  returnError(error: any) {
    if (error.hasOwnProperty('deleted')) {
      this.form.controls['deleted'].setErrors({ delete: true });
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error);
  }
  delete(): any {
    this.api.tradingPlace.deleteImage(this.selectImage.id).subscribe(
      _ => {
        this.item.images = this.item.images.filter((image: Image) => image.id !== this.selectImage.id);
        this.setImages(this.item.images);
        this.sendSuccessAlert('Фото успешно удалено.');
      }
    )

  }
  downloadAll() {
    this.api.tradingPlace.getZip(this.item.id).subscribe(
      res => this.downloadFile(res, this.item.name + '.zip')
    );
  }

  download() {
    this.api.tradingPlace.getImage(this.selectImage.id).subscribe(
      res => this.downloadFile(res, this.selectImage.name)
    );
  }

  downloadFile(res: Response, filename: string) {
    const dataType = res.type;
    const binaryData = [];
    binaryData.push(res);
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
    if (filename) {
      downloadLink.setAttribute('download', filename);
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  }

  sendSuccessAlert(text: string) {
    this.message.text = text;
    this.message.position = this.message.messagePosition.float;
    this.message.color = this.message.messageColor.success;
    this.message.type = this.message.messageType.alert;
    this.services.message.setMessageEvent(this.message);
  }
}
