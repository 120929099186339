import { IPointsAccountService } from 'src/services/interfaces/userSevice/IPointsAccountService';
import { AdminService } from './admin.service';
import { Injectable } from "@angular/core";
import { IUserService } from "../../interfaces/userSevice/IUserService";
import { Observable } from "rxjs";
import { CategoryService } from "./category.service";
import { User } from "src/models/users/user";
import { IAdminService } from "src/services/interfaces/userSevice/IAdminService";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DataTablesResponse } from 'src/models/dataTablesResponse';
import { PointsAccountService } from './pointsAccount.service';
import { IBaseService } from 'src/services/interfaces/IBaseService';
import { Category } from 'src/models/users/category';
import { BaseService } from '../base.service';
import { LogService } from '../allServices/log.service';

@Injectable({
    providedIn: 'root'
})

export class UserService implements IUserService {
    api: string = environment.api;
    public category: IBaseService<Category>;
    public admin: IAdminService;
    public pointsAccount: IPointsAccountService;
    constructor(private http: HttpClient,
                private baseService: BaseService<User>,
                private log: LogService,
                category: CategoryService,
                admin: AdminService,
                pointsAccount: PointsAccountService)
    {
        this.category = category;
        this.admin = admin;
        this.pointsAccount = pointsAccount;
    }
    getAll(): Observable<User[]> {
        throw new Error("Method not implemented.");
    }
    getOne(id: number): Observable<User> {
        return this.baseService.getOne(id, "user");
    }
    getPage(dataTablesParameters: any): Observable<DataTablesResponse> {
        return this.http.post<DataTablesResponse>(`${this.api}/users`, JSON.stringify(dataTablesParameters));
    }
    add(item: User): Observable<User> {
        return this.baseService.add(item, "adduser");
    }
    update(item: User): Observable<User> {
        return this.baseService.update(item, "user");
    }
   
    search(email: string, length: number): Observable<User[]> {
        return this.http.post<User[]>(`${this.api}/user/search`, JSON.stringify({email, length}));
    }

    logXls(dataTablesParameters: any): Observable<any> {
        return this.log.postXls(dataTablesParameters, "usersXls");
    }
    logXml(dataTablesParameters: any): Observable<any> {
        return this.log.postXml(dataTablesParameters, "usersXml");
    }
    logHistoryXls(dataTablesParameters?: any): Observable<any> {
        return this.log.getXls("userHistoryXls");
    }
    logHistoryXml(dataTablesParameters?: any): Observable<any> {
        return this.log.getXml("userHistoryXml");
    }
}