import { Component, OnInit } from '@angular/core';
import { DatatablesComponent } from 'src/models/datatables';
import { Admin } from 'src/models/users/admin';
import { ApiService } from 'src/services/api.service';
import { MessageService } from 'src/services/apiServices/allServices/message.service';
import { ServicesService } from 'src/services/services.service';
import { TitleItem } from 'src/models/titleItem';
import { TitleList } from 'src/models/titleList';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent extends DatatablesComponent<Admin> {
  columnNames: TitleItem[];
  apiCall: any;
  constructor(protected api: ApiService,
              protected services: ServicesService)
  { 
    super(api, services);
    this.apiCall = api.user.admin;
    const names = [ 
      'login', 'lastName', 'firstName', 'enabled'
    ];
    this.columnNames = new TitleList().getItemList(names);
  }
}
