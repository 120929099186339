import { ModelType } from './../../../models/model/modelType';
import { Component, OnInit } from '@angular/core';
import { EditorComponent } from 'src/models/editor';
import { ApiService } from 'src/services/api.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ServicesService } from 'src/services/services.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-model-type-editor',
  templateUrl: './model-type-editor.component.html',
  styleUrls: ['./model-type-editor.component.scss']
})
export class ModelTypeEditorComponent extends EditorComponent<ModelType> {
  apiCall: any;
  constructor(services: ServicesService, 
              route: ActivatedRoute, 
              formBuilder: FormBuilder, 
              api: ApiService,
              translate: TranslateService) { 
    super(services, route, formBuilder, api, translate);
    this.item = new ModelType();
    this.form = this.formBuilder.group({
      "name" : new FormControl('', [Validators.required])
    });
    this.apiCall = api.model.modelType;
  }
  
  setValuesToObject(): void {
    Object.assign(this.item, this.form.value);
  }
  returnError(error: any) {
    if(error.indexOf(environment.serverError.alreadyExist) > -1){
      this.form.controls['name'].setErrors({exist: true});
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error);
  }
}
