import { LogService } from './apiServices/allServices/log.service';
import { Injectable } from '@angular/core';
import { LoaderService } from './apiServices/allServices/loader.service';
import { ILoaderService } from './interfaces/allServices/ILoaderService';
import { NavigationService } from './apiServices/allServices/navigation.service';
import { MessageService } from './apiServices/allServices/message.service';
import { INavigationService } from './interfaces/allServices/INavigationService';
import { IMessageService } from './interfaces/allServices/IMessageService';
import { ILogService } from './interfaces/allServices/ILogService';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  public loader: ILoaderService;
  public message: IMessageService;
  public nav: INavigationService;
  public log: ILogService;

  constructor(loader: LoaderService,
              message: MessageService,
              nav: NavigationService,
              log: LogService) 
  { 
    this.loader = loader;
    this.message = message;
    this.nav = nav;
    this.log = log;
  }
} 