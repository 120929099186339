import { DtColumnType } from './../../models/datatable/dtColumnType';
import { TitleItem } from "src/models/titleItem";
import { isArray } from "util";
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { IBase } from 'src/interfaces/IBase';

const dtType = DtColumnType;
export function getColumnTitles(names: any[]) {
    let columnNames: TitleItem[] = [];
    names.map(value => {
        if (typeof value === 'string') {
            columnNames.push(new TitleItem(value));
        }
        else if (isArray(value)) {
            const [name, className, hideSearch, isLink] = value;
            columnNames.push(new TitleItem(name, className, hideSearch, isLink));
        }
    });
    return columnNames;
}
export function getColumnData(names: any[]) {
    let columns = [];
    const shift = names[0][0] === 'id' ? 2 : 1;
    for (let i = shift; i < names.length + shift; i++) {
        const name = names[i - shift];
        let columnData = "";
        let orderable = true;
        if (i === shift) {
            columnData = name[0];
            if (columnData !== 'id') {
                columns.push({
                    data: 'id',
                    className: "col-hide"
                });
            }
        }
        else if (typeof name === "string") {
            columnData = name;
        }
        else if (isArray(name)) {
            columnData = name[0];
            orderable = name[2] !== undefined ? name[2] : orderable;
        }
        const column = {
            data: columnData,
            orderable,
            render: function (data, type, row, meta) {
                if (i === shift) {
                    return getDtColumn(data, dtType.string, true, true, row.id, name[1]);
                }
                else if (typeof name === "string") {
                    return getDtColumn(data, dtType.string, true, false, 0);
                }
                else if (isArray(name)) {
                    const [columnName, dtColumnType, isSort, isLink, id, uri] = name;
                    if (isLink) {
                        return getDtColumn(data, dtColumnType, isSort);
                    }
                    return getDtColumn(data, dtColumnType, isSort, isLink);
                }
            }
        }
        columns.push(column);
    }
    return columns;
}
export function getDtColumn(data: any, type: DtColumnType = dtType.string, isSort: boolean = true, isLink: boolean = true, id: number = 0, uri: string = "") {
    let column;
    switch (type) {
        case dtType.string:
            column = getUriData(data, id, isLink, uri);
            break;
        case dtType.photo:
            column = getUriPhoto(data);
            break;
        case dtType.date:
            column = getUriDate(data);
            break;
        case dtType.dateShort:
            column = getUriDateWithoutTime(data);
            break;
        case dtType.longText:
            column = getUriLongText(data);
            break;
        case dtType.array:
            column = getUriArray(data, id, isLink, uri);
            break;
        case dtType.arrayString:
            column = getUriArrayString(data, id);
            break;
        case dtType.yesOrNo:
            column = getUriYesOrNo(data);
            break;
        case dtType.enabled:
            column = getUriDisabledOrEnabled(data);
            break;
        case dtType.delete:
            column = getUriDisabledOrEnabled(!data);
            break;
        case dtType.manual:
            column = getUriInputManulOrAuto(data);
            break;

    }
    return column;
}

export function getUriData(data: string | number, id: number, isLink: boolean = true, uri: string = ""): string {
    if (!data && data !== 0)
        data = "";
    if (isLink)
        return `<a href="${uri}/editor/${id}">${data}</a>`;

    return data.toString();
}

export function getUriPhoto(data: string): string {
    const src = `${environment.api}/uploads/${data}`;
    return `<div class="icon-photo">
                <img src="${src}" class="w-100 h-100"/>
            </div>`;
}

export function getUriLongText(data: string): string {
    return `<div class="ui-scrollpanel">
        <div class="ui-scrollpanel-wrapper">
            <div class="ui-scrollpanel-content">
                ${data || ''}
            </div>
        </div>
    </div>`;
}

export function getUriArray(data: IBase[], id: number, isLink: boolean = true, uri: string = ""): string {
    let result = [];
    if (isLink) {
        data.map(category => result.push(`<div><a href="${uri}/editor/${id}">${category.name}</a></div>`));
    }
    else {
        data.map(category => result.push(`<div>${category.name}</div>`));
    }
    return `<div class="ui-scrollpanel">
                <div class="ui-scrollpanel-wrapper">
                    <div class="ui-scrollpanel-content">
                        ${result.join('')}
                    </div>
                </div>
            </div>`;
}
export function getUriArrayString(data: IBase[], id: number): string{
    let result = [];
    data.map(category => result.push(category.name));
    return result.join(', ');
}

export function getUriDate(data: Date, id?: number): string {
    if (!data) return "";
    const now = moment(data);
    return now.format('YYYY-MM-DD HH:mm');
}
export function getUriDateWithoutTime(data: Date, id?: number): string {
    if (!data) return "";
    const now = moment(data);
    return now.format('YYYY-MM-DD');
}

export function getUriYesOrNo(data: boolean): string {
    return data ? "Да" : "Нет";
}

export function getUriDisabledOrEnabled(data: boolean): string {
    return data ? "Активный" : "Заблокирован";
}

export function getUriInputManulOrAuto(data: boolean): string {
    return data ? "Вручную" : "Автоматически"
}
