import { FormGroup } from '@angular/forms';
import { SelectMany } from './selectMany';

export class SelectManyList {
    isInit = true;
    select: Array<SelectMany> = [];
    private isCascade: boolean;
    private form: FormGroup;
    private listItems: string[];
    constructor(items: string[], form: FormGroup) {
        this.form = form;
        this.listItems = items;
        items.map(name => {
            this.select[name] = new SelectMany();
            this.select[name].onIsDisabled.subscribe(
                isDisabled => this.onIsDisabled(name, isDisabled)
            );
        });
    }

    getSelect(name: string): SelectMany {
        return this.select[name];
    }
    setItems(name: string, values: any);
    setItems(name: string, values: any, defaultValue: number);
    setItems(name: string, values: any, defaultValue: string);
    setItems(name: string, values: any, defaultValue?: any) {
        const selectItem = this.getSelect(name);
        selectItem.setItems(values);
    }

    findIndexItem(name: string) {
        return this.listItems.findIndex(value => value === name);
    }

    selected(value: any | number, name: string) {
        const selectList = this.select[name] as SelectMany;
        selectList.selected(value.id ? value.id : value);
        if (value.id === -1) {
            const activityIds = selectList.Actives.map(active => active.id);
            this.form.controls[name].setValue(activityIds);
        } else if (value.id === -2) {
            this.form.controls[name].setValue([]);
        }
    }

    removed(value: any, name: string) {
        this.select[name].removed(value.id);
    }
    disable(name: string) {
        this.getSelect(name).IsDisabled = true;
    }
    enable(name: string) {
        this.getSelect(name).IsDisabled = false;
    }
    onIsDisabled(name: string, isDisabled: boolean) {
        const control = this.form.controls[name];
        isDisabled ? control.disable() : control.enable();
    }
}
