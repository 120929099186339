import { ApiService } from './../../services/api.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/services/apiServices/allServices/navigation.service';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private api: ApiService, 
              private route: ActivatedRoute,
              private nav: NavigationService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    let currentUser = this.api.auth.getCurrentUser();
    if (currentUser.isAuthorized) { 
        if(this.nav.isAccessAllowedByUrl(url, currentUser))
            return true; 
        if(this.nav.isEditorByUrl(url)){
          const id = this.nav.getId(url);
          if(id === currentUser.id)
             return true;
        }

        this.nav.redirectTo('users/users');
        return false;
    }
    this.nav.redirectToLogin();
    return false;
  }
}