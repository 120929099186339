import { Option } from './../../../models/form/option';
import { Sale } from './../../../models/sales/sale';
import { EditorComponent } from 'src/models/editor';
import { Component } from '@angular/core';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { SelectUser } from 'src/models/users/selectUser';
import { forkJoin } from 'rxjs';
import { Base } from 'src/models/base';
import { Util } from 'src/utils/util';

@Component({
  selector: 'app-sale-editor',
  templateUrl: './sale-editor.component.html',
  styleUrls: ['./sale-editor.component.scss']
})
export class SaleEditorComponent extends EditorComponent<Sale> {
  apiCall: any;
  saleStatus: Option[];
  selectStatus: number;
  disabledAll = false;
  confirm = 1;
  cancel = 6;
  src: string;
  installationPhotos: string[];
  constructor(services: ServicesService,
              route: ActivatedRoute,
              formBuilder: FormBuilder,
              api: ApiService,
              translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.form = this.formBuilder.group({
      'saleStatus' : new FormControl('', [Validators.required]),
      'comment' : new FormControl('')
    });
    this.apiCall = api.sale;
  }
  setValues(): void {
    const observable = forkJoin(
      this.api.sale.getAllStatus(),
      this.api.sale.getOne(this.id)
    );
    observable.subscribe(
      res => {
        this.saleStatus = res[0] as Option[];
        const item = res[1] as Sale;
        this.item = item;
        this.changeValidators(this.item.saleStatus.id);
        this.form.patchValue({
          ...this.item,
          'saleStatus': this.selectStatus
        });
        this.item.createdAt = this.getUriDate(this.item.createdAt);
        this.item.admin = item.admin.email ? item.admin :  new SelectUser(this.currentUser.id, this.currentUser.email);
        this.src = Util.getPathImage(this.item.photoBarcode);

        for ( let i = 0; i < this.item.installationPhotos.length; i++) {
          this.item.installationPhotos[i] = Util.getPathImage(this.item.installationPhotos[i]);
        }
        this.InProgress = false;
      },
      error => this.catchError(error)
    );

  }

  setValuesToObject(): void {
    const { comment } = this.form.value;
    this.item.saleStatus = new Base(this.selectStatus);
    this.item.comment = comment;
  }
  returnError(error: any) {
    throw new Error('Method not implemented.');
  }
  onChange(value: any) {
    this.changeValidators(parseInt(value));
  }
  changeValidators(id: number) {
    this.selectStatus = id;
    const comment = this.form.controls['comment'];

    this.saleStatus.map(status => status.disabled = true);
    if (id === this.confirm) {
      const status = this.saleStatus.find(status => status.id === this.cancel);
      status.disabled = false;
    }

    if (id === this.cancel) {
      comment.setValidators(Validators.required);
    } else {
      comment.setValidators([]);
      comment.setErrors(null);
    }
  }
  openImg() {
    this.sendPhoto(this.src);
  }

}
