import { DatatablesComponent } from "./datatables";
import { ApiService } from "src/services/api.service";
import { ServicesService } from "src/services/services.service";
import { IBase } from "src/interfaces/IBase";
import * as moment from 'moment';
import { IDataTableResponse } from "src/services/interfaces/IDataTableResponse";
import { IExportService } from "src/services/interfaces/IExportService";
import { IExportHistoryService } from "src/services/interfaces/IExportHistoryService";
import { environment } from "src/environments/environment";
import beautify from 'xml-beautifier';
import { getColumnData } from "src/utils/dt/columns";
import { ViewChild } from "@angular/core";


export abstract class DatatablesServerComponent<T> extends DatatablesComponent<T> {
    private target: any;
    private timer: any;
    protected exportButtons: any;
    protected exportLogButtons: any;
    protected parameters: any;
    protected pageLength: number;

    abstract filename: string;
    abstract columnsData: any[];
    abstract setInitValues(): void;

    abstract apiCall: IDataTableResponse<T>;
    apiLog: IExportService;
    apiLogHistory: IExportHistoryService;
    @ViewChild('calendar') calendar: any;
    constructor(protected api: ApiService,
        protected services: ServicesService) {
        super(api, services);
        this.pageLength = 10;
        this.urlReturn = this.services.nav.getSubmenu().url;
    }
    ngOnInit() {
        this.setInit();
    }

    setInit() {
        super.setInit();
        const that = this;
        this.exportButtons = {
            extend: "collection",
            text: 'Экспорт',
            autoClose: true,
            buttons: [
                {
                    text: 'XLS',
                    key: '0',
                    action: function (e, dt, node, config) {
                        that.exportXLS();
                    }
                },
                {
                    text: 'XML',
                    key: '1',
                    action: function (e, dt, node, config) {
                        that.exportXML();
                    }
                }
            ]
        };
        this.exportLogButtons = {
            extend: "collection",
            text: 'Экспорт лог',
            autoClose: true,
            buttons: [
                {
                    text: 'XLS',
                    key: '0',
                    action: function (e, dt, node, config) {
                        that.exportLogXLS();
                    }
                },
                {
                    text: 'XML',
                    key: '1',
                    action: function (e, dt, node, config) {
                        that.exportLogXML();
                    }
                }
            ]
        }

        this.dtOptions = {
            ...this.dtOptions,
            serverSide: true,
            processing: true,
            lengthMenu: [[5, 10, 25, 50, 100], [5, 10, 25, 50, 100]],
            pageLength: this.pageLength,
            buttons: [
                ...this.dtOptions.buttons,
                this.exportButtons
            ],
            ajax: (dataTablesParameters: any, callback) => this.ajaxServer(dataTablesParameters, callback),
            columns: [
                {
                    data: null,
                    defaultContent: '',
                    title: '<input type="checkbox" class="checkbox select-all" id="checkbox"/><label for="checkbox"></label>'
                }
            ],
            drawCallback: function (settings) {
                that.selectAllRows();
            }
        }

        this.dtOptions.columns = this.createColumns();

    }
    protected createColumns() {
        const columnsData = getColumnData(this.columnsData);
        let columns = [
            ...this.dtOptions.columns,
            ...columnsData
        ];
        return columns;
    };
    exportXLS() {
        this.apiLog.logXls(this.parameters).subscribe(
            res => this.downloadFile(res, `${this.filename}.xls`),
            e => this.returnError(e)
        );
    };
    exportXML() {
        this.apiLog.logXml(this.parameters).subscribe(
            res => this.downloadFile(res, `${this.filename}.xml`),
            e => this.returnError(e)
        );
    }
    exportLogXLS() {
        this.apiLogHistory.logHistoryXls(this.parameters).subscribe(
            res => this.downloadFile(res, `${this.filename}-лог.xls`),
            e => this.returnError(e)
        );
    }
    exportLogXML() {
        this.apiLogHistory.logHistoryXml(this.parameters).subscribe(
            res => this.downloadFile(res, `${this.filename}-лог.xml`),
            e => this.returnError(e)
        );
    }


    ajaxServer(dataTablesParameters: any, callback) {
        this.parameters = { ...dataTablesParameters };
        this.apiCall.getPage(dataTablesParameters).subscribe(resp => {
            this.items = resp.data;
            callback({
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                data: resp.data
            });
            this.rerender();
        });
    }
    rerender() {
        const self = this;

        this.datatableElement.dtInstance.then((dtInstance: any) => {
            let table: DataTables.Api = dtInstance;
            $('.table tbody a').unbind('click');
            $('.table tbody a').bind('click', function (event) {
                event.preventDefault();
                event.stopPropagation();
                const path = $(this).attr('href');
                self.services.nav.redirectTo(path);
            });
            $('.reset').unbind();
            $('.reset').bind('click', function () {
                const input = $('input.input-search');
                input.each(function () {
                    $(this).val('');
                });
                table.columns().search('').draw();
            });
            self.openCalendar();
            $('.table thead tr:eq(1) th').each(function (i) {
                $('input', this).unbind('keyup change search');
                $('input', this).bind('keyup change search', function () {
                    if (table.column(i).search() !== this['value']) {
                        if (self.timer)
                            clearTimeout(self.timer);
                        self.timer = setTimeout(_ => {
                            table
                                .column(i)
                                .search(this['value'])
                                .draw();
                            self.timer = null;
                        }, 500);
                    }
                });
            });
            $('.select-all').unbind("change");
            $('.select-all').bind("change", function () {
                self.selectAllRows();
            });
        });
        $(".icon-photo img").bind("click", function () {
            self.setImages($(this).attr('src'));
        });
        this.selectAllButton();

    }
    protected selectAllRows() {
        this.datatableElement.dtInstance.then((dtInstance: any) => {
            let checkbox = document.querySelector('.select-all');
            if (checkbox['checked']) {
                dtInstance.rows({ page: 'current' }).select();
            }
            else {
                dtInstance.rows({ page: 'current' }).deselect();
            }
        });
    }
    openCalendar() {
        const that = this;
        $('input.input-calendar').unbind();
        $('input.input-calendar').bind('click', function (event) {
            that.target = event.target;
            const value = (<HTMLInputElement>event.target).value;
            if(value){
                that.calendar.setValue(value);
            }
            that.calendar.open();
        });
    }
    setValueToCalendar(date: string){
        this.target.value = date;
        $(this.target).change();
    }
    downloadFile(res: Response, filename: string) {
        let dataType = res.type;
        const xmlType = 'application/xml' as ResponseType;
        let binaryData = [];
        if (!dataType) {
            dataType = xmlType;
            const xml = beautify(res);
            binaryData.push(xml);
        }
        else {
            binaryData.push(res);
        }

        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        if (filename)
            downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
    }
    protected getUri(id: number, data: string | number, isLink: boolean = true): string {
        if (!data && data !== 0)
            data = "";
        if (isLink)
            return `<a href="${this.urlReturn}/editor/${id}">${data}</a>`;

        return data.toString();
    }
    protected getUriArray(id: number, data: IBase[], isLink: boolean = true): string {
        let result = [];
        if (isLink) {
            data.map(category => result.push(`<div><a href="${this.urlReturn}/editor/${id}">${category.name}</a></div>`));
        }
        else {
            data.map(category => result.push(`<div>${category.name}</div>`));
        }
        return `<div class="ui-scrollpanel">
                    <div class="ui-scrollpanel-wrapper">
                        <div class="ui-scrollpanel-content">
                            ${result.join('')}
                        </div>
                    </div>
                </div>`;
    }
    protected getUriYesOrNo(data: boolean): string {
        return data ? "Да" : "Нет"
    }
    protected getUriDisabledOrEnabled(data: boolean): string {
        return data ? "Активный" : "Заблокирован"
    }
    protected getUriInputManulOrAuto(data: boolean): string {
        return data ? "Вручную" : "Автоматически"
    }
    protected getUriDate(id: number, data: Date): string {
        if (!data) return "";
        // const now = moment.utc(data).local();
        const now = moment(data);
        return now.format('YYYY-MM-DD HH:mm');
    }
    protected getPhoto(data: string): string {
        const src = `${environment.api}/uploads/${data}`;
        return `<div class="icon-photo">
                    <img src="${src}" class="w-100 h-100"/>
                </div>`;
    }
    protected getLongText(data: string): string {
        return `<div class="ui-scrollpanel">
            <div class="ui-scrollpanel-wrapper">
                <div class="ui-scrollpanel-content">
                    ${data || ''}
                </div>
            </div>
        </div>`;
    }
    setImages(uri: string) {
        throw new Error("Method not implemented.");
    }
}
