import { LogService } from './../allServices/log.service';
import { BaseService } from './../base.service';
import { Country } from 'src/models/geography/country';
import { Injectable } from "@angular/core";
import { of, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { IActivityService } from 'src/services/interfaces/activityService/IActivityService';
import { Activity } from 'src/models/activity/activity';
import { DataTablesResponse } from 'src/models/dataTablesResponse';

@Injectable({
    providedIn: 'root'
})

export class ActivityService implements IActivityService {
    
    api: string = environment.api;
    constructor(private http: HttpClient,
                private baseService: BaseService<Activity>,
                private log: LogService)
    { }
    getAll(): Observable<Activity[]> {
        return this.baseService.getAll("adminactivity");
    }
    getPage(dataTablesParameters: any): Observable<DataTablesResponse> {
        return this.http.post<DataTablesResponse>(`${this.api}/adminactivity`, JSON.stringify(dataTablesParameters));
    }
    getPageModels(dataTablesParameters: any, id: number): Observable<DataTablesResponse> {
        return this.http.post<DataTablesResponse>(`${this.api}/promomodels/${id}`, JSON.stringify(dataTablesParameters));
    }
    getOne(id: number): Observable<Activity> {
        return this.baseService.getOne(id, "activity");
    }
    getYears(): Observable<any> {
        return this.http.get<Activity>(`${this.api}/adminfirstactivities`);
    }
    add(item: Activity): Observable<Activity> {
        return this.baseService.add(item, "activity");
    }
    update(item: Activity): Observable<Activity> {
        return this.baseService.update(item, "activity");
    }
    logXls(dataTablesParameters: any): Observable<any> {
        return this.log.postXls(dataTablesParameters, "activitiesXls");
    }
    logXml(dataTablesParameters: any): Observable<any> {
        return this.log.postXml(dataTablesParameters, "activitiesXml");
    }
}