import { LogService } from './../allServices/log.service';
import { BaseService } from './../base.service';
import { ModelBrand } from './../../../models/model/modelBrand';
import { ModelTypeService } from './modelType.service';
import { Injectable } from "@angular/core";
import { IModelService } from "src/services/interfaces/modelService/IModelService";
import { HttpClient } from "@angular/common/http";
import { ModelBrandService } from './modelBrand.service';
import { Observable } from 'rxjs';
import { DataTablesResponse } from 'src/models/dataTablesResponse';
import { Model } from 'src/models/model/model';
import { environment } from 'src/environments/environment';
import { IBaseService } from 'src/services/interfaces/IBaseService';
import { ModelType } from 'src/models/model/modelType';
import {Base} from '../../../models/base';

@Injectable({
    providedIn: 'root'
})

export class ModelService implements IModelService {

    public modelType: IBaseService<ModelType>;
    public modelBrand: IBaseService<ModelBrand>;
    api: string = environment.api;
    constructor(private http: HttpClient,
                private baseService: BaseService<Model>,
                private log: LogService,
                modelType: ModelTypeService,
                modelBrand: ModelBrandService)
    {
        this.modelType = modelType;
        this.modelBrand = modelBrand;
    }
    getPage(dataTablesParameters: any): Observable<DataTablesResponse> {
        return this.http.post<DataTablesResponse>(`${this.api}/models`, JSON.stringify(dataTablesParameters));
    }
    getAll(): Observable<Model[]> {
        throw new Error("Method not implemented.");
    }
    getOne(id: number): Observable<Model> {
        return this.baseService.getOne(id, "model");
    }
    add(item: Model): Observable<Model> {
        return this.baseService.add(item, "model");
    }
    update(item: Model) {
        return this.baseService.update(item, "model");
    }
    logXls(dataTablesParameters: any): Observable<any> {
        return this.log.postXls(dataTablesParameters, "modelsXls");
    }
    logXml(dataTablesParameters: any): Observable<any> {
        return this.log.postXml(dataTablesParameters, "modelsXml");
    }
}
