import { PointsAccountHistory } from './../../../models/users/pointsAccountHistory';
import { Component, Input, EventEmitter, OnDestroy } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { DatatablesServerComponent } from 'src/models/datatablesServer';
import { TitleItem } from 'src/models/titleItem';
import { TitleList } from 'src/models/titleList';
import { getColumnTitles } from 'src/utils/dt/columns';

@Component({
  selector: 'app-points-account-history',
  templateUrl: './points-account-history.component.html',
  styleUrls: ['./points-account-history.component.scss']
})
export class PointsAccountHistoryComponent extends DatatablesServerComponent<PointsAccountHistory> implements OnDestroy {

  columnsData: any[];
  columnNames: TitleItem[];
  apiCall: any;
  @Input() onUpdate: EventEmitter<any>
  filename: string = "История счета номер ";
  display: boolean = false;
  protected id: number;

  constructor(api: ApiService,
              services: ServicesService,
              private route: ActivatedRoute) {
    super(api, services);
    this.setInitValues();
  }

  setInitValues(): void {
    this.apiCall = this.api.user.pointsAccount.getHistoryPage;
    this.route.params.subscribe(params => {
      this.id = parseInt(params['id']);
    });
    this.filename = this.filename + this.id;
    this.pageLength = 5;
    this.columnNames = getColumnTitles([
      ['createdAt', 'input-calendar'], 'operationPoints', 'country', 'region', 'city',
      'tradingPlace', 'points', 'reasonForOperation', 'comment'
    ]);
  }

  ngOnInit(): void {
    this.onUpdate.subscribe(_ => this.update());
    this.setInit();
    this.dtOptions = {
      ...this.dtOptions,
      order: [[2, 'desc']]
    }
  }

  ngOnDestroy(): void {
    this.onUpdate.unsubscribe();
  }

  ajaxServer(dataTablesParameters: any, callback) {
    this.parameters = dataTablesParameters;
    this.api.user.pointsAccount.getHistoryPage(dataTablesParameters, this.id).subscribe(
      resp => {
        this.items = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: resp.data
        });
        this.rerender();
      }
    );
  }
  update() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }
  createColumns() {
    const that = this;
    let columns = [
      ...this.dtOptions.columns,
      {
        data: 'id',
        className: "col-hide"
      }
    ];
    const names = ['createdAt', 'points', 'country', 'region', 'city', 'tradingPlace',
      'userPoints', 'reason', 'comment'];
    const shift = 2;
    for (let i = shift; i < names.length + shift; i++) {
      const name = names[i - shift];
      const order = name !== 'comment';
      columns.push({
        name: i,
        data: name,
        orderable: order,
        render: function (data, type, row, meta) {
          if (name === 'createdAt') {
            return that.getUriDate(row.id, data);
          }
          else if (name === 'comment') {
            return that.getLongText(data);
          }
          return that.getUri(row.id, data, false);
        }
      });
    }
    return columns;
  }
  exportXLS() {
    this.services.log.getPointsAccountHistoryXls(this.parameters, this.id).subscribe(
      res => this.downloadFile(res, `${this.filename}.xls`),
      e => this.returnError(e)
    )
  }
  exportXML() {
    this.services.log.getPointsAccountHistoryXml(this.parameters, this.id).subscribe(
      res => this.downloadFile(res, `${this.filename}.xml`),
      e => this.returnError(e)
    )
  }
}
