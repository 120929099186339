import { Message } from 'src/models/message';
import { AuthComponent } from 'src/models/auth';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent extends AuthComponent {
  message: Message;
  constructor(formBuilder: FormBuilder, 
              api: ApiService, 
              services: ServicesService) {
    super(formBuilder, api, services);
    this.form = this.formBuilder.group({
      "password": new FormControl('', [Validators.required, Validators.minLength(4)]),
      "confirmPassword": new FormControl('', [Validators.required, Validators.minLength(4)])
    });
    this.message = new Message();
  }

  ngOnInit() {
  }
  catchError(res: any) {
    throw new Error("Method not implemented.");
  }
  submitValue() {
    this.api.auth.confirmPassword(this.form.controls['password'].value).subscribe(
      res => this.returnResult(res),
      error => this.catchError(error)
    );
  }
  returnResult(res: any) {
    this.message.alertSuccessFloat('info.message.confirmPassword');
    this.services.message.sendAlertMessageText(this.message);
    this.services.nav.redirectToLogin();
  }
  setValuesToObject(){
    let password = this.form.value.password;
    let confirmPassword = this.form.value.confirmPassword;
    if(password !== confirmPassword){
      this.form.get('confirmPassword').setErrors({notCorrect: true});
      this.onSubmit.emit();
      return;
    }
  };
  back(){
    this.services.nav.redirectToLogin();
  }
}
