import {Component, DoCheck, OnDestroy, OnInit} from '@angular/core';
import {EditorComponent} from '../../../models/editor';
import {Supervisor} from '../../../models/users/supervisor';
import {ServicesService} from '../../../services/services.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {SelectManyList} from '../../../models/form/selectManyList';
import {Base} from '../../../models/base';
import {forkJoin} from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-supervisor-edit',
  templateUrl: './supervisor-edit.component.html',
  styleUrls: ['./supervisor-edit.component.scss']
})
export class SupervisorEditComponent extends EditorComponent<Supervisor> {
  apiCall: any;
  selectManyList: SelectManyList;
  constructor(services: ServicesService,
              formBuilder: FormBuilder,
              api: ApiService,
              route: ActivatedRoute,
              translate: TranslateService) {
      super(services, route, formBuilder, api, translate);
      this.apiCall = api.supervisor;
      this.item = new Supervisor();
      this.form = this.formBuilder.group({
        'name' : new FormControl('', [Validators.required]),
        'email': new FormControl('', [Validators.email]),
        'phone' : new FormControl('', [Validators.pattern(/^(?:\+\d{10,18})$/)]),
        'regions': new FormControl(''),
      });
    this.selectManyList = new SelectManyList(['regions'], this.form);

  }

  ngOnInit() {
    this.setValues();
  }

  setValues() {
    let observable = null;
    if (this.id) {
      observable = forkJoin(
        this.api.supervisor.getAvailableRegionsAndAlreadyConnected(this.id),
        this.api.supervisor.getOne(this.id)
      );
    } else {
      observable = forkJoin(
        // this.api.geography.region.getAll()
        this.api.supervisor.getAvailableRegions()
      );
    }
    observable.subscribe(
      res => {
        this.selectManyList.setItems('regions', res[0]);
        this.InProgress = false;
        if (this.id) {
          const item = res[1];
          const regions = item.regions as Base[];
          this.item = item;
          this.form.patchValue({
            ...item,
            'regions': regions.map(region => region.id)
          });
        }
      },
      e => this.catchError(e)
    );
  }

  setValuesToObject(): void {
    const { promoRegions, ...valuesToSubmit } = this.form.value;
    this.item.regions = this.selectManyList.getSelect('regions').getActivesToSubmit();
    Object.assign(this.item, valuesToSubmit);
  }

  // setValuesToObject(): void {
  //   this.item.name = this.form.value.name;
  //   this.item.email = this.form.value.email;
  //   this.item.phone = this.form.value.phone;
  //   this.item.regions = this.form.value.regions;
  // }
  returnError(error: string) {
    if (error.indexOf(environment.serverError.alreadyExist) > -1) {
      this.form.controls['name'].setErrors({exist: true});
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error);
  }
  removed(value: any, name: string) {
    this.selectManyList.removed(value.value, name);
  }
  selected(value: any, name: string) {
    this.selectManyList.selected(value, name);
  }
}
