import { map } from 'rxjs/operators';
import { Login } from './../../models/login';
import { Injectable, EventEmitter } from "@angular/core";
import { Observable } from "rxjs";
import { IAuthService } from "../interfaces/IAuthService";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CurrentUser } from 'src/models/users/currentUser';

@Injectable({
    providedIn: 'root'
})

export class AuthService implements IAuthService {
    api: string;
    currentUser: CurrentUser;
    currentUserEvent: EventEmitter<CurrentUser> = new EventEmitter();
    emailReset: string;
    code: string;
    httpOptions: any = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded',
          
        }),
        withCredentials: true
      };
    constructor(private http: HttpClient)
    {
        this.api = environment.api;
        this.currentUser = new CurrentUser();
    }
    postLogin(login: Login): Observable<any>{
        let input = new FormData();
        input.append('email', login.email);
        input.append('password', login.password);
        input.append('remember', JSON.stringify(login.remember));
        
        return this.http.post(`${this.api}/login`, input, {observe: 'response'})
            .pipe(
                map(resp => { 
                    let token = resp.headers.get('adminsessionid');
                    localStorage.setItem('adminsessionid', token);
                    return resp.body;
                })
            );
    }

    getCurrentUserEvent(){
        return this.currentUserEvent;
    }
    setCurrentUserEvent(currentUser: CurrentUser){
        this.currentUser = currentUser;
        this.currentUserEvent.emit(currentUser);
    }
    getCurrentUserFromAPI(): Observable<any>{
        return this.http.get(`${this.api}/getcurrentuser`);
    }
    getCurrentUser(): CurrentUser{
        return this.currentUser;
    }
    
    logout(): Observable<any> {
        return this.http.get<any>(`${this.api}/logout`);
    }
    forgotPassword(email: string): Observable<any> {
        this.emailReset = email;
        return this.http.post<any>(`${this.api}/resetpassword`, JSON.stringify({email}));
    }
    verifivationCode(code: string): Observable<any> {
        this.code = code;
        return this.http.post<any>(`${this.api}/resetpasswordcheck`, JSON.stringify({email: this.emailReset, code}));
    }
    confirmPassword(password: string): Observable<any> {
        const res = {
            email: this.emailReset,
            code: this.code,
            password
        }
        return this.http.post<any>(`${this.api}/resetpasswordcheck`, JSON.stringify(res));
    }
}