import { ModelType } from './../model/modelType';
import { Category } from 'src/models/users/category';
import { TradingPlace } from 'src/models/tradingPlace/tradingPlace';
import { Admin } from './admin';
import { PointsAccount } from './pointsAccount';

export class User extends Admin{
    middleName: string;
    emailMessagingEnabled: boolean;
    phone: string;
    phoneMessagingEnabled: boolean;
    createdAt?: string;
    lastCheckDate?: string;
    postAddress: string;
    category: Category;
    pointsAccount?: PointsAccount;
    modelTypes: ModelType[];
    tradingPlace: TradingPlace;
}
