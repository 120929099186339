import { PromoModelBase } from './promoModelBase';
import { PromoModel } from "./promoModel";

export class PromoModelList{ 
    private submitModels: PromoModel[] = [];
    get SubmitModels(){
        return this.submitModels;
    }
    set SubmitModels(value: any[]){
        this.submitModels = value;
    }
    private isValid: boolean = true;
    get IsValid(){
        // this.isValidation();
        return this.isValid;
    }
    private length: number;
    set Length(value: any){
        this.length = parseInt(value);
    }
    get Length(){
        return this.length;
    }
    private isSelectAll: boolean = false;
    get IsSelectAll(){
        return this.isSelectAll;
    }
    set IsSelectAll(value: boolean){
        this.isSelectAll = value;
    }

    private error: string;
    get Error(){
        return this.error;
    }
    set Error(value: string){
        this.error = value;
    }
    private id: number|null;
    constructor(id: number|null){
        this.id = id;
    }
    setSubmitModelsEmpty(){
        this.submitModels = [];
    }
    getActivesToSubmit(){
        const models = this.submitModels.filter(item => item.enabled);
        return models.map(item => new PromoModelBase(item.id, item.points));
    }
    addSubmitModel(item: any){
        this.submitModels.push(item);
    }
    removeFromSubmit(id: number){
        this.submitModels = this.submitModels.filter(item => item.id !== id);
    }
    isValidation(){
        this.isValid = true;
        if(!this.submitModels.length){
            this.isValid = false;
            if(this.isSelectAll){
                this.isValid = false;
            }
            this.error = 'form.error.required.promoOneModel';
        }
        else if(this.isSelectAll && this.submitModels.length !== this.length){
            this.isValid = false;
        }
        else if(!this.isSelectAll){
            let hasOne = this.submitModels.find(item => item.enabled);
            if(!hasOne){
                this.isValid = !hasOne ? false : this.isValid;
                this.error = 'form.error.required.promoOneModel';
            }
            hasOne = this.submitModels.find(item => item.enabled && !Number.isInteger(item.points));
            if(hasOne){
                this.isValid = hasOne ? false : this.isValid;
                this.error = 'form.error.required.promoModels';
            }
        }
        else{
            const model = this.submitModels.find(item => item.enabled && !Number.isInteger(item.points));
            if(model)
                this.isValid = false; 
        }
    }
    validation(){
        const model = this.submitModels.find(item => item.enabled && !Number.isInteger(item.points));
        this.isValid = model ? false : true;
        
        if(this.isSelectAll && this.submitModels.length !== this.length){
            if(!this.id){
                this.error = 'form.error.required.promoModels';
                this.isValid = false;
            }
        }
        if(this.submitModels.length || (!this.submitModels.length && this.isSelectAll)){
            this.error = 'form.error.required.promoModels';
        }
        else{
            this.error = 'form.error.required.promoOneModel';
        }
    }
    allEnable(){
        this.submitModels.map(item => item.enabled = true);
    }
    allDisable(){
        this.submitModels.map(item => item.enabled = false);
    }
}