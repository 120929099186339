import { Component, OnInit, ViewChild, Input, EventEmitter, OnDestroy, ElementRef, Output } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { FormGroup, NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit,  OnDestroy {
  src: string;
  maxFileSize: number = 5000000;
  @Input() image: string;

  @ViewChild(FileUpload) fileUpload;
  @ViewChild('ngForm') _form: NgForm;
  @ViewChild('selectItem') selectItem: ElementRef;
  @Input() parentForm: FormGroup;
  @Input() onSubmit: EventEmitter<any>;

  @Output() onChangeImage = new EventEmitter<string|ArrayBuffer>();
  constructor() { 
  }

  ngOnInit() {
    this.onSubmit.subscribe(_ => {
      const { image } = this.parentForm.value;
      if(!image){
        this.parentForm.controls.image.setErrors({required: true});
      }
      this._form.ngSubmit.emit();
    });
    if(this.image){
      this.parentForm.controls.image.setValue(true);
      this.src = `${environment.api}/uploads/${this.image}`;
    }
  }
  ngOnDestroy(): void {
    this.onSubmit.unsubscribe();
  }
  uploadHandler(event: any){ 
    const file: File = event.files[0];
    if(file.size <= this.maxFileSize){
      this.parentForm.controls.image.setValue(true);
      this.parentForm.controls.image.setErrors(null);
      this.image = '';
      this.fileUpload.files = [file];
      const reader = new FileReader();
      reader.onloadend = _ => {
        this.onChangeImage.emit(reader.result);
      }
      reader.readAsDataURL(file);
    }
  }
  removeHandle(){
    this.parentForm.controls.image.setValue(false);
    this.parentForm.controls.image.setErrors({required: true});
    this.image = '';
    this.fileUpload.files = [];
  }
}
