import { Component, OnInit, Input } from '@angular/core';
import { NavigationService } from 'src/services/apiServices/allServices/navigation.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss']
})
export class CardFooterComponent {
  @Input() id: string;
  @Input() urlReturn: string;
  @Input() canSubmit = true;
  @Input() parentForm: FormGroup;
  constructor(private nav: NavigationService) { }

  goBack() {
    this.nav.redirectTo(this.urlReturn);
  }
}
