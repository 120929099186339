import { TitleItem } from './../../../models/titleItem';
import { Component, OnInit } from '@angular/core';
import { City } from 'src/models/geography/city';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { DatatablesServerComponent } from 'src/models/datatablesServer';
import { getColumnTitles } from 'src/utils/dt/columns';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent extends DatatablesServerComponent<City> {
  columnsData: any[];
  apiCall: any;
  columnNames: TitleItem[];
  filename: string;
  exportCol: number[];
  constructor(api: ApiService, 
              services: ServicesService) { 
    super(api, services);
    this.setInitValues();
  }

  setInitValues(): void {
    this.apiCall = this.api.geography.city;
    this.columnNames = getColumnTitles([
      'name.city', 'region','country'
    ]);
    this.columnsData = [
      ['name', this.urlReturn], 'region.name', 'region.country.name'
    ];
  }
  
  ngOnInit(){
    this.setInit();
    this.dtOptions.buttons = [
      this.filterButton
    ];
  }
}
