import { PromoModel } from './../../../models/activity/promoModel';
import { Component, Input, EventEmitter } from '@angular/core';
import { DatatablesServerComponent } from 'src/models/datatablesServer';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { IDataTableResponse } from 'src/services/interfaces/IDataTableResponse';
import { PromoModelList } from 'src/models/activity/promoModelList';
import { TitleItem } from 'src/models/titleItem';
import { getColumnTitles } from 'src/utils/dt/columns';

@Component({
  selector: 'app-activity-models',
  templateUrl: './activity-models.component.html',
  styleUrls: ['./activity-models.component.scss']
})
export class ActivityModelsComponent extends DatatablesServerComponent<PromoModel> {
  
  columnsData: any[];
  columnNames: TitleItem[];
  filename: string = 'Активность';
  apiCall: IDataTableResponse<PromoModel>;
  @Input() id: number;
  @Input() priority: number;
  @Input() eventChangePriority: EventEmitter<number>;
  @Input() promoModelList: PromoModelList;
  constructor(api: ApiService,
    services: ServicesService) {
    super(api, services);
    this.setInitValues();
  }
  
  setInitValues(): void {
    this.pageLength = 5;
    this.columnNames = getColumnTitles([
      ['name.model', 'w-75'], ['points', true], ['involvement', 'w-100', true]
    ]);
  }
  
  ngOnInit() {
    this.setInit();
    this.eventChangePriority.subscribe(id => this.changePriority(id));
    if (this.priority === -1 || this.priority === 0) {
      this.promoModelList.IsSelectAll = true;
    }
    const that = this;
    this.dtOptions = {
      ...this.dtOptions,
      select: false,
      order: [[1, 'asc']],
      buttons: [
        this.filterButton,
        {
          text: 'Отменить всё',
          key: '4',
          action: function (e, dt, node, config) {
            that.deselectAllModels();
          },
          className: "deselect-all-model"
        },
        {
          text: 'Выбрать всё',
          key: '3',
          action: function (e, dt, node, config) {
            that.selectAllModels();
          },
          className: "select-all-model"
        }
      ]
    }
    if (this.id !== -1) {
      this.dtOptions.buttons = [
        ...this.dtOptions.buttons,
        this.exportButtons = {
          extend: "collection",
          text: 'Экспорт активности',
          autoClose: true,
          buttons: [
            {
              text: 'XLS',
              key: '0',
              action: function (e, dt, node, config) {
                that.exportXLS();
              }
            },
            {
                text: 'XML',
                key: '1',
                action: function (e, dt, node, config) {
                    that.exportXML();
                }
            }
          ]
        }
      ]
    }
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.eventChangePriority.unsubscribe();
  };
  ajaxServer(dataTablesParameters: any, callback) {
    this.parameters = { ...dataTablesParameters };
    this.api.activity.getPageModels(dataTablesParameters, this.id).subscribe(resp => {
      this.items = resp.data;
      if (this.promoModelList.IsSelectAll) {
        this.items.map(item => item.enabled = true);
      }
      if (this.promoModelList.SubmitModels.length) {
        this.items.map(item => {
          let model = this.promoModelList.SubmitModels.find(m => m.id === item.id);
          if (model) {
            Object.assign(item, model);
            if (this.id !== -1) {
              Object.assign(model, item);
            }
          }
        })
      }
      this.promoModelList.Length = resp.recordsTotal;
      callback({
        recordsTotal: resp.recordsTotal,
        recordsFiltered: resp.recordsFiltered,
        data: resp.data
      });
      this.rerender();
    });
  }
  exportXLS(): void {
    this.services.log.getActivityXls(this.id).subscribe(
      res => this.downloadFile(res, `${this.filename}.xls`)
    )
  }
  exportXML(): void {
    this.services.log.getActivityXml(this.id).subscribe(
      res => this.downloadFile(res, `${this.filename}.xml`)
    )
  }
  createColumns(): any[] {
    const that = this;
    let columns: any = [
      {
        data: 'id',
        className: "col-hide"
      }
    ];
    const names = ['name', 'points', 'enabled'];
    const shift = 2;
    for (let i = shift; i < names.length + shift; i++) {
      const name = names[i - shift];
      let orderable = name === 'name';
      if (this.id !== -1) {
        orderable = true;
      }
      columns.push({
        name: i,
        data: names[i - shift],
        orderable,
        render: function (data, type, row, meta) {
          if (name === 'enabled') {
            return that.getCheckbox(row.id, data);
          }
          else if (name === 'points') {
            return that.getInput(row.id, data, row.enabled);
          }
          return that.getUri(row.id, data, false);
        },
        class: name !== 'name' ? "text-center" : ''
      });
    }
    return columns;
  }
  protected getCheckbox(id: number, data: boolean): string {
    const checked = data ? 'checked' : '';
    const disabled = this.priority === 0 ? 'disabled' : '';
    return `<div class="custom-control custom-switch ">
              <input type="checkbox" class="custom-control-input model-enabled" id="enabled-${id}" model-id="${id}" ${checked} ${disabled}>
              <label for="enabled-${id}" class="custom-control-label font-weight-bold"></label>
            </div>`;
  }
  protected getInput(id: number, data: number, enabled: boolean): string {
    let hasData = data || data === 0;
    return `<input name="points" type="number" class="form-control ${enabled && !hasData ? "input-invalid" : ''}" id="points-${id}" model-id="${id}" value="${hasData ? data : ''}" required>`;
  }
  selectAllModels() {
    this.promoModelList.IsSelectAll = true;
    // this.promoModelList.setSubmitModelsEmpty();
    this.items.map(item => {
      const checkbox = document.getElementById(`enabled-${item.id}`) as HTMLInputElement;
      checkbox.disabled = this.priority === 0;
      checkbox.checked = true;
      this.onChange(item.id);

      // const input = document.getElementById(`points-${item.id}`) as HTMLInputElement;
      // if (!input.value) {
      //   input.classList.add('input-invalid');
      // }
    });
    if (this.id !== -1) {
      this.promoModelList.allEnable();
    }
  }
  deselectAllModels() {
    this.promoModelList.IsSelectAll = false;
    // this.promoModelList.setSubmitModelsEmpty();
    this.items.map(item => {
      const checkbox = document.getElementById(`enabled-${item.id}`) as HTMLInputElement;
      checkbox.checked = false;
      checkbox.disabled = false;
      this.onChange(item.id);
      // const input = document.getElementById(`points-${item.id}`) as HTMLInputElement;
      // input.classList.remove('input-invalid');
    });
    if (this.id !== -1) {
      this.promoModelList.allDisable();
    }
  }
  rerender() {
    super.rerender();
    const that = this;
    this.datatableElement.dtInstance.then((dtInstance: any) => {
      if (that.priority === 0) {
        let selectAllModels = dtInstance.button(".select-all-model");
        selectAllModels.disable();
        let deselectAllModels = dtInstance.button(".deselect-all-model");
        deselectAllModels.disable();
        that.selectAllModels();
      }
      $('.model-enabled').change(function (event) {
        const id = event.target.getAttribute("model-id");
        that.onChange(parseInt(id));
      });
      $('input[type=number]').on('keyup mouseup', function (event) {
        const id = event.target.getAttribute("model-id");
        that.onChange(parseInt(id));
      });
    });
  }
  onChange(id: number) {
    const checkbox = document.getElementById(`enabled-${id}`) as HTMLInputElement;
    const input = document.getElementById(`points-${id}`) as HTMLInputElement;
    const points = parseInt(input.value);

    let model = this.items.find(item => item.id === id);
    model.enabled = checkbox.checked;
    const promoModel = this.promoModelList.SubmitModels.find(promo => promo.id === model.id);
    if (model.enabled || points || points === 0) {
      if (promoModel) {
        promoModel.enabled = model.enabled;
        promoModel.points = Number.isInteger(points) ? points : null;
      }
      else {
        model.points = Number.isInteger(points) ? points : null;
        this.promoModelList.addSubmitModel(model);
      }
    }
    else {
      if (promoModel) {
        this.promoModelList.removeFromSubmit(id);
      }
    }

    if (model.enabled) {
      if (!Number.isInteger(points)) {
        input.classList.add('input-invalid');
      }
      else {
        input.classList.remove('input-invalid');
      }
    }
    else {
      input.classList.remove('input-invalid');
    }
    this.promoModelList.validation();
  }
  protected selectAllRows(){}
  changePriority(id: number) {

    if (id === 0 && this.items) {
      this.selectAllModels();
    }
    else if (id > 0 && this.priority > 0) {

    }
    else if (id > 0 && this.items) {
      this.deselectAllModels();
    }
    this.priority = id;
    this.selectButtons(id);
    this.promoModelList.isValidation();
  }
  selectButtons(priority: number) {
    this.datatableElement.dtInstance.then((dtInstance: any) => {
      let selectAllModels = dtInstance.button(".select-all-model");
      let deselectAllModels = dtInstance.button(".deselect-all-model");
      if (priority === 0) {
        selectAllModels.disable();
        deselectAllModels.disable();
      }
      else {
        selectAllModels.enable();
        deselectAllModels.enable();
      }
    });
  }
}
