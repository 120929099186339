import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'switchTranslate'
})
export class SwitchTranslatePipe implements PipeTransform {
  transform(value: string, name: string): string {
    const path = 'info.text.';
    const translate: string[] = [];
    switch  (name) {
        case 'dialog':
            translate.push(path + 'yes');
            translate.push(path + 'no');
            break;
        case 'enabled':
            translate.push(path + 'active');
            translate.push(path + 'noActive');
            break;
        case 'input':
            translate.push(path + 'manual');
            translate.push(path + 'auto');
            break;
    }
    return value ? translate[0] : translate[1];
  }
}
