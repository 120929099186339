import { Injectable, EventEmitter } from '@angular/core';
import { ILoaderService } from 'src/services/interfaces/allServices/ILoaderService';

@Injectable({
  providedIn: 'root'
})
export class LoaderService implements ILoaderService{
  isShow: EventEmitter<boolean> = new EventEmitter();
  constructor() { }

  setIsShowEvent(isShow: boolean){
      this.isShow.emit(isShow);
  }
  getIsShowEvent(){
      return this.isShow;
  }
}
