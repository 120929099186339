import { environment } from "src/environments/environment";
import * as moment from 'moment';

export abstract class Util{
    public static getPathImage(uri:string): string{
        return `${environment.api}/uploads/${uri}`;
    }

    public static createHtml(html: string): string{
        let text = html.replace(/class="ql-align-center"/g, 'style="text-align: center"');
        text = text.replace(/class="ql-align-right"/g, 'style="text-align: right"');
        text = text.replace(/class="ql-align-justify"/g, 'style="text-align: justify"');
        return text;
    }
    public static getDate(data: Date, format?: string){
        if (!data) return "";
        const now = moment(data);
        return now.format(format || 'YYYY-MM-DD HH:mm');
    }
}
