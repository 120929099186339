import { ApiService } from 'src/services/api.service';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { NavigationService } from './apiServices/allServices/navigation.service';
import { CurrentUser } from 'src/models/users/currentUser';
import { environment } from 'src/environments/environment';


@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {
  store: Storage;
  constructor(private nav: NavigationService,
              private api: ApiService) {
    this.store = localStorage;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // import { Cookie } from 'ng2-cookies/ng2-cookies';

    // Cookie.setCookie('cookieName', 'cookieValue');
    // Cookie.setCookie('cookieName', 'cookieValue', 10 /*days from now*/);
    // Cookie.setCookie('cookieName', 'cookieValue', 10, '/myapp/', 'mydomain.com');
    
    // let myCookie = Cookie.getCookie('cookieName');
    
    // Cookie.deleteCookie('cookieName');
      let headers = new HttpHeaders();
      let token = localStorage.getItem('adminsessionid');
      if(token)
        headers = new HttpHeaders({'adminsessionid': token});
      req = req.clone({
          // withCredentials: true,
          headers: headers,
          
      });
      return next.handle(req)
      .pipe(
        catchError((error, caught) => {
          //intercept the respons error and displace it to the console
          console.log(error);
          this.handleAuthError(error);
          return of(error);
        }) as any
    );
    // if (
    //   req.url.includes(config.api.link) &&
    //   !req.url.includes('telephone_registration')
    // ) {
    //   return Observable.fromPromise(this.storage.get('apiToken')).switchMap(
    //     token => {
    //       let headers;
    //       if (req.url.includes('search')) {
    //         headers = new HttpHeaders({
    //           'Content-Type': 'application/json',
    //           Accept: 'application/json',
    //           'Dieselnet-api-token': token,
    //           'Dieselnet-secret': config.api.secret
    //         });
    //       } else {
    //         headers = new HttpHeaders({
    //           'Dieselnet-api-token': token,
    //           'Dieselnet-secret': config.api.secret
    //         });
    //       }
    //       const clone = req.clone({
    //         headers: headers
    //       });
    //       return next.handle(clone).catch((err, caught) => {
    //         if (err.status == 401) {
    //           this.events.publish('notAuthorized', true);
    //           this.navCtrl.setRoot(LoginPage).then(_ => {
    //             this.storage.clear();
    //           });
    //         }
    //         return Observable.throw(err);
    //       });
    //     }
    //   );
    // } else {
    //   if (req.url.includes('telephone_registration')) {
    //     const clone = req.clone({
    //       headers: new HttpHeaders({
    //         'Dieselnet-secret': config.api.secret
    //       })
    //     });
    //     return next.handle(clone).catch(err => {
    //       if (err.status == 401) {
    //         this.events.publish('notAuthorized', true);
    //         this.navCtrl.setRoot(LoginPage).then(_ => {
    //           this.storage.clear();
    //         });
    //       }
    //       return Observable.throw(err);
    //     });
    //   }
    // }

    // return next.handle(req).catch(error => {
    //   return Observable.throw(error);
    // }) as any;
  }

  /**
   * manage errors
   * @param err
   * @returns {any}
   */
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401) {
      //navigate /delete cookies or whatever
      const error = err.error.error;
      if(error === environment.serverError.wrongPassword || 
        error === environment.serverError.emailNotFound ||
        error === environment.serverError.notAuthorizedRole ||
        error === environment.serverError.delete)
      {
        throw err.error.error;
      }
      console.log('handled error ' + err.status);
      this.nav.redirectToLogin();
      this.api.auth.setCurrentUserEvent(new CurrentUser());
      localStorage.clear();
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return of(err.message);
    }
    throw err;
  }
//   get navCtrl(): NavController {
//     return this.app.getRootNav();
//   }
}
