import { Util } from "src/utils/util";

export class FormCalendar{
    private dateFrom: Date|undefined;
    get DateFrom(){
        return this.dateFrom;
    }
    set DateFrom(value: any){
        this.dateFrom = value;
        this.isValid = value <= this.dateTo;
    }
    private dateTo: Date|undefined;
    get DateTo(){
        return this.dateTo;
    }
    set DateTo(value: any){
        this.dateTo = value;
        this.isValid = value >= this.dateFrom;
    }
    isValid: boolean = false;
    constructor();
    constructor(dateFrom: Date, dateTo: Date);
    constructor(dateFrom?: any, dateTo?: any){
        this.setValue(dateFrom, dateTo);
    }
    setValue(dateFrom?: any, dateTo?: any){
        
        this.dateFrom = dateFrom || undefined;
        this.dateTo = dateTo || undefined;
        if(this.dateFrom &&  this.dateTo)
            this.isValid = this.dateFrom <= this.dateTo;
        else
            this.isValid = false;
    }
    toString(){
        const getDate = Util.getDate;
        const format = "YYYY-MM-DD";
        const dateFrom = getDate(this.dateFrom, format);
        const dateTo = getDate(this.dateTo, format);
        return dateFrom === dateTo ? dateTo : `${dateFrom}/${dateTo}`;
    }
}