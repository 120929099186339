import { SelectUser } from 'src/models/users/selectUser';
import { Component } from '@angular/core';
import { SelectUserBase } from 'src/models/form/selectUser';
import { ApiService } from 'src/services/api.service';

@Component({
  selector: 'app-select-admin',
  templateUrl: './select-admin.component.html',
  styleUrls: ['./select-admin.component.scss']
})
export class SelectAdminComponent extends SelectUserBase {
  constructor(api: ApiService) {
    super(api);
  }
  ngOnInit() {
    super.ngOnInit();
    this.api.user.admin.search().subscribe(
      users => this.setUsers(users as SelectUser[])
    );
  }
}
