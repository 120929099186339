import { Component } from '@angular/core';
import { PointsAccount } from 'src/models/users/pointsAccount';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { DatatablesServerComponent } from 'src/models/datatablesServer';
import { TitleItem } from 'src/models/titleItem';
import { TitleList } from 'src/models/titleList';
import { getColumnTitles } from 'src/utils/dt/columns';

@Component({
  selector: 'app-points-accounts',
  templateUrl: './points-accounts.component.html',
  styleUrls: ['./points-accounts.component.scss']
})
export class PointsAccountsComponent extends DatatablesServerComponent<PointsAccount> {
  columnsData: any[];
  columnNames: TitleItem[];
  apiCall: any;
  filename: string = 'Счета';
  constructor(api: ApiService, 
              services: ServicesService) {
    super(api, services);
    this.setInitValues();
  }

  setInitValues(){
    this.apiCall = this.api.user.pointsAccount;
    this.apiLog = this.api.user.pointsAccount;
    const names = [ 
      'id', 'lastName', 'firstName', 'middleName', 'country',
      'region', 'city', 'tradingPlace', 'points', 'spentPoints'
    ];
    this.columnNames = getColumnTitles(names);
    this.columnsData = [
      ['id', this.urlReturn], 'user.lastName', 'user.firstName', 'user.middleName', 
      'user.tradingPlace.city.region.country.name', 'user.tradingPlace.city.region.name', 
      'user.tradingPlace.city.name', 'user.tradingPlace.name', 'points', 'spentPoints'
    ];
  }

  ngOnInit(): void {
    this.setInit();
    this.dtOptions = {
      ...this.dtOptions,
      order: [[ 1, 'asc' ]],
    }
  }
}
