import {Injectable} from '@angular/core';
import {Supervisor} from '../../../models/users/supervisor';
import {BaseService} from '../base.service';
import {Observable} from 'rxjs';
import {ISupervisorService} from '../../interfaces/supervisorService/ISupervisorService';
import {Region} from '../../../models/geography/region';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {DataTablesResponse} from '../../../models/dataTablesResponse';
import {LogService} from '../allServices/log.service';

@Injectable({
  providedIn: 'root'
})
export class SupervisorService implements ISupervisorService {
  private api: string = environment.api;
  constructor(private http: HttpClient,
              private baseService: BaseService<Supervisor>,
              private log: LogService
  ) {}

  getAll(): Observable<Supervisor[]> {
    return this.baseService.getAll('supervisos-regions');
  }
  getAvailableRegions(): Observable<Region[]> {
    return this.http.get<Region[]>(`${this.api}/available-regions`);
  }
  getAvailableRegionsAndAlreadyConnected(supervisorId: number): Observable<Region[]> {
    return this.http.get<Region[]>(`${this.api}/available-regions/${supervisorId}`);
  }
  getOne(id: number): Observable<Supervisor> {
    return this.baseService.getOne(id, 'supervisor');
  }
  add(item: Supervisor): Observable<Supervisor> {
    return this.baseService.add(item, 'supervisor');
  }
  update(item: Supervisor): Observable<Supervisor> {
    return this.baseService.update(item, 'supervisor');
  }

  getPage(dataTablesParameters: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(`${this.api}/supervisors`, JSON.stringify(dataTablesParameters));
  }
  logXls(dataTablesParameters: any): Observable<any> {
    return this.log.postXls(dataTablesParameters, 'supervisorsXls');
  }
  logXml(dataTablesParameters: any): Observable<any> {
    return this.log.postXml(dataTablesParameters, 'supervisorsXml');
  }
}
