import { SelectUser } from 'src/models/users/selectUser';
import { ApiService } from './../../services/api.service';
import { Component, HostListener } from '@angular/core';
import { SelectUserBase } from 'src/models/form/selectUser';

@Component({
  selector: 'app-select-user',
  templateUrl: './select-user.component.html',
  styleUrls: ['./select-user.component.scss']
})
export class SelectUserComponent extends SelectUserBase {
  private length: number = 20;
  constructor(api: ApiService) {
    super(api);
  }

  ngOnInit() {
    super.ngOnInit();
    this.api.user.search(this.selectUser.email, this.length).subscribe(
      users => this.setUsers(users as SelectUser[])
    );
  }
  search(value: any) {
    this.searchValue(value.term);
  }
  clear() {
    this.searchValue();
    this.select.removed();
  }
  close() {
    if (!this.select.HasSelected) {
      this.searchValue();
    }
  }
  private searchValue(value: string = '') {
    this.api.user.search(value, this.length).subscribe(users => {
      this.select.setItems(users);
    });
  }
}
