import { ApiService } from 'src/services/api.service';
import { Component } from '@angular/core';
import { DatatablesComponent } from 'src/models/datatables';
import { ServicesService } from 'src/services/services.service';
import { TitleItem } from 'src/models/titleItem';
import {InstallerClientSMS} from '../../../models/settings/installerClientSMS';

@Component({
  selector: 'app-installer-clients-sms',
  templateUrl: './installer-clients-sms.component.html',
  styleUrls: ['./installer-clients-sms.component.scss']
})
export class InstallerClientsSmsComponent extends DatatablesComponent<InstallerClientSMS> {
  columnNames: TitleItem[];
  apiCall: any;
  constructor(protected services: ServicesService,
              protected api: ApiService) {
    super(api, services);
    this.apiCall = api.installerClientSMS;
    this.columnNames = [
      new TitleItem('name.installerClientSMS'),
      new TitleItem('message'),
      new TitleItem('models')
    ];
  }
}
