import { LogService } from './../allServices/log.service';
import { TradingPlaceTypeService } from './tradingPlaceType.service';
import { TradingPlace } from '../../../models/tradingPlace/tradingPlace';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { DataTablesResponse } from 'src/models/dataTablesResponse';
import { IBaseService } from 'src/services/interfaces/IBaseService';
import { TradingPlaceType } from 'src/models/tradingPlace/tradingPlaceType';
import { ITradingPlaceService } from 'src/services/interfaces/ITradingPlaceService/ITradingPlaceService';
import { BaseService } from '../base.service';
import { IExportService } from 'src/services/interfaces/IExportService';

@Injectable({
    providedIn: 'root'
})

export class TradingPlaceService implements ITradingPlaceService {
    api: string = environment.api;
    type: IBaseService<TradingPlaceType>;
    baseService: BaseService<TradingPlace>;
    constructor(private http: HttpClient,
                private log: LogService,
                type: TradingPlaceTypeService)
    {
        this.type = type;
        this.baseService = new BaseService<TradingPlace>(http);
    }
    getAll(): Observable<TradingPlace[]> {
        throw new Error("Method not implemented.");
    }
    getPage(dataTablesParameters: any): Observable<DataTablesResponse> {
        return this.http.post<DataTablesResponse>(`${this.api}/alltradingplaces`, JSON.stringify(dataTablesParameters));
    }
    getOne(id: number): Observable<TradingPlace> {
        return this.baseService.getOne(id, "admintradingplace");
    }
    getOneByCityAndRegion(idCity: number, idRegion: number): Observable<TradingPlace[]> {
        return this.http.get<TradingPlace[]>(`${this.api}/tradingplaces/${idCity}/${idRegion}`);
    }
    add(item: TradingPlace): Observable<TradingPlace> {
        return this.baseService.add(item, "admintradingplace");
    }
    update(item: TradingPlace): Observable<TradingPlace> {
        return this.baseService.update(item, "admintradingplace");
    }
   
    getImage(id: number): Observable<any> {
        let getfileheaders = new HttpHeaders().set('Accept', 'image/jpeg');
        return this.http.get(`${this.api}/getimagetradingplace/${id}`, { responseType: 'blob' as 'json', headers: getfileheaders });
    }
    getZip(idTradingPlace: number): Observable<any> {
        let getfileheaders = new HttpHeaders().set('Accept', 'application/zip');
        return this.http.get(`${this.api}/getziptradingplace/${idTradingPlace}`, { responseType: 'blob' as 'json', headers: getfileheaders });
    }
    deleteImage(id: number): Observable<any> {
        return this.http.delete(`${this.api}/deleteimagetradingplace/${id}`);
    }
    logXls(dataTablesParameters: any): Observable<any> {
        return this.log.postXls(dataTablesParameters, "tradingPlacesXls");
    }
    logXml(dataTablesParameters: any): Observable<any> {
        return this.log.postXml(dataTablesParameters, "tradingPlacesXml");
    }
}