import { Select } from "./select";
import { FormGroup } from "@angular/forms";
import { Input, EventEmitter } from "@angular/core";

export class SelectList {
    isInit: boolean = true;
    select: Array<Select> = [];
    private isCascade: boolean;
    private form: FormGroup;
    private listItems: string[];
    private selectItemsEvent: EventEmitter<SelectList>;
    constructor(items: string[], form: FormGroup, selectItemsEvent: EventEmitter<SelectList>) {
        this.form = form;
        this.isCascade = items.length > 1;
        this.listItems = items;
        items.map(name => {
            this.select[name] = new Select();
            this.select[name].onIsDisabled.subscribe(isDisabled => this.onIsDisabled(name, isDisabled));
        });
        this.selectItemsEvent = selectItemsEvent;
    }
    onIsDisabled(name: string, isDisabled: boolean) {
        const control = this.form.controls[name];
        isDisabled ? control.disable() : control.enable();
    }
    getSelect(name: string): Select {
        return this.select[name];
    }
    setItems(name: string, values: any);
    setItems(name: string, values: any, defaultValue: number);
    setItems(name: string, values: any, defaultValue: string);
    setItems(name: string, values: any, defaultValue?: any) {
        const selectItem = this.getSelect(name);
        selectItem.setItems(values);
        if (defaultValue) {
            selectItem.setActive(defaultValue);
        }
        if (selectItem.HasSelected) {
            this.form.controls[name].setValue(selectItem.Active.id);
        }
        this.selectItemsEvent.emit(this);
    }

    AllDisabledBeginFrom(name: string) {
        if (this.isCascade) {
            let index = this.findIndexItem(name);
            if (index < 0) return;
            let newItems = this.listItems.slice(index);
            newItems.map(item => {
                let select = this.select[item] as Select;
                select.removed();
                select.IsDisabled = true;
                this.form.controls[item].setValue([]);
            });
        }
    }
    AllDisabledNextFrom(name: string) {
        if (this.isCascade) {
            let index = this.findIndexItem(name);
            if (index < 0) return;
            if (index <= this.listItems.length - 1) index++;
            let newItems = this.listItems.slice(index);
            newItems.map(item => {
                let select = this.select[item] as Select;
                select.removed();
                select.setItems([]);
                select.IsDisabled = true;
                select.HasValues = false;
                this.form.controls[item].setValue([]);
            });
        }
    }

    findIndexItem(name: string) {
        return this.listItems.findIndex(value => value === name);
    }

    selected(value: any, name: string) {
        this.select[name].selected(value.id);
        this.isInit = false;
        this.AllDisabledNextFrom(name);
        this.selectItemsEvent.emit(this);
    }

    removed(name: string) {
        this.select[name].removed();
        this.form.controls[name].setValue(null);
        this.isInit = false;
        this.AllDisabledNextFrom(name);
        this.selectItemsEvent.emit(this);
    }

}