import { LogHistory } from './logHistory';
import { CurrentUser } from 'src/models/users/currentUser';
import { ServicesService } from 'src/services/services.service';
import { OnInit, EventEmitter, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, NgForm } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageManager } from './messageManager';
import * as moment from 'moment';

export abstract class EditorComponent<T> extends MessageManager implements OnInit{
    private inProgress: boolean = true;
    set InProgress(value: boolean){
        this.inProgress = value;
        this.services.loader.setIsShowEvent(value);
    }
    get InProgress(){
        return this.inProgress;
    }
    public form: FormGroup;
    protected id: number;
    protected currentUser: CurrentUser;
    public item: T;
    protected logHistory: LogHistory;
    protected onSubmit: EventEmitter<any> = new EventEmitter<any>(); 
    abstract apiCall: any;
    @ViewChild('ngForm') _form: NgForm;
    protected urlReturn: string;
    abstract setValuesToObject(): void;
    abstract returnError(error: any);
    addLogHistory(){};

    constructor(protected services: ServicesService,
                protected route: ActivatedRoute,
                protected formBuilder: FormBuilder,
                protected api: ApiService,
                protected translate: TranslateService)
    {
        super(services);
        this.route.params.subscribe( params => {
            this.id = parseInt(params['id']);
        });
        this.urlReturn = this.services.nav.getSubmenu().url;
        this.currentUser = this.api.auth.getCurrentUser();
        this.services.nav.setItemNotFound(false);
    }
    ngOnInit() {
        this.setDefaultValues();
        if(!this.id){
            this.InProgress = false;
        }
        else {
            this.setValues();
        }
      this.logHistory = new LogHistory(this.id, this.currentUser.id, this.translate);

    }
    setDefaultValues(){};
    setValues(): void {
        this.apiCall.getOne(this.id).subscribe(
            item => {
                this.item = item;
                this.setPatchValue();
                this.InProgress = false;
            },
            error => this.catchError(error)
        );
    }
    setPatchValue(){
        this.form.patchValue(this.item);
    }
    submit(): void{
        this.setValuesToObject();
        if(this.form.valid){
            if(this.id)
                this.apiUpdate();
            else
                this.apiAdd();
        }
    }
    apiAdd(): void {
        this.apiCall.add(this.item).subscribe(
            res => this.returnResult(res, this.message.messageEvent.add),
            error => this.catchError(error)
        )
    }
    apiUpdate(): void {
        this.apiCall.update(this.item).subscribe(
            res => this.returnResult(res, this.message.messageEvent.edit),
            error => this.catchError(error)
        )
    }
    catchError(error: any){
        if(error.status && error.status === 404){
            this.services.nav.setItemNotFound(true);
            this.InProgress = false;
        }
        else if(error.message)
            this.sendAlert(this.message.messageColor.error, error.message);
        else
            this.sendAlert(this.message.messageColor.error, error); 
    }
    getUriDate(data: string|Date): string {
        if(!data) return "";
        // const now = moment.utc(data).local();
        const now = moment(data);
        return now.format('YYYY-MM-DD HH:mm');
    }
    
}
