import { City } from 'src/models/geography/city';
import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { EditorComponent } from 'src/models/editor';
import { ISelect } from 'src/interfaces/ISelect';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { SelectList } from 'src/models/form/selectList';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'src/models/form/selectItem';
import { Country } from 'src/models/geography/country';
import { Region } from 'src/models/geography/region';
import { SelectOptions } from 'src/models/form/selectOptions';

@Component({
  selector: 'app-city-editor',
  templateUrl: './city-editor.component.html',
  styleUrls: ['./city-editor.component.scss']
})
export class CityEditorComponent extends EditorComponent<City> implements ISelect, OnDestroy {
  apiCall: any;
  selectOptions: SelectOptions;
  constructor(services: ServicesService, 
              route: ActivatedRoute, 
              formBuilder: FormBuilder, 
              api: ApiService,
              translate: TranslateService) { 
    super(services, route, formBuilder, api, translate);
    this.apiCall = api.geography.city;
    this.item = new City();
    this.form = this.formBuilder.group({
      "name" : new FormControl('', [Validators.required]),
      "region" : new FormControl(null, [Validators.required]),
      "country" : new FormControl(null, [Validators.required])
    });
    this.selectOptions = new SelectOptions(['country', 'region']);
    this.selectOptions.selectItemsEvent.subscribe(selectList => this.selectItems(selectList));
  }
  setPatchValue(){
    this.form.patchValue({
      ...this.item,
      "region": this.item.region,
      "country": this.item.region.country
    });
  }
  selectItems(selectList: SelectList) {
    const selectCountry = selectList.getSelect('country');
    const selectRegion = selectList.getSelect('region');

    if(!selectCountry.HasValues){
      this.api.geography.country.getAll().subscribe(countries => {
        if(this.id && selectList.isInit){
          selectList.setItems('country', countries, this.item.region.country.id);
          selectCountry.IsDisabled = true;
        }
        else
          selectList.setItems('country', countries);
      });
    }
    if(selectCountry.HasSelected && !selectRegion.HasValues){
      this.api.geography.region.getRegions(selectCountry.Active.id).subscribe(regions => {
        if(this.id && selectList.isInit){
          selectList.setItems('region', regions, this.item.region.id);
          selectRegion.IsDisabled = true;
        }
        else
          selectList.setItems('region', regions);
      });
    }
  }
  setValuesToObject(): void {
    const {name, region, country} = this.form.value;
    this.item.name = name;
    if (region) {
      this.item.region = new Region(region);
    }
    if (country) {
      this.item.region.country = new Country(country);
    }
    this.onSubmit.emit();
  }
  returnError(error: string) {
    if(error.indexOf(environment.serverError.alreadyExist) > -1){
      this.form.controls['name'].setErrors({exist: true});
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error);
  }
  ngOnDestroy(): void {
    this.selectOptions.selectItemsEvent.unsubscribe();
  }
}
