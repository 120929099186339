import { UserRoleEnum } from './../../../models/users/userRoleEnum';
import { ApiService } from './../../../services/api.service';
import { Component, DoCheck } from '@angular/core';
import { Admin } from 'src/models/users/admin';
import { EditorComponent } from 'src/models/editor';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ServicesService } from 'src/services/services.service';
import { TranslateService } from '@ngx-translate/core';
import { isNumber } from 'util';

@Component({
  selector: 'app-admin-editor',
  templateUrl: './admin-editor.component.html',
  styleUrls: ['./admin-editor.component.scss']
})
export class AdminEditorComponent extends EditorComponent<Admin> implements DoCheck {
  localId: number;
  apiCall: any;
  userRoleEnum = UserRoleEnum;
  constructor(services: ServicesService,
              formBuilder: FormBuilder,
              api: ApiService,
              route: ActivatedRoute,
              translate: TranslateService) { 
    super(services, route, formBuilder, api, translate);
    this.form = this.formBuilder.group({
      "email": new FormControl('', [Validators.required, Validators.email]),
      "lastName" : new FormControl('', [Validators.required]),
      "firstName" : new FormControl('', [Validators.required]),
      "password" : new FormControl(''),
      "confirmPassword" : new FormControl(''),
      "enabled":  new FormControl(true)
    });
    this.item = new Admin();
    this.apiCall = api.user.admin;
    const prevUri = this.services.nav.getPreviousUrl();
    const isAllow = this.services.nav.isAccessAllowedByUrl('/users/admins', this.currentUser);
    if(prevUri === '/login'){
      if(isAllow)
        this.urlReturn = '/users/admins';
      else
        this.urlReturn = '/users/users';
    }
    else{
        this.urlReturn = prevUri;
    }
    
    if(!this.currentUser.isSuperAdmin){
      this.item.userRole = this.userRoleEnum.superAdmin;
    }

    if(this.id){
      this.form.controls['password'].setValidators([Validators.minLength(4)]);
      this.form.controls['confirmPassword'].setValidators([Validators.minLength(4)]);
      this.localId = this.id;
    } else {
      this.form.controls['password'].setValidators([Validators.required, Validators.minLength(4)]);
      this.form.controls['confirmPassword'].setValidators([Validators.required, Validators.minLength(4)]);
      this.item.userRole = this.userRoleEnum.admin;
    }
    
  }
  ngDoCheck(): void {
    if(this.id && this.localId !== this.id){
      this.setValues();
      this.localId = this.id;
    }
  }
  setPatchValue(){
    this.form.patchValue(this.item);
    if(!this.currentUser.isSuperAdmin){
      this.item.userRole = this.userRoleEnum.superAdmin;
    }
  }
  setValuesToObject(): void {
    this.onSubmit.emit();
    const { password, confirmPassword, ...valuesToSubmit } = this.form.value;
    if(password !== confirmPassword){
      this.form.get('confirmPassword').setErrors({notCorrect: true});
      this.onSubmit.emit();
      return;
    }
    if(password){
      valuesToSubmit.password = password;
    }
    Object.assign(this.item, valuesToSubmit);
    delete this.item.userRole;
  }
  returnError(error: any) {
    if(error.email){
      this.form.controls['email'].setErrors({exist: true});
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error);
  }
}
