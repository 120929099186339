import { SelectItem } from "./selectItem";
import { EventEmitter } from "@angular/core";

export class Select {
    private items: Array<SelectItem> = [];


    setItems(values: any) {
        this.items = [];
        // values.map(item => this.items.push(new SelectItem(item.id, item.name)));
        this.items = values.map(item => new SelectItem(item.id, item.name));
        this.IsDisabled = false;
        this.HasValues = true;
    }
    get Items() {
        return this.items;
    }

    setActive(value: number);
    setActive(value: string);
    setActive(value: any) {
        if (this.items.length > 0) {
            if (typeof value == 'number' && value > -1)
                this.active = this.items.find(item => item.id === value);
            else if (typeof value == 'string' && value)
                this.active = this.items.find(item => item.text === value);

            if (this.active)
                this.hasSelected = true;
        }
    }

    private active: SelectItem;
    get Active() {
        return this.active;
    }
    private isDisabled: boolean = true;
    public onIsDisabled = new EventEmitter<boolean>(false);
    set IsDisabled(value: boolean) {
        this.isDisabled = value;
        this.onIsDisabled.emit(value);
    }
    get IsDisabled() {
        return this.isDisabled;
    }

    private hasValues: boolean = false;
    set HasValues(value: boolean) {
        this.hasValues = value;
    }
    get HasValues() {
        return this.hasValues;
    }

    private hasSelected: boolean = false;
    set HasSelected(value: boolean) {
        this.hasSelected = value;
    }
    get HasSelected() {
        return this.hasSelected;
    }
    constructor() {
    }

    selected(id: number) {
        this.setActive(id);
    }
    removed() {
        this.active = null;
        this.HasSelected = false;
    }
}