import { Model } from './../../../models/model/model';
import { Component } from '@angular/core';
import { EditorComponent } from 'src/models/editor';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ModelType } from 'src/models/model/modelType';
import { ModelBrand } from 'src/models/model/modelBrand';

@Component({
  selector: 'app-model-editor',
  templateUrl: './model-editor.component.html',
  styleUrls: ['./model-editor.component.scss']
})
export class ModelEditorComponent extends EditorComponent<Model> {
  apiCall: any;
  modelTypes: Array<ModelType> = [];
  modelBrands: Array<ModelBrand> = [];
  logisticCode: Array<any> = [];
  constructor(services: ServicesService,
              route: ActivatedRoute,
              formBuilder: FormBuilder,
              api: ApiService,
              translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.apiCall = api.model;
    this.item = new Model();
    this.form = this.formBuilder.group({
      "name" : new FormControl('', [Validators.required]),
      "manufactureNumber" : new FormControl('', [Validators.required]),
      // "logisticCodes" : new FormControl([], [Validators.required]),
      "modelType" : new FormControl('', [Validators.required]),
      "modelBrand" : new FormControl('', [Validators.required]),
      "description" : new FormControl(''),
      "image" : new FormControl(false),
      "enabled" : new FormControl(true),
      "hidden" : new FormControl(false)
    });
    if(!this.id){
      this.form.addControl('points', new FormControl('', [Validators.required]));
    }
  }
  setPatchValue(){
    // this.setLogisticCodes(this.item.logisticCodes);
    this.form.patchValue({
      ...this.item,
      // "logisticCodes": this.item.logisticCodes.map(value => ({text: value}))
    });
    this.form.controls['modelType'].setValue(this.item.modelType.id);
    this.form.controls['modelBrand'].setValue(this.item.modelBrand.id);
  }

  setDefaultValues(){
    // this.setLogisticCodes();
    this.api.model.modelBrand.getAll().subscribe(data => {
      this.modelBrands = data;
      this.form.controls['modelBrand'].setValue(this.modelBrands[0].id);
    });
    this.api.model.modelType.getAll().subscribe(data => {
      this.modelTypes = data;
      this.form.controls['modelType'].setValue(this.modelTypes[0].id);
    });

  }
  // setLogisticCodes(defaultCodes: any[] = []){
  //   this.logisticCode = [];
  //   for (let i = 0; i < 100; i++) {
  //     this.logisticCode.push(i > 9 ? i.toString() : `0${i}`);
  //   }
  //   if(defaultCodes.length){
  //     this.logisticCode = this.logisticCode.filter(value => {
  //       if(defaultCodes.indexOf(value) === -1)
  //         return value;
  //     });
  //   }
  // }
  setValuesToObject(): void {
    const { image, modelType, modelBrand, ...valuesToSubmit } = this.form.value;
    this.onSubmit.emit();
    // this.item.logisticCodes = logisticCodes.map(value => value.text);
    this.item.modelType = new ModelType(modelType);
    this.item.modelBrand = new ModelBrand(modelBrand);
    Object.assign(this.item, valuesToSubmit);
  }
  returnError(error: any) {
    let isReturn = false;
    if(error.name){
        this.form.controls['name'].setErrors({exist: true});
        isReturn = true;
    }
    if(error.manufactureNumber){
        this.form.controls['manufactureNumber'].setErrors({exist: true});
        isReturn = true;
    }

    if(isReturn) {
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error);
  }
  onChangeImage(file: string|ArrayBuffer){
    this.item.file = file;
  }
  // removed(item: any){
  //   let values = this.form.value.logisticCodes;
  //   values = values.filter(value => value.text !== item.text);
  //   this.form.controls['logisticCodes'].setValue(values);
  //   this.setLogisticCodes(values.map(value => parseInt(value.text)));
  // }
}
