import { IBaseService } from './../../interfaces/IBaseService';
import { Injectable } from "@angular/core";
import { TradingPlaceType } from "src/models/tradingPlace/tradingPlaceType";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { BaseService } from '../base.service';

@Injectable({
    providedIn: 'root'
})

export class TradingPlaceTypeService implements IBaseService<TradingPlaceType> {
    constructor(private baseService: BaseService<TradingPlaceType>) 
    { }
    
    getAll(): Observable<TradingPlaceType[]> {
        return this.baseService.getAll('tradingplacetypes');
    }
    getOne(id: number): Observable<TradingPlaceType> {
        return this.baseService.getOne(id, 'typetradingplace');
    }
    add(item: TradingPlaceType): Observable<TradingPlaceType> {
        return this.baseService.add(item, 'typetradingplace');
    }
    update(item: TradingPlaceType) {
        return this.baseService.update(item, 'typetradingplace');
    }
}