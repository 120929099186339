import { library } from '@fortawesome/fontawesome-svg-core'
import * as SolidIcons from '@fortawesome/free-solid-svg-icons'
import * as RegularIcons from '@fortawesome/free-brands-svg-icons'
import * as Brands from '@fortawesome/free-brands-svg-icons'


library.add(SolidIcons.faUser);
library.add(SolidIcons.faLock);
library.add(SolidIcons.faAngleDoubleRight);
library.add(SolidIcons.faAngleLeft);
library.add(SolidIcons.faGlobeEurope);
library.add(SolidIcons.faBuilding);
library.add(SolidIcons.faUsers);
library.add(SolidIcons.faShoppingCart);
library.add(SolidIcons.faCalendarAlt);
library.add(SolidIcons.faBalanceScale);
library.add(SolidIcons.faGift);
library.add(SolidIcons.faCog);
library.add(SolidIcons.faBars);
library.add(SolidIcons.faPlusCircle);
library.add(SolidIcons.faEye);
library.add(SolidIcons.faEyeSlash);
library.add(SolidIcons.faFilter);
library.add(SolidIcons.faSignOutAlt);
library.add(SolidIcons.faHome);