import { DatatablesComponent } from 'src/models/datatables';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { TradingPlaceType } from 'src/models/tradingPlace/tradingPlaceType';
import { TitleItem } from 'src/models/titleItem';

@Component({
  selector: 'app-trading-place-types',
  templateUrl: './trading-place-types.component.html',
  styleUrls: ['./trading-place-types.component.scss']
})
export class TradingPlaceTypesComponent extends DatatablesComponent<TradingPlaceType> {
  columnNames: TitleItem[];
  apiCall: any;
  constructor(api: ApiService, 
              services: ServicesService) { 
    super(api, services);
    this.apiCall = api.tradingPlace.type;
    this.columnNames = [
      new TitleItem('name.tradingPlaceType')
    ];
  }
}
