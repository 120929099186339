import { IRuleService } from '../../interfaces/settingsService/IRuleService';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Rule } from 'src/models/settings/rule';

@Injectable({
  providedIn: 'root'
})

export class CommissionCppdService implements IRuleService {
  api: string = environment.api;
  constructor(private http: HttpClient) {}

  getOne(): Observable<Rule> {
    return this.http.get<Rule>(`${this.api}/commission-cppd`);
  }
  update(value: string): Observable<Rule> {
    return this.http.put<Rule>(`${this.api}/update-commission-cppd`, JSON.stringify({value}));
  }

}
