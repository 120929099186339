import { FormCalendar } from './../../../models/message/formCalendar';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'table-search-calendar',
  templateUrl: './table-search-calendar.component.html',
  styleUrls: ['./table-search-calendar.component.scss']
})
export class TableSearchCalendarComponent implements OnInit {
  display: boolean = false;
  @Output() onSubmit = new EventEmitter<string>();
  formCalendar: FormCalendar;
  ru: any;
  constructor() {
    this.ru = environment.ru;
    this.formCalendar = new FormCalendar();
  }

  ngOnInit() {
  }
  open() {
    this.display = true;
    if(!this.formCalendar.DateFrom || !this.formCalendar.DateTo){
      this.formCalendar.setValue(new Date(), new Date());
    }
  }
  hide() {
    this.display = false;
    this.formCalendar.setValue();
  }
  setValue(date: string){
    const parts = date.split('/');
    if(parts.length === 1)
      this.formCalendar.setValue(new Date(parts[0]), new Date(parts[0]));
    else if(parts.length === 2)
      this.formCalendar.setValue(new Date(parts[0]), new Date(parts[1]));
  }
  clear(){
    this.onSubmit.emit('');
    this.hide();
  }
  submit(){
    this.onSubmit.emit(this.formCalendar.toString());
    this.hide();
  }
}
