import { Component, OnInit, Input, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';

@Component({
  selector: 'form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit, OnDestroy {
  @Input() name: string;
  @Input() translate: string;
  @Input() parentForm: FormGroup;

  @Input() type: string = 'text';
  @Input() isRequired: boolean = true;
  @ViewChild('ngForm') _form: NgForm;
  @Input() onSubmit: EventEmitter<any>;
  
  constructor() { }

  ngOnInit(): void {
    this.onSubmit.subscribe(_ => this._form.ngSubmit.emit());
  }

  ngOnDestroy(): void {
      this.onSubmit.unsubscribe();
  }

}
