import { Component, OnInit, OnChanges } from '@angular/core';
import { MessageType } from 'src/enum/message/type';

@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss']
})
export class BasePageComponent implements OnInit {
  protected messageType = MessageType;
  constructor() { }

  ngOnInit() {
  }
  
}
