import { Activity } from './../../../models/activity/activity';
import { Component } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { DatatablesServerComponent } from 'src/models/datatablesServer';
import * as moment from 'moment';
import { TitleItem } from 'src/models/titleItem';
import { getColumnTitles } from 'src/utils/dt/columns';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent extends DatatablesServerComponent<Activity> {
  columnsData: any[];
  columnNames: TitleItem[];
  filename: string = "Активности";
  apiCall: any;
  constructor(api: ApiService, 
              services: ServicesService) { 
    super(api, services);
    this.setInitValues();
  }
  setInitValues(): void {
    this.apiCall = this.api.activity;
    this.apiLog = this.api.activity;
    this.pageLength = 5;
    this.columnNames = getColumnTitles([
      "name.activity", ["priority", 'w100'], ["dateFrom", 'input-calendar'], ["dateTo", 'input-calendar'], "promoCategory",
      "promoRegions", "promoModels"
    ]);
    const dtType = this.dtType;
    this.columnsData = [
      ["name", this.urlReturn], 'priority', ['dateFrom', dtType.date], 
      ['dateTo', dtType.date], ['promoCategory', dtType.array], 
      ['promoRegions', dtType.array], ['promoModels', dtType.array]
    ];
  }
}
