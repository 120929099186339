import { PointsAccountHistory } from './../../../models/users/pointsAccountHistory';
import { Component, EventEmitter } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EditorComponent } from 'src/models/editor';
import { TranslateService } from '@ngx-translate/core';
import { PointsAccount } from 'src/models/users/pointsAccount';

@Component({
  selector: 'app-points-account-editor',
  templateUrl: './points-account-editor.component.html',
  styleUrls: ['./points-account-editor.component.scss']
})
export class PointsAccountEditorComponent extends EditorComponent<PointsAccount> {
  apiCall: any;
  display: boolean = false;
  get Form(){
    return this.form;
  }
  private history: PointsAccountHistory;
  protected onUpdate: EventEmitter<any> = new EventEmitter<any>(); 
  constructor(services: ServicesService, 
              route: ActivatedRoute, 
              formBuilder: FormBuilder, 
              api: ApiService, 
              translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.form = formBuilder.group({
      "points": new FormControl('', [Validators.required]),
      "comment": new FormControl('', [Validators.required]),
      "isAdd": new FormControl(true)
    });
    this.history = new PointsAccountHistory();
  }
  setValues(): void {
    this.api.user.pointsAccount.getOne(this.id).subscribe(
      item => {
        this.item = item;
        this.InProgress = false;
      },
      error => this.catchError(error)
    );
  }
  setValuesToObject(): void {
    const {isAdd, points, comment} = this.form.value;
    let hasError = false;
    if(!points.trim()){
      this.form.controls['points'].setErrors({required: true});
      hasError = true;
    }
    if(!comment.trim()){
      this.form.controls['comment'].setErrors({required: true});
      hasError = true;
    } 
    if(hasError) return;
    this.history.comment = comment;
    this.history.pointsAccountId = this.item.id;
    this.history.points = isAdd ? Math.abs(points) : -Math.abs(points);
  }
  apiAdd(): void {
    throw new Error("Method not implemented.");
  }
  apiUpdate(): void {
    this.services.log.savePointAccountHistory(this.history).subscribe(
      res => {
        this.display = false;
        this.form.patchValue({"points": ' ', "comment": ' '});
        this.sendSuccessAlert('info.message.addOrWriteOffPoints');
        this.onUpdate.emit();
      },
      error => this.catchError(error)
    )
  }
  returnError(error: any) {
    throw new Error("Method not implemented.");
  }
 
  goBack(){
    this.services.nav.redirectTo(this.urlReturn);
  }
  
  addPoints(){
    this.form.controls['isAdd'].setValue(true);
    this._form.ngSubmit.emit();
  }
  writeOffPoints(){
    this.form.controls['isAdd'].setValue(false);
    this._form.ngSubmit.emit();
  }
 
  showDialog(){
    this.display = true;
  }
  cancel(){
    this.display = false;
  }
}
