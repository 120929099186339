import { ModelType } from './modelType';
import { ModelBrand } from './modelBrand';
import { Base } from "../base";

export class Model extends Base {
    manufactureNumber: string;
    description: string;
    image: string;
    file?: string|ArrayBuffer;
    modelBrand: ModelBrand;
    modelType: ModelType;
    // logisticCodes: string[];
    points?: number;
    enabled: boolean;
    hidden: boolean;
}
