import { ApiService } from './../services/api.service';
import { CurrentUser } from '../models/users/currentUser';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/services/apiServices/allServices/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'atlantic-profit-web-interface';
  currentUser: CurrentUser;
  constructor(translate: TranslateService,
              private api: ApiService,
              private nav: NavigationService) {
    translate.setDefaultLang('ru');
    translate.use('ru');
    this.nav.loadRouting();
  }
  ngOnInit(): void {
    this.api.auth.getCurrentUserEvent().subscribe(currentUser => {
      this.currentUser = currentUser;
    });
    this.currentUser = this.api.auth.getCurrentUser();
    this.api.auth.getCurrentUserFromAPI().subscribe(
      (currentUser: CurrentUser) => {
        currentUser.isAuthorized = true;
        this.api.auth.setCurrentUserEvent(currentUser);
        const hasPath = this.nav.PathMenu;
        if (!hasPath) {
          this.nav.redirectTo('users/users');
        }
      },
      error => {
        this.nav.redirectToLogin();
      }
    );
  }
}
