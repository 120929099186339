import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ICityService } from 'src/services/interfaces/geographyService/ICityService';
import { City } from 'src/models/geography/city';
import { environment } from 'src/environments/environment';
import { DataTablesResponse } from 'src/models/dataTablesResponse';
import { BaseService } from "../base.service";

@Injectable({
    providedIn: 'root'
})

export class CityService implements ICityService {
    api: string = environment.api;
    constructor(private http: HttpClient,
                private baseService: BaseService<City>)
    { }

    getAll(): Observable<City[]> {
        throw new Error("Method not implemented.");
    }
    getOne(id: number): Observable<City> {
        return this.baseService.getOne(id, "city");
    }
    getPage(dataTablesParameters: any): Observable<DataTablesResponse> {
        return this.http.post<DataTablesResponse>(`${this.api}/cities`, JSON.stringify(dataTablesParameters));
    }
    getCities(idRegion: number): Observable<City[]> {
        return this.http.get<City[]>(`${this.api}/citiesByRegion/${idRegion}`);
    }
    add(item: City): Observable<City> {
        return this.baseService.add(item, "city");
    }
    update(item: City): Observable<City> {
        return this.baseService.update(item, "city");
    }
}