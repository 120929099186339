import { BaseService } from './../base.service';
import { IBaseService } from 'src/services/interfaces/IBaseService';
import { Country } from 'src/models/geography/country';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class CountryService implements IBaseService<Country> {
    constructor(private baseService: BaseService<Country>)
    { }
    getAll(): Observable<Country[]> {
        return this.baseService.getAll("countries");
    }
    getOne(id: number): Observable<Country> {
        return this.baseService.getOne(id, "country");
    }
    add(item: Country): Observable<Country> {
        return this.baseService.add(item, "country");
    }
    update(item: Country): Observable<Country> {
        return this.baseService.update(item, "country");
    }
}
