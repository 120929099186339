import { Injectable } from '@angular/core';
import { ILogService } from 'src/services/interfaces/allServices/ILogService';
import { Log } from 'src/models/log';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PointsAccountHistory } from 'src/models/users/pointsAccountHistory';

@Injectable({
  providedIn: 'root'
})
export class LogService implements ILogService{
  private api: string = environment.api;
  optionsXls: any;
  optionsXml: any;
  constructor(private http: HttpClient) { 
    const getfileheadersXls = new HttpHeaders().set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const getfileheadersXml = new HttpHeaders().set('Accept', 'application/xml');
    this.optionsXls = {responseType: 'blob' as 'json', headers: getfileheadersXls};
    this.optionsXml = {responseType: 'xml' as 'json', headers: getfileheadersXml};
  }
  saveChangeUserHistory(logs: Log[]): Observable<any>{
    return this.http.post<Log[]>(`${this.api}/log/user`, JSON.stringify({logs}));
  }
  savePointAccountHistory(log: PointsAccountHistory): Observable<PointsAccountHistory> {
    return this.http.post<PointsAccountHistory>(`${this.api}/log/accounts`, JSON.stringify({log}));
  }
  getPointsAccountHistoryXls(dataTablesParameters: any, idPointsAccount: number): Observable<any> {
    return this.http.post(`${this.api}/log/accounthistoryXls/${idPointsAccount}`, JSON.stringify(dataTablesParameters), this.optionsXls);
  }

  getPointsAccountHistoryXml(dataTablesParameters: any, idPointsAccount: number): Observable<any> {
    return this.http.post(`${this.api}/log/accounthistoryXml/${idPointsAccount}`, JSON.stringify(dataTablesParameters), this.optionsXml);
  }

  getActivityXls(id: number): Observable<any> {
    return this.http.get(`${this.api}/log/activityXls/${id}`, this.optionsXls);
  }

  getActivityXml(id: number): Observable<any> {
    return this.http.get(`${this.api}/log/activityXml/${id}`, this.optionsXml);
  }

  getXls(url: string){
    return this.http.get(`${this.api}/log/${url}`, this.optionsXls);
  }

  getXml(url: string){
    return this.http.get(`${this.api}/log/${url}`, this.optionsXml);
  }
  postXls(dataTablesParameters: any, url: string){
    return this.http.post(`${this.api}/log/${url}`, JSON.stringify(dataTablesParameters),this.optionsXls);
  }

  postXml(dataTablesParameters: any, url: string){
    return this.http.post(`${this.api}/log/${url}`, JSON.stringify(dataTablesParameters), this.optionsXml);
  }
}
