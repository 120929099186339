import { AuthComponent } from 'src/models/auth';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ServicesService } from 'src/services/services.service';
import { ApiService } from 'src/services/api.service';

@Component({
  selector: 'app-verification-code',
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.scss']
})
export class VerificationCodeComponent extends AuthComponent {
  constructor(formBuilder: FormBuilder, 
              api: ApiService, 
              services: ServicesService) { 
    super(formBuilder, api, services);
    this.form = this.formBuilder.group({
      "code": new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
  }
  catchError(error: any) {
    const { errors } = error.error;
    if(errors && errors.code){
      this.form.controls['code'].setErrors({ notCorrect: true});
      this._form.ngSubmit.emit();
    }
  }
  submitValue() {
    const values = this.form.value;
    this.api.auth.verifivationCode(values.code).subscribe(
      res => this.returnResult(res),
      error => this.catchError(error)
    )
  }
  returnResult(res: any) {
    this.services.nav.redirectTo('confirmPassword');
  }
  back(){
    this.services.nav.redirectTo('forgotPassword');
  }
}
