import { ModelType } from 'src/models/model/modelType';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy } from '@angular/core';
import { EditorComponent } from 'src/models/editor';
import { User } from 'src/models/users/user';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Category } from 'src/models/users/category';
import { SelectList } from 'src/models/form/selectList';
import { ServicesService } from 'src/services/services.service';
import { ISelect } from 'src/interfaces/ISelect';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { SelectOptions } from 'src/models/form/selectOptions';
import { SelectItem } from 'src/models/form/selectItem';
import { TradingPlace } from 'src/models/tradingPlace/tradingPlace';
import { City } from 'src/models/geography/city';
import { Region } from 'src/models/geography/region';
import { Country } from 'src/models/geography/country';
import { MessageEventType } from 'src/enum/message/eventType';
import * as moment from 'moment';

@Component({
  selector: 'app-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.scss']
})
export class UserEditorComponent extends EditorComponent<User> implements ISelect, OnDestroy {
  apiCall: any;
  selectItem: string[] = ['country', 'region', 'city', 'tradingPlace'];
  selectOptions: SelectOptions;
  categories: Array<Category> = [];
  modelTypes: Array<ModelType> = [];
  isDisabled = false;
  private selectList: SelectList;
  constructor(services: ServicesService,
    formBuilder: FormBuilder,
    api: ApiService,
    route: ActivatedRoute,
    translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.item = new User();
    this.item.modelTypes = [];
    this.isDisabled = !this.currentUser.isSuperAdmin;
    this.form = this.formBuilder.group({
      'lastName': new FormControl({ value: '', disabled: this.isDisabled }, [Validators.required]),
      'firstName': new FormControl({ value: '', disabled: this.isDisabled }, [Validators.required]),
      'middleName': new FormControl({ value: '', disabled: this.isDisabled }, [Validators.required]),
      'email': new FormControl({ value: '', disabled: this.isDisabled }, [Validators.required, Validators.email]),
      'emailMessagingEnabled': new FormControl({ value: true, disabled: this.isDisabled }),
      'phone': new FormControl({ value: '', disabled: this.isDisabled }, [Validators.required, Validators.pattern(/^(?:\+\d{10,18})$/)]),
      'phoneMessagingEnabled': new FormControl({ value: true, disabled: this.isDisabled }),
      'password': new FormControl({ value: '', disabled: this.isDisabled }, [Validators.required, Validators.minLength(4)]),
      'confirmPassword': new FormControl({ value: '', disabled: this.isDisabled }, [Validators.required, Validators.minLength(4)]),
      'postAddress': new FormControl({ value: '', disabled: this.isDisabled }),
      'category': new FormControl({ value: '', disabled: this.isDisabled }, [Validators.required]),
      'modelTypes': this.formBuilder.array([]),
      'country': new FormControl({ value: null, disabled: this.isDisabled }, [Validators.required]),
      'region': new FormControl({ value: null, disabled: this.isDisabled }, [Validators.required]),
      'city': new FormControl({ value: null, disabled: this.isDisabled }, [Validators.required]),
      'tradingPlace': new FormControl({ value: null, disabled: this.isDisabled }, [Validators.required]),
      'enabled': new FormControl({ value: true, disabled: this.isDisabled }),
      'createdAt': new FormControl({ value: '', disabled: this.isDisabled }),
      'lastCheckDate': new FormControl(''),
      'pointsAccountId': new FormControl({ value: '', disabled: this.isDisabled })
    });
    this.selectOptions = new SelectOptions(this.selectItem, true);
    this.selectOptions.selectItemsEvent.subscribe((selectList: SelectList) => this.selectItems(selectList));
    this.apiCall = api.user;
  }

  ngOnDestroy(): void {
    this.selectOptions.selectItemsEvent.unsubscribe();
  }
  buildModelType(data: Array<ModelType>) {
    const array = data.map(type => {
      if (!this.id && type.name === environment.defaultValue.modelType) {
        return new FormControl({ value: true, disabled: this.isDisabled });
      }
      if (this.item.modelTypes.some(x => x.id === type.id)) {
        return new FormControl({ value: true, disabled: this.isDisabled });
      }
      return new FormControl({ value: false, disabled: this.isDisabled });
    });
    return this.formBuilder.array(array);
  }
  setPatchValue() {
    this.item.password = '';
    this.form.patchValue({
      ...this.item,
      'tradingPlace': null,
      'city': null,
      'region': null,
      'country': null,
      'pointsAccountId': this.item.pointsAccount.id
    });
    this.form.setControl('modelTypes', this.buildModelType(this.modelTypes));
    this.form.controls['category'].setValue(this.item.category.id);
    ['password', 'confirmPassword'].forEach(name => {
      const control = this.form.controls[name];
      control.clearValidators();
      control.setValidators(Validators.minLength(4));
    });
  }

  setDefaultValues() {
    this.api.user.category.getAll().subscribe(categories => {
      this.categories = categories;
      const selectCategory = categories.find(val => val.name === environment.defaultValue.category);
      if (selectCategory) {
        this.item.category = new Category(selectCategory.id, selectCategory.name);
        this.form.controls.category.setValue(selectCategory.id);
      }
    });
    this.api.model.modelType.getAll().subscribe(data => {
      this.modelTypes = data;
      this.form.setControl('modelTypes', this.buildModelType(data));
    });
  }
  selectItems(selectList: SelectList) {
    const lastCheckDate = this.form.controls['lastCheckDate'];
    this.selectList = selectList;
    const selectCountry = selectList.getSelect('country');
    const selectRegion = selectList.getSelect('region');
    const selectCity = selectList.getSelect('city');
    const selectTradingPlace = selectList.getSelect('tradingPlace');

    if (!selectCountry.HasValues) {
      this.api.geography.country.getAll().subscribe(data => {
        if (this.id && selectList.isInit) {
          selectList.setItems('country', data, this.item.tradingPlace.city.region.country.id);
          this.logHistory.compareField('country', selectCountry.Active.text, '');
          selectCountry.IsDisabled = this.isDisabled;
        } else if (!this.id && selectList.isInit) {
          selectList.setItems('country', data, 'Россия');
        } else {
          selectList.setItems('country', data);
        }
      });
    }

    if (selectCountry.HasSelected && !selectRegion.HasValues) {
      this.api.geography.region.getRegions(selectCountry.Active.id).subscribe(data => {
        if (this.id && selectList.isInit) {
          selectList.setItems('region', data, this.item.tradingPlace.city.region.id);
          this.logHistory.compareField('region', selectRegion.Active.text, '');
          selectRegion.IsDisabled = this.isDisabled;
        } else {
          selectList.setItems('region', data);
        }
      });
    }

    if (selectRegion.HasSelected && !selectCity.HasValues) {
      this.api.geography.city.getCities(selectRegion.Active.id).subscribe(data => {
        if (this.id && selectList.isInit) {
          selectList.setItems('city', data, this.item.tradingPlace.city.id);
          this.logHistory.compareField('city', selectCity.Active.text, '');
          selectCity.IsDisabled = this.isDisabled;
        } else {
          selectList.setItems('city', data);
        }
      });
    }

    if (selectCity.HasSelected && !selectTradingPlace.HasValues) {
      this.api.tradingPlace.getOneByCityAndRegion(selectCity.Active.id, selectRegion.Active.id).subscribe(data => {
        if (this.id && selectList.isInit) {
          selectList.setItems('tradingPlace', data, this.item.tradingPlace.id);
          this.logHistory.compareField('tradingPlace', selectTradingPlace.Active.text, '');
          selectTradingPlace.IsDisabled = this.isDisabled;
        } else {
          selectList.setItems('tradingPlace', data);
        }
      });
    }
    if (!selectList.isInit) {
      this.selectItem.map(item => {
        const select = selectList.getSelect(item);
        if (select.HasSelected) {
          this.logHistory.compareGeography(item, select.Active.text);
        }
      });
    }
  }
  setValuesToObject(): void {
    this.onSubmit.emit();
    const values = this.form.value;
    const { country, region, city, tradingPlace, modelTypes, password, confirmPassword, pointsAccountId, ...valuesToSubmit } = values;

    const selectedModelTypes = values.modelTypes
      .map((checked, index) => checked ? this.modelTypes[index].id : null)
      .filter(value => value !== null);

    if (selectedModelTypes.length === 0) {
      this.form.get('modelTypes').setErrors({ required: true });
      return;
    }

    if (password !== confirmPassword) {
      this.form.get('confirmPassword').setErrors({ notCorrect: true });
      this.onSubmit.emit();
      return;
    }
    if (password) {
      valuesToSubmit.password = password;
    }

    delete this.item.createdAt;
    delete this.item.pointsAccount;

    if (this.id) {
      this.addLogHistory();
    }
    const types = [];
    for (let i = 0; i < this.modelTypes.length; i++) {
      if (values.modelTypes[i]) {
        types.push(new ModelType(this.modelTypes[i].id));
      }
    }
    this.item.modelTypes = types;

    Object.assign(this.item, valuesToSubmit);

    this.item.lastCheckDate =  this.item.lastCheckDate ? moment(this.item.lastCheckDate).format('YYYY-MM-DD') : null;

    this.item.category = new Category(parseInt(values.category, 10));
    if (tradingPlace) {
      this.item.tradingPlace = new TradingPlace(tradingPlace);
      if (city) {
        this.item.tradingPlace.city = new City(city);

        if (region) {
          this.item.tradingPlace.city.region = new Region(region);

          if (country) {
            this.item.tradingPlace.city.region.country = new Country(country);
          }
        }
      }
    }
  }

  addLogHistory() {
    const values = this.form.value;
    const ignoreKeys = ['id', 'confirmPassword'];
    const keys = Object.keys(this.item);
    keys.forEach(key => {
      if (ignoreKeys.indexOf(key) > -1) {
        return;
      } else if (this.selectItem.indexOf(key) > -1) {
        const select = this.selectList.getSelect(key);
        return this.logHistory.compareGeography(key, select.Active.text);
      } else if (this.item[key] !== values[key]) {
        const itemKey = this.item[key];
        const valueKey = values[key];
        if (key === 'category') {
          return this.logHistory.compareBase(key, itemKey, valueKey, this.categories);
        }
        if (key === 'password') {
          return this.logHistory.compareField(key, '', ' ');
        }
        if (key === 'modelTypes') {
          return this.logHistory.compareModelType(key, itemKey, valueKey, this.modelTypes);
        }
        if (key === 'emailMessagingEnabled' || key === 'phoneMessagingEnabled') {
          return this.logHistory.compareBoolean(key, itemKey, valueKey);
        }
        if (key === 'enabled') {
          return this.logHistory.compareEnabled(key, itemKey, valueKey);
        }
        return this.logHistory.compareField(key, itemKey, valueKey);
      }
    });
    this.logHistory.translate();
  }
  returnError(error: any) {
    let isReturn = false;
    if (error.email) {
      this.form.controls['email'].setErrors({ exist: true });
      isReturn = true;
    }
    if (error.phone) {
      this.form.controls['phone'].setErrors({ exist: true });
      isReturn = true;
    }

    if (isReturn) {
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error);
  }
  returnResult(res: any, event: MessageEventType) {
    if (res.error) {
      this.returnError(res.error);
    } else if (res.errors) {
      this.returnError(res.errors);
    } else {
      if (event === this.message.messageEvent.edit) {
        const translate = this.logHistory.TranslateHistory;
        if (translate.length) {
          this.services.log.saveChangeUserHistory(translate).subscribe();
        }
      }
      this.sendAlert(this.message.messageColor.success, event);
      this.services.nav.redirectTo(this.urlReturn);
    }
  }

}
