import { Component, ViewChild, OnDestroy } from '@angular/core';
import { EditorComponent } from 'src/models/editor';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { Present } from 'src/models/presents/present';
import { environment } from 'src/environments/environment';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-present-editor',
  templateUrl: './present-editor.component.html',
  styleUrls: ['./present-editor.component.scss']
})
export class PresentEditorComponent extends EditorComponent<Present>{
  apiCall: any;
  src: string;
  @ViewChild(FileUpload) fileUpload;
  constructor(services: ServicesService, 
              route: ActivatedRoute, 
              formBuilder: FormBuilder, 
              api: ApiService, 
              translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.item = new Present();
    this.form = this.formBuilder.group({
      "name" : new FormControl('', [Validators.required]),
      "article": new FormControl('', [Validators.required]),
      "isDeliveryRequired" : new FormControl(true),
      "points" : new FormControl('', [Validators.required]),
      "image" : new FormControl(false),
      "enabled" : new FormControl(true)
    });
    this.apiCall = api.present;
  }
  
  setValuesToObject(): void {
    const { image, ...valuesToSubmit } = this.form.value;
    this.onSubmit.emit();
    Object.assign(this.item, valuesToSubmit);
  }
  returnError(error: any) {
    if(error.hasOwnProperty('article')){
      this.form.controls['article'].setErrors({exist: true});
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error);
  }
  onChangeImage(file: string|ArrayBuffer){
    this.item.file = file;
  }
}
