import {Component} from '@angular/core';
import {EditorComponent} from '../../../models/editor';
import {ServicesService} from '../../../services/services.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {SelectManyList} from '../../../models/form/selectManyList';
import {Base} from '../../../models/base';
import {forkJoin} from 'rxjs';
import {InstallerClientSMS} from '../../../models/settings/installerClientSMS';

@Component({
  selector: 'app-installer-client-sms-editor',
  templateUrl: './installer-client-sms-editor.component.html',
  styleUrls: ['./installer-client-sms-editor.component.scss']
})
export class InstallerClientSmsEditorComponent extends EditorComponent<InstallerClientSMS> {
  apiCall: any;
  selectManyList: SelectManyList;
  constructor(services: ServicesService,
              formBuilder: FormBuilder,
              api: ApiService,
              route: ActivatedRoute,
              translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.apiCall = api.installerClientSMS;
    this.item = new InstallerClientSMS();
    this.form = this.formBuilder.group({
      'name' : new FormControl('', [Validators.required]),
      'message': new FormControl('', [Validators.required]),
      'models': new FormControl(''),
    });
    this.selectManyList = new SelectManyList(['models'], this.form);

  }

  ngOnInit() {
    this.setValues();
  }

  setValues() {
    let observable = null;
    if (this.id) {
      observable = forkJoin(
        this.api.installerClientSMS.getAccessibleModels(this.id),
        this.api.installerClientSMS.getOne(this.id)
      );
    } else {
      observable = forkJoin(
        this.api.installerClientSMS.getAccessibleModels(0)
      );
    }
    observable.subscribe(
      res => {
        this.selectManyList.setItems('models', res[0]);
        this.InProgress = false;
        if (this.id) {
          const item = res[1];
          const models = item.models as Base[];
          this.item = item;
          this.form.patchValue({
            ...item,
            'models': models.map(model => model.id)
          });
        }
      },
      e => this.catchError(e)
    );
  }

  // setValuesToObject(): void {
  //   const { models, ...valuesToSubmit } = this.form.value;
  //   this.item.models = this.selectManyList.getSelect('models').getActivesToSubmit();
  //   Object.assign(this.item, valuesToSubmit);
  // }

  setValuesToObject(): void {
    this.item.name = this.form.value.name;
    this.item.message = this.form.value.message;
    this.item.models = this.form.value.models;
  }
  returnError(error: string) {
    if (error.indexOf(environment.serverError.alreadyExist) > -1) {
      this.form.controls['name'].setErrors({exist: true});
      this._form.ngSubmit.emit();
      return;
    }
    this.catchError(error);
  }
  removed(value: any, name: string) {
    this.selectManyList.removed(value.value, name);
  }
  selected(value: any, name: string) {
    this.selectManyList.selected(value, name);
  }
}
