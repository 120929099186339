import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { DatatablesComponent } from 'src/models/datatables';
import { Category } from 'src/models/users/category';
import { ServicesService } from 'src/services/services.service';
import { TitleItem } from 'src/models/titleItem';


@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent extends DatatablesComponent<Category> {
  columnNames: TitleItem[];
  apiCall: any;
  constructor(protected api: ApiService,
              protected services: ServicesService) { 
    super(api, services);
    this.apiCall = api.user.category;
    this.columnNames = [
      new TitleItem('name.category')
    ];
  }
}
