import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { DataTablesResponse } from "src/models/dataTablesResponse";
import { IOrderPresentService } from "src/services/interfaces/presentService/IOrderPresentService";
import { OrderPresent } from "src/models/presents/orderPresent";
import { Base } from "src/models/base";
import { BaseService } from "../base.service";
import { LogService } from "../allServices/log.service";
import {OrderPresentHistory} from '../../../models/presents/orderPresentHistory';

@Injectable({
    providedIn: 'root'
})

export class OrderPresentService implements IOrderPresentService {
    api: string = environment.api;
    constructor(private http: HttpClient,
                private baseService: BaseService<OrderPresent>,
                private log: LogService)
    {}

    getPage(dataTablesParameters: any): Observable<DataTablesResponse> {
        return this.http.post<DataTablesResponse>(`${this.api}/adminpresentorders`, JSON.stringify(dataTablesParameters));
    }
    getAll(): Observable<OrderPresent[]> {
        throw new Error("Method not implemented.");
    }
    getAllStatus(): Observable<Base[]> {
        return this.http.get<Base[]>(`${this.api}/adminpresentorderstatus`);
    }
    getOne(id: number): Observable<OrderPresent> {
        return this.baseService.getOne(id, "adminpresentorder");
    }
    add(item: OrderPresent): Observable<OrderPresent> {
        throw new Error("Method not implemented.");
    }
    update(item: OrderPresent) {
        return this.baseService.update(item, "adminpresentorder");
    }

    logXls(dataTablesParameters: any): Observable<any> {
        return this.log.postXls(dataTablesParameters, "orderpresentsXls");
    }
    logXml(dataTablesParameters: any): Observable<any> {
        return this.log.postXml(dataTablesParameters, "orderpresentsXml");
    }

    logHistoryXls(dataTablesParameters?: any): Observable<any> {
        return this.log.getXls("orderpresentshistoryXls");
    }
    logHistoryXml(dataTablesParameters?: any): Observable<any> {
        return this.log.getXml("orderpresentshistoryXml");
    }
    getLogHistory(id: number): Observable<OrderPresentHistory[]> {
      return this.http.get<OrderPresentHistory[]>(`${this.api}/log/orderpresentshistory/${id}`);
      // return this.log.get("orderpresentshistory");
    }
}
