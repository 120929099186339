import { Component } from '@angular/core';
import { EditorComponent } from 'src/models/editor';
import { ServicesService } from 'src/services/services.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import {PointValue} from '../../../models/settings/pointValue';

@Component({
  selector: 'app-settings',
  templateUrl: './pointValue.component.html',
  styleUrls: ['./pointValue.component.scss']
})
export class PointValueComponent extends EditorComponent<PointValue> {
  apiCall: any;
  ru: any;
  currentPointValue: number;

  constructor(services: ServicesService,
              route: ActivatedRoute,
              formBuilder: FormBuilder,
              api: ApiService,
              translate: TranslateService) {
    super(services, route, formBuilder, api, translate);
    this.form = formBuilder.group({
      'pointValue' : new FormControl('', [
        Validators.required,
        Validators.pattern(/^[1-9]\d*$/)
        ]),
    });
  }

  ngOnInit() {
    this.ru = environment.ru;
    this.setValues();
  }

  setValues(): void {
    this.api.settings.getPointValue().subscribe(
      item => {
        this.item = item;
        this.currentPointValue = item.pointValue;
        this.form.patchValue({
          ...item,
        });
        this.InProgress = false;
      },
      error => this.catchError(error)
    );
  }
  setValuesToObject(): void {
      Object.assign(this.item, this.form.value);
  }
  apiAdd(): void {
    this.apiUpdate();
  }
  apiUpdate(): void {

    if (this.currentPointValue === this.item.pointValue) {
      alert('Ведённая вами стоимость балла ' + this.currentPointValue + ' уже используется на данный момент');
      return;
    }

    if (confirm('Вы уверенны что хотите изменить стоимость бала с ' + this.currentPointValue + ' на ' + this.item.pointValue + '?')) {
      this.api.settings.updatePointValue(this.item).subscribe(
        res => {
          this.sendSuccessAlert('info.message.settings');
        },
        error => this.catchError(error)
      );
    }
  }
  returnError(error: any) {
    throw new Error('Method not implemented.');
  }
}
