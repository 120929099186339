import { CurrentUser } from '../../models/users/currentUser';
import { ApiService } from './../../services/api.service';
import { Component, OnInit, Input } from '@angular/core';
import { NavigationService } from 'src/services/apiServices/allServices/navigation.service';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],

})
export class ContentHeaderComponent implements OnInit {
  public url: string;
  public text: string;
  public currentUser: CurrentUser;
  public isShow = true;
  constructor(private nav: NavigationService,
    private api: ApiService) { }

  ngOnInit() {
    const menu = this.nav.getSubmenu().url;
    this.url = `/${menu}/editor`;
    this.text = this.nav.Title;
    this.currentUser = this.api.auth.getCurrentUser();
    const isEditor = this.nav.IsEditor;
    const banUrls = [
      'users/pointsAccounts', 'settings/adminsInfo',
      'settings/settings', 'presents/orderPresents',
      'sales/sales'
    ];
    if (isEditor && menu === 'users/admins') {
      this.isShow = false;
    } else if (menu === 'users/users' && !this.currentUser.isSuperAdmin) {
      this.isShow = false;
    } else if (banUrls.indexOf(menu) > -1) {
      this.isShow = false;
    }
  }

}
