import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Region } from 'src/models/geography/region';
import { IRegionService } from 'src/services/interfaces/geographyService/IRegionService';
import { environment } from 'src/environments/environment';
import { IBaseService } from "src/services/interfaces/IBaseService";
import { BaseService } from "../base.service";

@Injectable({
    providedIn: 'root'
})

export class RegionService implements IRegionService {
    private api: string = environment.api;
    constructor(private http: HttpClient,
                private baseService: BaseService<Region>)
    { }
    getAll(): Observable<Region[]> {
        return this.baseService.getAll("regions");
    }
    getOne(id: number): Observable<Region> {
        return this.baseService.getOne(id, "region");
    }
    getRegions(idCountry: number): Observable<Region[]> {
        return this.http.get<Region[]>(`${this.api}/regions/${idCountry}`);
    }
    add(item: Region): Observable<Region> {
        return this.baseService.add(item, "region");
    }
    update(item: Region): Observable<Region> {
        return this.baseService.update(item, "region");
    }
}