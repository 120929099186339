import { SelectItem } from 'src/models/form/selectItem';
import { Base } from '../base';
import { EventEmitter } from '@angular/core';
export class SelectMany {
    private selectItems: SelectItem[] = [];
    private items: SelectItem[] = [];
    get Items() {
        return this.items;
    }
    private actives: SelectItem[] = [];
    get Actives() {
        return this.actives;
    }
    private hasSelected = false;
    set HasSelected(value: boolean) {
        this.hasSelected = value;
    }
    get HasSelected() {
        return this.hasSelected;
    }
    private isAll = false;
    private isDisabled = false;
    public onIsDisabled = new EventEmitter<boolean>(false);
    set IsDisabled(value: boolean) {
        this.isDisabled = value;
        this.onIsDisabled.emit(value);
    }
    get IsDisabled() {
        return this.isDisabled;
    }

    constructor() {
        this.selectItems.push(new SelectItem(-1, 'Выбрать всё'));
        this.selectItems.push(new SelectItem(-2, 'Отменить всё'));
    }
    setItems(values: Base[]) {
        this.items = [...this.selectItems];
        values.map(item => this.items.push(new SelectItem(item.id, item.name)));
    }
    setActives(value: number) {
        if (value === -1) {
            this.actives = this.items.filter(item => this.selectItems.indexOf(item) === -1);
            this.isAll = true;
        } else if (value === -2) {
            this.actives = [];
        } else {
            this.actives.push(this.items.find(item => item.id === value));
            this.isAll = false;
        }
        this.HasSelected = this.actives.length ? true : false;
    }
    getActivesToSubmit() {
        return this.isAll ? -1 : this.actives.map(item => new Base(item.id));
    }
    selected(id: number) {
        this.setActives(id);
    }
    removed(id: number) {
        this.actives = this.actives.filter(active => active.id !== id);
        this.isAll = false;
        this.HasSelected = this.actives.length ? true : false;
    }
}
