export class TitleItem{
    name: string;
    className: string;
    hideSearch: boolean;
    isLink: boolean;
    constructor(name: string);
    constructor(name: string, className: string);
    constructor(name: string, hideSearch: boolean);
    constructor(name: string, className: string, hideSearch: boolean);
    constructor(name: string, className: string, hideSearch: boolean, isLink: boolean);
    constructor(name?: string, className?: any, hideSearch: boolean = false, isLink: boolean = false){
        if(name) this.name = name;
        if(typeof className === "string"){
            this.className = className;
            this.hideSearch = hideSearch;
        }
        else if(typeof className === "boolean"){
            this.hideSearch = className || false;
        }
        this.isLink = isLink;
    }
}