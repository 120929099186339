import { ApiService } from 'src/services/api.service';
import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/services/apiServices/allServices/navigation.service';
import { CurrentUser } from 'src/models/users/currentUser';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private api:ApiService,
              private nav: NavigationService) { }

  ngOnInit() {
    this.api.auth.logout().subscribe(_ => {
      localStorage.clear();
      this.api.auth.setCurrentUserEvent(new CurrentUser());
      this.nav.redirectToLogin();
    });;
  }

}
