import { ServicesService } from './../services/services.service';
import { OnInit, ViewChild, EventEmitter } from "@angular/core";
import { FormGroup, NgForm, FormBuilder } from "@angular/forms";
import { ApiService } from 'src/services/api.service';

export abstract class AuthComponent implements OnInit {
    @ViewChild('ngForm') _form: NgForm;
    onSubmit: EventEmitter<any> = new EventEmitter<any>(); 
    form: FormGroup;

    constructor(protected formBuilder: FormBuilder,
                protected api: ApiService,
                protected services: ServicesService)
    { }
    
    abstract ngOnInit(): void;
    abstract catchError(res: any);
    
    abstract submitValue();
    setValuesToObject(){};

    submit(){
        this.onSubmit.emit();
        this.setValuesToObject();
        if(this.form.valid){
            this.submitValue();
        }
    }
    abstract returnResult(res: any);
}