import { IBaseService } from 'src/services/interfaces/IBaseService';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Category } from "src/models/users/category";
import { BaseService } from "../base.service";

@Injectable({
    providedIn: 'root'
})

export class CategoryService implements IBaseService<Category> {
    constructor(private baseService: BaseService<Category>){}
    
    getAll(): Observable<Category[]> {
        return this.baseService.getAll("usercategories");
    }
    getOne(id: number): Observable<Category> {
        return this.baseService.getOne(id,"usercategorybyid");
    }
    add(item: Category): Observable<Category>{
        return this.baseService.add(item, "usercategory");
    }
    update(item: Category): Observable<Category> {
        return this.baseService.update(item, "usercategory");
    }
}