import { Model } from './../../../models/model/model';
import { Component } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { ServicesService } from 'src/services/services.service';
import { DatatablesServerComponent } from 'src/models/datatablesServer';
import { TitleItem } from 'src/models/titleItem';
import { getColumnTitles, getColumnData } from 'src/utils/dt/columns';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss']
})
export class ModelsComponent extends DatatablesServerComponent<Model> {
  
  columnsData: any[];
  columnNames: TitleItem[];
  apiCall: any;
  filename: string = 'Модели';

  constructor(api: ApiService, 
              services: ServicesService) { 
    super(api, services);
    this.setInitValues();
  }

  setInitValues(): void {
    this.apiCall = this.api.model;
    this.apiLog = this.api.model;
    this.pageLength = 5;
    this.columnNames = getColumnTitles([
      'name.name', 'manufactureNumber', 'modelType', 'modelBrand', 
      ['specifications', 'w-25'], 'enabled', 'hidden'
    ]);
    const dtType = this.dtType;
    this.columnsData = [ 
      ['name', this.urlReturn], 'manufactureNumber', 'modelType.name', 'modelBrand.name', 
      ['description', dtType.longText], ['enabled', dtType.enabled], ['hidden', dtType.yesOrNo]
    ]; 
  }
}
